import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api, apiCall, AuthService, isEmail } from '../../../services';
import { addSnackbar, setLoading } from '../../../store';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { DEFAULT_MAINTENANCE_OPERATION, TRANSLATIONS } from '../../../constants';
import { MaintenanceOperationType } from '../../../enums';
import { MaintenanceOperation } from '../../../models';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Icon, { IconType } from '../../common/Icon';
import Loader from '../../common/Loader';
import Pagination from '../../common/Pagination';
import RadioButton from '../../common/RadioButton';
import { SnackbarType } from '../../common/Snackbar';
import Select from '../../common/Select';
import SpacingContainer from '../../common/SpacingContainer';
import Table from '../../common/Table';
import Title from '../../common/Title';
import TextInput from '../../common/TextInput';
import Tooltip from '../../common/Tooltip';

export const MaintenanceView = () => {
    const dark = useAppSelector(x => x.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [tablePage, setTablePage] = useState(0);
    const [maintenanceLogs, setMaintenanceLogs] = useState<MaintenanceOperation[]>(null);
    const [maintenanceOperation, setMaintenanceOperation] = useState(DEFAULT_MAINTENANCE_OPERATION);
    const [manufacturers, setManufacturers] = useState(['']);

    useEffect(() => {
        loadCompaniesAndLogs();
    }, []);
    
    const loadCompaniesAndLogs = async () => {
        dispatch(setLoading(true));
        
        const maintenanceRequest = apiCall(Api.getMaintenanceLogs(), async x => {
            setMaintenanceLogs(x.data);
        }, async () => {
            dispatch(addSnackbar({ text: translate(translations.error.maintenanceLogsLoad), dark, type: SnackbarType.Error }));
        });
        
        const companiesRequest = apiCall(Api.getAvailableCompanies(), async x => {
            setManufacturers(x.data);
            setMaintenanceOperation({ ...maintenanceOperation, adminEmail:AuthService.getUserEmail(), affectedCompanyName: x.data[0] });
        }, async () => {
            dispatch(addSnackbar({ text: translate(translations.error.companiesLoad), dark, type: SnackbarType.Error }));
        });
        
        await maintenanceRequest;
        await companiesRequest;

        dispatch(setLoading(false));
    };
    
    const handleOperationTypeChange = (type: MaintenanceOperationType) => {
      setMaintenanceOperation({ ...maintenanceOperation, type });
    };
    
    const handleSave = async () => {
        dispatch(setLoading(true));

        await apiCall(Api.performMaintenanceOperation(maintenanceOperation), async x => {
            const successText = x.data !== 0 ? `${translate(translations.maintenance.maintenanceSuccess)} (${x.data})` : translate(translations.maintenance.noMachinesAffected);
            
            dispatch(addSnackbar({ text: successText, dark, type: SnackbarType.Success }));
            dispatch(setLoading(false));
        }, async () => {
            dispatch(addSnackbar({ text: translate(translations.error.maintenanceAction), dark, type: SnackbarType.Error }));
            dispatch(setLoading(false));
        });

        await loadCompaniesAndLogs();
    };
    
    const handleUndo = async (changeId: string) => {
        dispatch(setLoading(true));
        
        await apiCall(Api.undoMaintenanceOperation(changeId), async x => {
            dispatch(addSnackbar({ text: `${translate(translations.maintenance.maintenanceSuccess)} (${x.data})`, dark, type: SnackbarType.Success }));
            dispatch(setLoading(false));
        }, async () => {
            dispatch(addSnackbar({ text: translate(translations.error.maintenanceAction), dark, type: SnackbarType.Error }));
            dispatch(setLoading(false));
        });
        
        await loadCompaniesAndLogs();
    };

    const renderWithTooltip = (content: string) => {
        return (
            <>
                <Tooltip id={`id-${content}`} text={content}>
                    {content}
                </Tooltip>
            </>
        );
    };
    
    const renderRowAction = (changeId: string) => {
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.Center}>
                <Button dark={dark} onClick={() => handleUndo(changeId)} type={ButtonType.Primary}>
                    <Icon type={IconType.Undo} />
                    {translate(translations.action.undo)}
                </Button>
            </Flex>
        );
    };
    
    const renderMaintenanceLogTable = () => {
        return maintenanceLogs && (
            <>
                <Table
                    dark={dark}
                    columns={[
                        { label: translate(translations.company.companyName), min: '100px', max: '3fr', bold: true },
                        { label: translate(translations.maintenance.currentResponsible), min: '100px', max: '3fr', bold: true },
                        { label: translate(translations.maintenance.newResponsible), min: '100px', max: '3fr', bold: true },
                        { label: translate(translations.maintenance.maintenanceType), min: '100px', max: '5fr', bold: true },
                        { label: translate(translations.maintenance.maintenanceBy), min: '100px', max: '5fr', bold: true },
                        { label: '', min: '120px', max: '1fr', bold: true }
                    ]}
                    rows={[
                        ...maintenanceLogs.slice(tablePage * 5, (tablePage + 1) * 5).map(x => (
                            {
                                key: x.id,
                                cells: [
                                    { content: renderWithTooltip(x.affectedCompanyName) },
                                    { content: renderWithTooltip(x.oldResponsibleEmail.toLowerCase()) },
                                    { content: renderWithTooltip(x.newResponsibleEmail.toLowerCase()) },
                                    { content: renderWithTooltip(translate(translations.maintenance.maintenanceTypes[x.type as unknown as keyof typeof translations.maintenance.maintenanceTypes])) },
                                    { content: renderWithTooltip(x.adminEmail) },
                                    { content: renderRowAction(x.id) }
                                ]
                            })),
                        { key: 'divider', divider: true, cells: [] }
                    ]}
                />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.Center} style={{ marginTop: 10, marginBottom: 40 }}>
                    <Pagination dark={dark} activePage={tablePage} count={Math.ceil(maintenanceLogs.length / 5)}
                                onChange={x => setTablePage(x)} />
                </Flex>
            </>
        );
    };
    
    const renderMaintenanceView = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                <Loader dark={dark} loading={isLoading}>
                    <ContentContainer style={{ marginTop: '30px' }}>
                        <Title text={translate(translations.maintenance.updateResponsible)} style={{ fontSize: '24px', marginBottom: '10px' }} />
                        <Select label={translate(translations.common.manufacturers)} value={maintenanceOperation.affectedCompanyName ?? manufacturers[0]} values={manufacturers} mapToString={x => x} onSelect={x => setMaintenanceOperation({ ...maintenanceOperation, affectedCompanyName: x })} />
                        <Flex style={{ marginTop: '10px' }} direction={FlexDirection.Row} justification={FlexJustification.FlexStart}>
                            <SpacingContainer>
                                <RadioButton label={translate(translations.common.machineCreator)} selected={maintenanceOperation.type == MaintenanceOperationType.ReplaceMachineCreator}
                                             onChange={() => handleOperationTypeChange(MaintenanceOperationType.ReplaceMachineCreator)} />
                                <RadioButton label={translate(translations.common.siemensRepresentative)} selected={maintenanceOperation.type == MaintenanceOperationType.ReplaceSiemensRepresentative}
                                             onChange={() => handleOperationTypeChange(MaintenanceOperationType.ReplaceSiemensRepresentative)} />
                                <RadioButton label={translate(translations.common.approvingCustomer)} selected={maintenanceOperation.type == MaintenanceOperationType.ReplaceApprovingCustomer}
                                             onChange={() => handleOperationTypeChange(MaintenanceOperationType.ReplaceApprovingCustomer)} />
                            </SpacingContainer>
                        </Flex>
                        <Flex style={{ marginTop: '20px', marginBottom: '10px' }} direction={FlexDirection.Row} justification={FlexJustification.FlexStart}>
                            <TextInput style={{ marginRight: '10px', minWidth: '250px' }} value={maintenanceOperation.oldResponsibleEmail} name={translate(translations.maintenance.currentResponsible)} label={translate(translations.maintenance.currentResponsible)} onChange={x => setMaintenanceOperation({ ...maintenanceOperation, oldResponsibleEmail: x })} />
                            <TextInput style={{ minWidth: '250px' }} value={maintenanceOperation.newResponsibleEmail} name={translate(translations.maintenance.newResponsible)} label={translate(translations.maintenance.newResponsible)} onChange={x => setMaintenanceOperation({ ...maintenanceOperation, newResponsibleEmail: x })} />
                        </Flex>
                        <Button type={ButtonType.Primary} onClick={handleSave} disabled={!isEmail(maintenanceOperation.oldResponsibleEmail) || !isEmail(maintenanceOperation.newResponsibleEmail)}>
                            {translate(translations.action.save)}
                        </Button>
                        {renderMaintenanceLogTable()}
                    </ContentContainer>
                </Loader>
            </div>
        );
    };
    
    return renderMaintenanceView();
};
