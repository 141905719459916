import React, { Component, CSSProperties, ReactText } from 'react';

interface TitleProps {
    text: ReactText;
    className?: string;
    style?: CSSProperties;
    uppercase?: boolean;
    bold?: boolean;
}

export default class Title extends Component<TitleProps> {
    private renderTitle() {
        const { className, text, uppercase, style, bold } = this.props;

        const containerClassName = `title-container ${className ? className : ''}`;
        const titleClassName = `title ${uppercase ? 'uppercase' : ''} ${bold ? 'bold' : ''}`;

        return (
            <div className={containerClassName} style={style}>
                <div className={titleClassName}>
                    {text}
                </div>
            </div>
        );
    }

    render() {
        return this.renderTitle();
    }
}
