import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AuthorizationError from './AuthorizationError';
import Loader from '../../common/Loader';
import { TrackingService } from '../../../services';
import { useAppSelector } from '../../../hooks/common';
import { setActiveTabType } from '../../../store';


const AuthorizationErrorManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveTabType(null));
        
        TrackingService.TrackError('page.ready', 'Unauthorized 401');
    }, []);

    const renderPage = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                <Loader dark={dark} loading={isLoading}>
                    <AuthorizationError />
                </Loader>
            </div>
        );
    };

    return renderPage();
};
export default AuthorizationErrorManager;
