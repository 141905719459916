import { ProductCreation } from '../models';
import { EMPTY_COMPANY, EMPTY_CONTENT, EMPTY_GUID } from '.';

export const EMPTY_PRODUCT: ProductCreation = {
    id: EMPTY_GUID,
    companyId: null,
    company: { ...EMPTY_COMPANY, id: null },
    title: '',
    description: EMPTY_CONTENT,
    secondDescription: EMPTY_CONTENT,
    imageId: null,
    customReleaseDate: null,
    createdDate: null,
    hallId: null,
    booth: '',
    comments: [],
    categoryGroups: [],
    tags:[],
    isVisibleForSiemensRepresentative: false,
    customerApproverEmail: ''
};
