import { ReactText } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Snackbar } from '../components/common/Snackbars';

export interface SnackbarState {
    snackbars: Snackbar[];
}

const initialState: SnackbarState = {
    snackbars: []
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        addSnackbar: (state: SnackbarState, action: PayloadAction<Omit<Snackbar, 'id'>>) => {
            state.snackbars = [...state.snackbars, { ...action.payload, id: new Date().getTime() }];
        },
        removeSnackbar: (state: SnackbarState, action: PayloadAction<ReactText>) => {
            state.snackbars = state.snackbars.filter(x => x.id !== action.payload);
        }
    }
});

export const { addSnackbar, removeSnackbar } = snackbarSlice.actions;
export const snackbarReducer = snackbarSlice.reducer;
