import React, { useState } from 'react';
import { AuthService, DateService } from '../../../services';
import { Product, ProductCreation, ReleasedMachineCollection } from '../../../models';
import { MachineCollectionType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import Button, { ButtonType } from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Icon, { IconType } from '../../common/Icon';
import List from '../../common/List';
import Modal from '../../common/Modal';
import SpacingContainer from '../../common/SpacingContainer';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import TextInput from '../../common/TextInput';
import RadioButton from '../../common/RadioButton';

interface ConsentModalProps {
    approvalMode: boolean;
    product: ProductCreation | Product;
    releaseComment: string;
    selectedReleaseDate?: Date;
    machineCollection?: ReleasedMachineCollection;
    onCancelModal: () => Promise<void>;
    onCloseModal: () => Promise<void>;
    onCommentChange: (value: string, name: string) => void;
    onReleaseDateChange?: (date: Date) => void;
}

const ConsentModal = ({ approvalMode, product, releaseComment, selectedReleaseDate, machineCollection, onCancelModal, onCloseModal, onCommentChange,
    onReleaseDateChange }: ConsentModalProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [legalAccepted, setLegalAccepted] = useState(false);

    const isValid = () => {
        const isSiemensRepresentative = AuthService.isSiemensRepresentative();

        return (!approvalMode && (isSiemensRepresentative || legalAccepted)) || (approvalMode && legalAccepted);

    };

    const renderTermsAndConditions = () => {
        const termsAndConditions: JSX.Element[] = [];

        for (let i = 1; i <= 7; i++) {
            termsAndConditions.push(<div>{translate(translations.dialog.termsAndConditions[i.toString() as keyof typeof translations.dialog.termsAndConditions])}</div>);
        }

        return termsAndConditions;
    };

    const renderConsentModal = () => {
        const isSiemensRepresentative = AuthService.isSiemensRepresentative();
        const commentTitle = `${translate(translations.common.commentTitle)} ${isSiemensRepresentative 
            ? translate(translations.common.approvingCustomer) 
            : translate(translations.common.siemensRepresentative)}`;

        return (
            <Modal dark={dark} open={true}>
                <SpacingContainer>
                    {renderTermsAndConditions()}
                    {(approvalMode || (!approvalMode && !isSiemensRepresentative)) &&
                        <Checkbox dark={dark} checked={legalAccepted} label={translate(translations.dialog.agreeTerms)} onChange={() => setLegalAccepted(!legalAccepted)} />
                    }
                    {!approvalMode && isSiemensRepresentative &&
                        <div>{translate(translations.dialog.legalForRep)}</div>
                    }
                    {machineCollection && machineCollection.type === MachineCollectionType.EBooklet &&
                        <>
                            {DateService.areEqual(product.customReleaseDate, machineCollection.fairStartDate) 
                            && !DateService.areEqual(machineCollection.fairPreStartDate, machineCollection.fairStartDate)
                                ? <List items={[
                                    {
                                        key: machineCollection.fairPreStartDate.getTime(),
                                        content: <RadioButton
                                            dark={dark}
                                            selected={DateService.areEqual(selectedReleaseDate, machineCollection.fairPreStartDate)}
                                            label={`${translate(translations.dialog.publishAt)}: ${DateService.toString(machineCollection.fairPreStartDate)} 
                                            (${translate(translations.common.recommended)})`}
                                            onChange={() => onReleaseDateChange(machineCollection.fairPreStartDate)}
                                        />
                                    }, {
                                        key: machineCollection.fairPreStartDate.getTime(),
                                        content: <RadioButton
                                            dark={dark}
                                            selected={DateService.areEqual(selectedReleaseDate, machineCollection.fairStartDate)}
                                            label={`${translate(translations.dialog.dontPublishUntil)}: ${DateService.toString(machineCollection.fairStartDate)}`}
                                            onChange={() => onReleaseDateChange(machineCollection.fairStartDate)}
                                        />
                                    }]} />
                                : <div>{`${translate(translations.common.publishingAt)}: ${DateService.toString(product.customReleaseDate)}`}</div>
                            }
                        </>
                    }
                    <Flex direction={FlexDirection.Row} style={{ marginTop: 20 }} justification={FlexJustification.FlexEnd}>
                        <Button dark={dark} type={ButtonType.Secondary} onClick={onCancelModal}>
                            <Icon type={IconType.Close} />
                            {translate(translations.action.cancel)}
                        </Button>
                        <Button dark={dark} type={ButtonType.Primary} disabled={!isValid()} onClick={onCloseModal}>
                            <Icon type={legalAccepted ? IconType.Check : IconType.Warning} />
                            {isSiemensRepresentative && !approvalMode ? translate(translations.action.send) : translate(translations.action.release)}
                        </Button>
                    </Flex>
                    <div style={{ marginTop: 50 }}>{commentTitle}</div>
                    <TextInput dark={dark} label={`${translate(translations.common.comment)} (${translate(translations.common.optional)})`} value={releaseComment} 
                               name='releaseComment' onChange={onCommentChange} />
                </SpacingContainer>
            </Modal>
        );
    };

    return renderConsentModal();
};
export default ConsentModal;
