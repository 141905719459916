export class DateService {
    static addLeadingZero = (value: number) => value >= 10 ? value.toString() : `0${value}`;

    static areEqual = (firstDate: Date, secondDate: Date) => DateService.getDayBeginning(firstDate).getTime() === DateService.getDayBeginning(secondDate).getTime();

    // TODO: Change DateTime to DateTimeOffset on back end
    static fromString = (dateString: Date) => dateString ? new Date(`${dateString}Z`) : null;

    static getDayBeginning = (date: Date) => DateService.addDays(date, 0);

    static getNextDayBeginning = (date: Date) => DateService.addDays(date, 1);

    static getTime = (date: Date) => date ? date.getTime() : 0;
    
    static toString = (date: Date) => `${date.getFullYear()}/${DateService.addLeadingZero(date.getMonth() + 1)}/${DateService.addLeadingZero(date.getDate())}`;

    static toDateString = (date: Date) => date.toISOString().substring(0, 10);

    private static readonly addDays = (date: Date, days: number) => new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
}
