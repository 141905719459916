import React, { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Api, apiCall, TrackingService } from '../../../services';
import { HeaderTabType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import ProductView from './ProductView';
import Loader from '../../common/Loader';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { Product } from '../../../models';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const ProductViewManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const match = useMatch('/machine-finder/:collectionId/product/:productId');
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [product, setProduct] = useState<Product>(null);

    useEffect(() => {
        if (window.location.pathname.includes('collection')){
            navigate(window.location.pathname.replace('collection', 'machine-finder'));
        }
        
        dispatch(setActiveTabType(HeaderTabType.MachineCollection));


        window.scrollTo(0, 0);

        (async () => {
            const startingUrl = window.location.pathname;
            let product: Product = null;

            dispatch(setLoading(true));

            await apiCall(
                Api.getPublicProduct(match.params.productId),
                async x => {
                    product = x.data;
                    TrackingService.TrackProduct('page.ready', window.location.pathname, product.title, product.company.name);
                    setProduct(x.data);
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.productLoad).toString(), type: SnackbarType.Error }));
                }
            );

            if (startingUrl === window.location.pathname) {
                dispatch(setLoading(false));
            }
        })();
    }, []);

    const renderPage = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                <Loader dark={dark} loading={isLoading}>
                    <ProductView product={product} allowSharing />
                </Loader>
                <script>
                    
                </script>
            </div>
        );
    };

    return renderPage();
};
export default ProductViewManager;
