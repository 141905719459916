import React, { KeyboardEvent, ReactNode } from 'react';
import { useOpenAnimations } from '../../hooks/common';

interface ModalProps {
    children: ReactNode;
    open: boolean;
    allowClickAway?: boolean;
    dark?: boolean;
    fullWidth?: boolean;
    onClose?: () => void;
}

const Modal = ({ allowClickAway, children, dark, fullWidth, open, onClose }: ModalProps) => {
    const [animationClass, handleAnimationEnd] = useOpenAnimations(open);

    const handleBackgroundClick = () => {
        if (onClose && allowClickAway) {
            onClose();
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (onClose && event.key === 'Escape') {
            onClose();
        }
    };

    return (
        <div className={`modal-background ${animationClass} ${allowClickAway ? 'clickable' : ''} ${dark ? 'dark' : ''}`} onClick={handleBackgroundClick} onAnimationEnd={handleAnimationEnd}>
            <div className={`modal ${fullWidth ? 'full-width' : ''}`} tabIndex={onClose ? 0 : undefined} onClick={e => e.stopPropagation()} onKeyDown={handleKeyDown}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
