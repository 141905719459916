import React, { KeyboardEvent, ReactNode } from 'react';

export enum ButtonType {
    CallToAction,
    Primary,
    Secondary,
    Tertiary
}

interface ButtonProps {
    type: ButtonType;
    onClick: () => void;
    children?: ReactNode;
    dark?: boolean;
    disabled?: boolean;
}

const Button = ({ children, dark, disabled, type, onClick }: ButtonProps) => {
    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleClick();
        }
    };

    const getButtonTypeClass = () => {
        switch (type) {
            case ButtonType.CallToAction:
                return 'call-to-action';
            case ButtonType.Primary:
                return 'primary';
            case ButtonType.Secondary:
                return 'secondary';
            case ButtonType.Tertiary:
                return 'tertiary';
            default:
                return 'tertiary';
        }
    };

    return (
        <div className={`button ${getButtonTypeClass()} ${disabled ? 'disabled' : ''} ${dark ? 'dark' : ''}`} tabIndex={disabled ? undefined : 0} onClick={handleClick} onKeyDown={handleKeyDown}>
            {children}
        </div>
    );
};

export default Button;
