import React, { useEffect, useState } from 'react';
import { EditorState, convertFromHTML, ContentState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentService } from '../../services';

interface TextEditorProps {
    value: string;
    onChange: (value: string) => void;
    allowFormating?: boolean;
    className?: string;
    dark?: boolean
    disabled?: boolean
    error?: boolean
    label?: string;
    required?: boolean;
    maxLength?: number;
}

const TextEditor = ({ value, onChange, allowFormating, className, dark, disabled, error, label, required, maxLength }: TextEditorProps) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    
    useEffect(() => {
        const contentState = convertFromHTML(value.replace('<ins>', '<u>').replace('</ins>', '</u>'));
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentState.contentBlocks, contentState.entityMap)));
    }, []);

    const addPastedContent = (input: string, editorState: EditorState) => {
        const inputLength = editorState.getCurrentContent().getPlainText().length;
        const remainingLength = maxLength - inputLength;

        const newContent = Modifier.insertText(editorState.getCurrentContent(), editorState.getSelection(), input.slice(0, remainingLength));

        setEditorState(EditorState.push(editorState, newContent, 'insert-characters'));
    };

    const getLengthOfSelectedText = () => {
        const currentSelection = editorState.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = editorState.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const startAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);
            
            if (startAndEndBlockAreTheSame) {
                length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
            } 
            else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } 
                    else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } 
                    else {
                        length += currentContent.getBlockForKey(currentKey).getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                }
            }
        }

        return length;
    };
    
    const handleBeforeInput = () => {
        if (maxLength) {
            if (editorState.getCurrentContent().getPlainText('').length >= maxLength) {
                return true;
            }
        }
        
        return false;
    };
    
    const handleChange = (newEditorState: EditorState) => {
        let newValue = stateToHTML(newEditorState.getCurrentContent());

        newValue = newValue.replace(/<img[^>]*>/, '');

        if (maxLength) {
            let lettersToRemove = Math.floor(ContentService.getLength(newValue) - maxLength);

            while (lettersToRemove > 0) {
                newValue = newValue.replace(/(&[^;]+;|[^>])(?=<)(?!.*((&[^;]+;|[^>])(?=<)))/, ''); // Remove the last letter or special character from the HTML while avoiding the tags

                lettersToRemove--;
            }
        }
        
        setEditorState(newEditorState);
        onChange(newValue);
    };

    const handlePastedText = (pastedText: string) => {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = getLengthOfSelectedText();

        if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
            const selection = editorState.getSelection();
            const isCollapsed = selection.isCollapsed();
            const tempEditorState = !isCollapsed ? removeSelection() : editorState;

            addPastedContent(pastedText, tempEditorState);

            return true;
        }
        return false;
    };

    const removeSelection = () => {
        const selection = editorState.getSelection();
        const startKey = selection.getStartKey();
        const startOffset = selection.getStartOffset();
        const endKey = selection.getEndKey();
        const endOffset = selection.getEndOffset();
        
        if (startKey !== endKey || startOffset !== endOffset) {
            const newContent = Modifier.removeRange(editorState.getCurrentContent(), selection, 'forward');
            const tempEditorState = EditorState.push(editorState, newContent, 'remove-range');
            
            setEditorState(tempEditorState);
            
            return tempEditorState;
        }
        
        return editorState;
    };

    const renderLabel = () => {
        return label && (
            <div className='text-editor-label'>
                {label}
            </div>
        );
    };

    const renderCharacterIndicator = () => {
        const length = editorState.getCurrentContent().getPlainText('').length;
        const className = `character-indicator ${maxLength && length > maxLength ? 'error' : ''}`;

        return maxLength && (
            <div className={className}>
                {length} / {maxLength}
            </div>
        );
    };

    const renderTextEditor = () => {
        const buttonClassName = 'draft-button';
        const customClassName = `text-editor ${error ? 'error' : ''} ${required && !ContentService.isValid(value, maxLength) ? 'highlighted' : ''} ${className ? className : ''} ${dark ? 'dark' : ''}`;
        
        return (
            <div className={customClassName}>
                {renderLabel()}
                <Editor
                    toolbarHidden={!allowFormating}
                    readOnly={disabled}
                    wrapperClassName={'editor'} 
                    toolbarClassName={'draft-toolbar'} 
                    editorClassName={'draft-editor'} 
                    editorState={editorState} 
                    onEditorStateChange={handleChange}
                    handlePastedText={handlePastedText}
                    toolbar={{
                        options: ['inline', 'link', 'list', 'remove'],
                        inline: {
                            options: ['italic', 'underline'],
                            italic: { className: buttonClassName },
                            underline: { className: buttonClassName }
                        },
                        link: {
                            popupClassName: 'draft-link-modal',
                            link: { className: buttonClassName },
                            unlink: { className: buttonClassName }
                        },
                        list: {
                            options: ['unordered', 'ordered'],
                            unordered: { className: buttonClassName },
                            ordered: { className: buttonClassName }
                        },
                        remove: { className: buttonClassName }
                    }}
                    handleBeforeInput={handleBeforeInput}
                />
                {renderCharacterIndicator()}
            </div>
        );
    };

    return renderTextEditor();
};
export default TextEditor;
