import React, { RefObject, useEffect } from 'react';
import { areIntersecting, CategoryService, flat } from '../../../services';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { Categories, Category, CategoryGroup, CategorySubgroup, ProductCategory, ProductCategoryGroup, ProductCreation, ReleasedMachineCollection } from '../../../models';
import { CategoryGroupRule, CategorySubgroupRule } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import Accordion from '../../common/Accordion';
import Checkbox from '../../common/Checkbox';
import Grid from '../../common/Grid';
import List from '../../common/List';
import RadioButton from '../../common/RadioButton';
import Section, { SectionColor } from '../../common/Section';

interface ProductCategoryFormProps {
    accordionHeaderRefs: RefObject<HTMLDivElement>[];
    accordionsOpen: boolean[];
    categories: Categories;
    product: ProductCreation;
    readOnly?: boolean;
    onAccordionToggle: (index: number) => void;
    onChange: (product: Partial<ProductCreation>) => void;
    machineCollection?: ReleasedMachineCollection;
}

const ProductCategoryForm = ({ accordionHeaderRefs, accordionsOpen, categories, product, readOnly, onAccordionToggle, onChange,
    machineCollection }: ProductCategoryFormProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    useEffect(() => {
        if (categories && product) {
            categories.groups.forEach((x, i) => {
                if (x.rule === CategoryGroupRule.ExactlyOne && (!machineCollection || x.subgroups.every(y => machineCollection.categoryGroups.find(z => z.groupId === x.content.id)
                    .subgroups.find(z => z.subgroupId === y.content.id).rule !== CategorySubgroupRule.PrimarySecondary))
                    && (!CategoryService.isGroupValid(x, machineCollection, product) && CategoryService.isGroupValid(x, machineCollection, product))) {
                    accordionHeaderRefs[i].current.click();
                }
            });
        }
    });
    
    const isCategorySelected = (category: Category, categoryGroup: CategoryGroup) => {
        return CategoryService.getAllProductCategoryIds(product, categoryGroup.content.id).some(x => x === category.content.id);
    };

    const selectCategory = (category: Category, categoryGroup: CategoryGroup, categorySubgroup: CategorySubgroup, isPrimary: boolean) => {
        const productCategory = getProductCategory(product, categoryGroup.content.id, category.content.id);
        const group = product.categoryGroups.find(x => x.groupId === categoryGroup.content.id);
        const remainingGroups = product.categoryGroups.filter(x => x.groupId !== categoryGroup.content.id);
        const subGroupRule = CategoryService.getSubgroupRule(categoryGroup, categorySubgroup, machineCollection);
        const subgroupIds = categorySubgroup.categories.map(x => x.content.id);

        const getNewCategory = (isPrimary?: boolean): ProductCategory => ({ id: category.content.id, content: category.content, isPrimary, priorityOnUI: category.priorityOnUI, 
            link: category.link, obsolete: category.obsolete });
        
        let categoryGroups: ProductCategoryGroup[];

        switch (group.groupRule) {
            case CategoryGroupRule.ExactlyOne:
                switch (subGroupRule) {
                    case CategorySubgroupRule.NoRule:
                    case CategorySubgroupRule.ExactlyOne:
                        categoryGroups = [...remainingGroups, { ...group, categories: [getNewCategory()] }];
                        break;
                    case CategorySubgroupRule.PrimarySecondary:
                        categoryGroups = [...remainingGroups, {
                            ...group,
                            categories: isCategorySelected(category, categoryGroup) && !productCategory.isPrimary
                                ? group.categories.filter(x => x.id !== category.content.id)
                                : [...group.categories.filter(x => areIntersecting([x.id], subgroupIds) && (!isPrimary || !x.isPrimary)), getNewCategory(isPrimary)]
                        }];
                        break;
                    default:
                        break;
                }
                break;
            case CategoryGroupRule.OneOrMore:
                switch (subGroupRule) {
                    case CategorySubgroupRule.NoRule:
                        categoryGroups = [...remainingGroups, {
                            ...group,
                            categories: isCategorySelected(category, categoryGroup)
                                ? group.categories.filter(x => x.id !== category.content.id)
                                : [...group.categories, getNewCategory()]
                        }];
                        break;
                    case CategorySubgroupRule.ExactlyOne:
                        categoryGroups = [...remainingGroups, {
                            ...group,
                            categories: [...group.categories.filter(x => !areIntersecting([x.id], subgroupIds)), getNewCategory()]
                        }];
                        break;
                    case CategorySubgroupRule.PrimarySecondary:
                        categoryGroups = [...remainingGroups, {
                            ...group,
                            categories: isCategorySelected(category, categoryGroup)
                                ? productCategory.isPrimary 
                                    ? group.categories.filter(x => !areIntersecting([x.id], subgroupIds)) 
                                    : group.categories.filter(x => x.id !== category.content.id)
                                : [...group.categories.filter(x => !areIntersecting([x.id], subgroupIds) || (!isPrimary || !x.isPrimary)), getNewCategory(isPrimary)]
                        }];
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        onChange({ categoryGroups });
    };

    const getSelectedCategoriesText = (categoryGroup: CategoryGroup) =>
        flat(categoryGroup.subgroups.map(x => x.categories)).filter(x => isCategorySelected(x, categoryGroup)).map(x => x.content.en).join(', ');

    const getProductCategory = (product: ProductCreation, categoryGroupId: number, categoryId: number) =>
        product.categoryGroups.find(x => x.groupId === categoryGroupId).categories.find(x => x.id === categoryId);

    const sortCategories = (categories: Category[]) =>
        [...categories].sort((a, b) => {
            const getTextValue = (category: Category) => category.content.en.toUpperCase();

            const textA = getTextValue(a);
            const textB = getTextValue(b);
            const textOther = 'OTHER';

            return textA !== textOther && (textA < textB || textB === textOther)
                ? -1
                : textB !== textOther && (textB < textA || textA === textOther)
                    ? 1
                    : 0;
        });

    const renderCategorySubgroup = (categoryGroup: CategoryGroup, categorySubgroup: CategorySubgroup) => {
        const subgroupRule = CategoryService.getSubgroupRule(categoryGroup, categorySubgroup, machineCollection);
        
        const getHeader = (isPrimary: boolean) =>
            `${categorySubgroup.content.en}${subgroupRule === CategorySubgroupRule.PrimarySecondary 
                ? ` (${translate(isPrimary ? translations.category.primary : translations.category.secondary)})` 
                : ''}`;

        const highlighted = !CategoryService.isSubgroupValid(categoryGroup, categorySubgroup, machineCollection, product);

        const isRadioButton = (isPrimary: boolean) => (categoryGroup.rule === CategoryGroupRule.ExactlyOne && isPrimary) 
        || (subgroupRule !== CategorySubgroupRule.NoRule && isPrimary);

        const isSelected = (category: Category, isPrimary: boolean) => isCategorySelected(category, categoryGroup) && (subgroupRule !== CategorySubgroupRule.PrimarySecondary ||
            getProductCategory(product, categoryGroup.content.id, category.content.id).isPrimary === isPrimary);

        const isDisabled = (category: Category, isPrimary: boolean) => category.obsolete || readOnly || (subgroupRule === CategorySubgroupRule.PrimarySecondary && isSelected(category, !isPrimary));

        return (
            <>
                {[true, false].map(x =>
                    (x || (subgroupRule === CategorySubgroupRule.PrimarySecondary && categoryGroup.subgroups.some(y => y.categories.some(z => isSelected(z, true))))) &&
                        <List
                            key={categorySubgroup.content.id}
                            header={getHeader(x)}
                            highlighted={highlighted}
                            items={sortCategories(categorySubgroup.categories).map(y => ({
                                key: y.content.id,
                                content: isRadioButton(x)
                                    ? <RadioButton dark={dark} selected={isSelected(y, x)} disabled={isDisabled(y, x)} 
                                                   label={`${y.content.en}${y.obsolete ? ` (${translate(translations.common.obsolete)})` : ''}`} onChange={() => selectCategory(y, categoryGroup, categorySubgroup, x)} />
                                    : <Checkbox dark={dark} checked={isSelected(y, x)} disabled={isDisabled(y, x)} 
                                                label={`${y.content.en}${y.obsolete ? ` (${translate(translations.common.obsolete)})` : ''}`} onChange={() => selectCategory(y, categoryGroup, categorySubgroup, x)} />
                            }))}
                        />
                )}
            </>
        );
    };

    const renderCategoryGroup = (categoryGroup: CategoryGroup, index: number) => {
        const selectedText = getSelectedCategoriesText(categoryGroup);
        const highlighted = !CategoryService.isGroupValid(categoryGroup, machineCollection, product);

        return (
            <Accordion dark={dark} label={categoryGroup.content.en} highlighted={highlighted} closedLabel={selectedText} 
                       icon={CategoryService.getIconType(index)} open={accordionsOpen[index]} onClick={() => onAccordionToggle(index)}>
                    <Grid columns={4} columnsBig={3} columnsMedium={2} columnsSmall={1} rowGap={10}>
                        {categoryGroup.subgroups.map(x => renderCategorySubgroup(categoryGroup, x))}
                    </Grid>
                </Accordion>
        );
    };

    const renderForm = () => {        
        return categories && (
            <div>
                {categories.groups.map((x, i) =>
                    <Section color={dark ? SectionColor.DeepBlue : SectionColor.White}>
                        {renderCategoryGroup(x, i)}
                    </Section>
                )}
            </div>
        );
    };

    return renderForm();
};
export default ProductCategoryForm;
