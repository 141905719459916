import React, { CSSProperties, KeyboardEvent, ReactNode, ReactText, RefObject } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import Icon, { IconType } from './Icon';

export interface Cell {
    content: ReactText | JSX.Element;
    inputStyle?: boolean;
}

export interface Column {
    label: ReactText;
    bold?: boolean;
    highlighted?: boolean;
    key?: ReactText;
    max?: string;
    min?: string;
    notClickable?: boolean;
}

export interface Row {
    key: ReactText;
    cells: Cell[];
    divider?: boolean;
    onClick?: () => void;
}

interface TableProps {
    columns: Column[];
    rows: Row[];
    dark?: boolean;
    hoverable?: boolean;
    indexSortedBy?: number;
    noHeader?: boolean;
    noScrollbar?: boolean;
    reverse?: boolean;
    scrollId?: string;
    tableRef?: RefObject<HTMLDivElement>;
    onHeaderClick?: (index: number) => void;
}

const AUTO = 'auto';

const Table = ({ columns, dark, hoverable, indexSortedBy, noHeader, noScrollbar, reverse, rows, scrollId, tableRef, onHeaderClick }: TableProps) => {
    const gridStyle: CSSProperties = {
        gridTemplateColumns: columns.map(x => `minmax(${x.min ?? AUTO}, ${x.max ?? AUTO})`).join(' '),
        gridTemplateRows: [AUTO, ...rows.map(x => x.divider ? '1fr' : AUTO)].join(' ')
    };

    const handleSelect = (column: Column, index: number) => {
        if (onHeaderClick && !column.notClickable) {
            onHeaderClick(index);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, column: Column, index: number) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleSelect(column, index);
        }
    };

    const sized = (column: Column) => Boolean(column.min || column.max);

    const selectable = (column: Column) => onHeaderClick && !column.notClickable;

    const renderScrollSyncWrapper = (children: ReactNode) => scrollId ? <ScrollSyncPane group={scrollId}>{children}</ScrollSyncPane> : children;

    return (
        <div ref={tableRef} className={`table ${dark ? 'dark' : ''}`}>
            {renderScrollSyncWrapper(
                <div className={`table-container ${noScrollbar ? 'no-scrollbar' : ''}`}>
                    <div className='table-grid' style={gridStyle}>
                        {!noHeader &&
                            <div className='table-row'>
                                {columns.map((x, i) =>
                                    <div key={x.key ?? x.label} className={`table-cell table-header-cell ${x.bold ? 'bold' : ''} ${x.highlighted ? 'highlighted' : ''} ${selectable(x) ? 'clickable' : ''}`}
                                        tabIndex={selectable(x) ? 0 : undefined} onClick={() => handleSelect(x, i)} onKeyDown={e => handleKeyDown(e, x, i)}>
                                        {x.label}
                                        {indexSortedBy === i
                                            ? <Icon type={reverse ? IconType.SortDescending : IconType.SortAscending} />
                                            : <div />
                                        }
                                    </div>
                                )}
                            </div>
                        }
                        {rows.map(x =>
                            <div key={x.key} className={`table-row ${hoverable && !x.divider ? 'hoverable' : ''} ${x.onClick ? 'clickable' : ''} ${noHeader ? 'no-header' : ''} `
                                + `${noScrollbar ? 'no-border-last' : ''}`} onClick={x.onClick}>
                                {columns.map((y, j) =>
                                    <div key={y.key ?? y.label} className={`table-cell table-data-cell ${columns.some(sized) ? 'single-line' : ''} ${sized(y) ? 'sized' : ''} `
                                        + `${x.cells[j]?.inputStyle ? 'input-style' : ''} ${y.highlighted ? 'highlighted' : ''}`}>
                                        {x.divider
                                            ? <div className='divider-content' />
                                            : x.cells[j]?.content
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Table;
