import { RowValues } from '../models';

export const DEFAULT_ROW_VALUES: RowValues = {
    requestTable: 5,
    chiefEditorTable: 5,
    myMachinesTable: 5,
    incompleteTable: 5,
    inReviewTable: 5,
    reviewedTable: 5,
    sharedMachines: 5
};
