import React from 'react';
import { Categories, Category, CategoryGroup, CategorySubgroup, MachineCollectionCreationCategoryGroup, MachineCollectionCreation } from '../../../models';
import { TRANSLATIONS } from '../../../constants';
import { CategorySubgroupRule } from '../../../enums';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Accordion from '../../common/Accordion';
import Checkbox from '../../common/Checkbox';
import List from '../../common/List';
import Section, { SectionColor } from '../../common/Section';
import Select from '../../common/Select';

interface MachineCollectionCategoryFormProps {
    accordionsOpen: boolean[];
    categories: Categories;
    machineCollection: MachineCollectionCreation;
    onAccordionToggle: (index: number) => void;
    onChange: (machineCollection: Partial<MachineCollectionCreation>) => Promise<void>;
}

const MachineCollectionCategoryForm = ({ accordionsOpen, categories, machineCollection, onAccordionToggle, onChange }: MachineCollectionCategoryFormProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const areCategoriesSelected = (categories: Category[], groupId: number, subgroupId: number) => {
        return categories.every(x => {
            const subgroup = machineCollection.categoryGroups.find(y => y.groupId === groupId).subgroups.find(y => y.subgroupId === subgroupId);

            return subgroup ? subgroup.categoryIds.some(y => y === x.content.id) : false;
        });
    };

    const selectCategories = (categories: Category[], groupId: number, subgroupId: number) => {
        let categoryGroups: MachineCollectionCreationCategoryGroup[];

        const group = machineCollection.categoryGroups.find(x => x.groupId === groupId);
        const remainingGroups = machineCollection.categoryGroups.filter(x => x.groupId !== groupId);
        const subgroup = group.subgroups.find(x => x.subgroupId === subgroupId);
        const remainingSubgroups = group.subgroups.filter(x => x.subgroupId !== subgroupId);

        if (areCategoriesSelected(categories, groupId, subgroupId)) {

            if (categories.length === subgroup.categoryIds.length) {
                categoryGroups = [...remainingGroups, { ...group, subgroups: remainingSubgroups }];
            } else {
                categoryGroups = [...remainingGroups, { ...group, subgroups: [...remainingSubgroups, { ...subgroup,
                    categoryIds: subgroup.categoryIds.filter(x => categories.every(y => x !== y.content.id)) }] }];
            }
        } else {
            if (subgroup) {
                categoryGroups = [...remainingGroups, { ...group, subgroups: [...remainingSubgroups, { ...subgroup,
                    categoryIds: [...subgroup.categoryIds, ...categories.map(x => x.content.id)] }] }];
            } else {
                categoryGroups = [...remainingGroups, { ...group, subgroups: [...remainingSubgroups, { subgroupId, rule: CategorySubgroupRule.NoRule,
                    categoryIds: categories.map(x => x.content.id) }] }];
            }
        }

        onChange({ categoryGroups });
    };

    const setCategorySubgroupRule = (rule: CategorySubgroupRule, groupId: number, subgroupId: number) => {        
        const group = machineCollection.categoryGroups.find(x => x.groupId === groupId);
        const remainingGroups = machineCollection.categoryGroups.filter(x => x.groupId !== groupId);
        const subgroup = group.subgroups.find(x => x.subgroupId === subgroupId);
        const remainingSubgroups = group.subgroups.filter(x => x.subgroupId !== subgroupId);

        const categoryGroups = [...remainingGroups, { ...group, subgroups: [...remainingSubgroups, { ...subgroup, rule }] }];

        onChange({ categoryGroups });
    };

    const renderCategorySubgroup = (categorySubgroup: CategorySubgroup, groupId: number) => {        
        const collectionSubgroup = machineCollection.categoryGroups.find(x => x.groupId === groupId).subgroups.find(x => x.subgroupId === categorySubgroup.content.id);
        const rule = collectionSubgroup ? collectionSubgroup.rule : null;
        const rules = Object.values(CategorySubgroupRule).filter(x => !isNaN(Number(x))) as CategorySubgroupRule[];
        
        return (
            <List
                key={categorySubgroup.content.id}
                header={
                    //<Flex direction={FlexDirection.Row} justification={FlexJustification.SpaceBetween}>
                    <>
                        <Checkbox
                            dark={dark}
                            checked={areCategoriesSelected(categorySubgroup.categories, groupId, categorySubgroup.content.id)}
                            label={categorySubgroup.content.en}
                            onChange={() => selectCategories(categorySubgroup.categories, groupId, categorySubgroup.content.id)}
                        />
                        <Select
                            dark={dark}
                            label={translate(translations.dialog.notSelected)}
                            disabled={rule === null}
                            value={rule}
                            values={rules}
                            onSelect={x => setCategorySubgroupRule(x, groupId, categorySubgroup.content.id)}
                            mapToString={x => translate(translations.category.subgroupRule[Number(x).toString() as keyof typeof translations.category.subgroupRule])}
                        />
                    </>
                    //</Flex>
                }
                items={categorySubgroup.categories.map(x => ({
                    key: x.content.id,
                    content: <Checkbox
                        dark={dark}
                        checked={areCategoriesSelected([x], groupId, categorySubgroup.content.id)}
                        label={x.content.en}
                        onChange={() => selectCategories([x], groupId, categorySubgroup.content.id)}
                    />
                }))}
            />
        );
    };

    const renderCategoryGroup = (categoryGroup: CategoryGroup, index: number) => {
        return (
            <Accordion dark={dark} label={categoryGroup.content.en} open={accordionsOpen[index]} onClick={() => onAccordionToggle(index)}>
                {categoryGroup.subgroups.map(x => renderCategorySubgroup(x, categoryGroup.content.id))}
            </Accordion>
        );
    };

    const renderForm = () => {        
        return categories && machineCollection && (
            <div>
                {categories.groups.map((x, i) =>
                    <Section key={i} color={dark ? SectionColor.DeepBlue : SectionColor.White}>
                        {renderCategoryGroup(x, i)}
                    </Section>
                )}
            </div>
        );
    };

    return renderForm();
};
export default MachineCollectionCategoryForm;
