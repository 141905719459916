import React, { Component, ReactText } from 'react';

export interface ListItem {
    content: JSX.Element | ReactText;
    key: ReactText;
}

interface ListProps {
    items: ListItem[];
    className?: string;
    header?: JSX.Element | ReactText;
    highlighted?: boolean;
}

export default class List extends Component<ListProps> {
    private renderItem(item: ListItem) {
        return (
            <div key={item.key} className='item'>
                {item.content}
            </div>
        );
    }

    private renderList() {
        const { className, header, highlighted, items } = this.props;

        const containerClassName = `list-container ${className ? className : ''}`;
        const headerClassName = `header ${highlighted ? 'highlighted' : ''}`;

        return (
            <div className={containerClassName}>
                {header &&
                    <div className={headerClassName}>
                        {header}
                    </div>
                }
                <div className='items'>
                    {items.map(x => this.renderItem(x))}
                </div>
            </div>
            
        );
    }

    render() {
        return this.renderList();
    }
}
