import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api, apiCall } from '../../../services';
import { Company } from '../../../models';
import { EMPTY_COMPANY, TRANSLATIONS } from '../../../constants';
import DefaultCompany from './DefaultCompany';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setLoading, toggleDefaultCompanyForm } from '../../../store';

const DefaultCompanyManager = () => {
    const showDefaultCompanyForm = useAppSelector(state => state.app.showDefaultCompanyForm);
    const dark = useAppSelector(state => state.layout.dark);
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [company, setCompany] = useState(EMPTY_COMPANY);

    const handleChange = (newCompany: Partial<Company>) => {
        setCompany({ ...company, ...newCompany });
    };

    const handleSubmit = async () => {
        dispatch(setLoading(true));

        await apiCall(
            Api.saveCompany(company),
            async () => {
                dispatch(toggleDefaultCompanyForm());
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulSave).toString(), type: SnackbarType.Success, dark: dark }));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.companySave).toString(), type: SnackbarType.Error, dark: dark }));
            }
        );

        dispatch(setLoading(false));
    };

    const renderForm = () => {
        return showDefaultCompanyForm && (
            <DefaultCompany company={company} onChange={handleChange} onSubmit={handleSubmit} />
        );
    };

    return renderForm();
};
export default DefaultCompanyManager;
