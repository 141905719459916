import React, { Component, createRef, ReactNode, RefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface FloatProps {
    children: ReactNode;
    bottom: number;
    paddingRight?: number;
    dark?: boolean;
}

interface FloatState {
    shouldFloat: boolean;
}

export default class Float extends Component<FloatProps, FloatState> {
    private readonly baseRef: RefObject<HTMLDivElement>;
    private readonly resizeObserver: ResizeObserver;

    constructor(props: FloatProps) {
        super(props);

        this.state = {
            shouldFloat: false
        };

        this.baseRef = createRef();
        this.resizeObserver = new ResizeObserver(this.floatCheck);
    }

    componentDidMount() {
        this.floatCheck();

        window.addEventListener('scroll', this.floatCheck);
        window.addEventListener('resize', this.floatCheck);
        this.resizeObserver.observe(document.body);
    }

    private readonly floatCheck = () => {
        const { bottom } = this.props;
        const { shouldFloat } = this.state;
        const { current: base } = this.baseRef;
        
        if (base) {
            const windowHeight = window.innerHeight;
            const scrollDistance = window.pageYOffset;
            const baseDistanceFromTop = base.offsetTop;
            const baseHeight = base.offsetHeight;

            const offset = windowHeight + scrollDistance - baseDistanceFromTop - baseHeight - bottom;
            
            if (offset < 0 !== shouldFloat) {
                this.setState(s => ({ shouldFloat: !s.shouldFloat }));
            }
        } else {
            window.removeEventListener('scroll', this.floatCheck);
            window.removeEventListener('resize', this.floatCheck);
            this.resizeObserver.disconnect();
        }
    };

    private renderFloat() {
        // eslint-disable-next-line react/prop-types
        const { bottom, children, dark, paddingRight } = this.props;
        const { shouldFloat } = this.state;
        const { current: base } = this.baseRef;

        const baseClassName = `base ${shouldFloat ? 'unvisible' : ''}`;
        const floatClassName = `float ${shouldFloat ? 'floating' : ''} ${dark ? 'dark' : ''}`;
        const baseDistanceFromRight = base ? document.body.clientWidth - base.offsetLeft - base.offsetWidth : 0;
        
        return (
            <div className='_float-container'>
                <div ref={this.baseRef} className={baseClassName} >
                    {children}
                </div>
                <div className={floatClassName} style={{ bottom: bottom, right: baseDistanceFromRight, paddingRight: paddingRight ? paddingRight : 0 }}>
                    {children}
                </div>
            </div>
        );
    }

    render() {
        return this.renderFloat();
    }
}
