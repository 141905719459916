import React, { useState } from 'react';
import { ContentService, DateService } from '../../../services';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { Content, Hall, ProductCreation, ReleasedMachineCollection, Tag } from '../../../models';
import { MachineCollectionType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import Button, { ButtonType } from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import DateInput from '../../common/DateInput';
import FairTag from '../../common/FairTag';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Grid from '../../common/Grid';
import Icon, { IconType } from '../../common/Icon';
import Modal from '../../common/Modal';
import Select from '../../common/Select';
import SideIcon from '../../common/SideIcon';
import SpacingContainer from '../../common/SpacingContainer';
import { Tabs, Tab } from '../../common/Tabs';
import TextEditor from '../../common/TextEditor';
import TextInput from '../../common/TextInput';
import Title from '../../common/Title';

interface ProductReleaseBasicFormProps {
    imageUrl: string;
    delegated: boolean
    machineCollection: ReleasedMachineCollection;
    product: ProductCreation;
    tags: Tag[];
    readOnly?: boolean;
    onDelegationToggled: () => void;
    onProductChange: (product: Partial<ProductCreation>) => Promise<void>;
}

const ProductReleaseBasicForm = ({ imageUrl, delegated, machineCollection, product, tags, readOnly, onDelegationToggled,
    onProductChange }: ProductReleaseBasicFormProps) => {
    const MAX_LENGTH = 1000;
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [activeTab, setActiveTab] = useState(0);
    const [shouldDisplayTagPicker, setShouldDisplayTagPicker] = useState(false);

    const handleTabSelect = (index: number) => {
        setActiveTab(index);
    };

    const handleInputChange = (value: string, name: string) => {
        onProductChange({ [name]: value });
    };

    const handleHallChange = (hall: Hall) => {
        onProductChange({ hallId: hall.id });
    };

    const handleDateChange = async (date: string) => {
        await onProductChange({ customReleaseDate: new Date(date) });
    };

    const handleDescriptionChange = (value: string, language: string) => {
        onProductChange({ description: { ...product.description, [language]: value } });
    };

    const handleSecondDescriptionChange = (value: string, language: string) => {
        onProductChange({ secondDescription: { ...product.secondDescription, [language]: value } });
    };

    const openFairPickerModal = async () => {
        setShouldDisplayTagPicker(true);
    };

    const closeFairPickerModal = async () => {
        setShouldDisplayTagPicker(false);
    };

    const handleAddTagToProduct = (tag: Tag, filteredTagsLength: number) => {
        if (filteredTagsLength === 1) {
            closeFairPickerModal();
        }

        onProductChange({ tags: [...product.tags, tag] });
    };

    const handleRemoveTagFromProduct = (tag: Tag) => {
        if (!readOnly) {
            onProductChange({ tags: product.tags.filter(x => x.id !== tag.id) });
        }
    };

    const getDateErrorMessage = (date: string) => {
        return new Date(date) < DateService.getDayBeginning(machineCollection.fairPreStartDate)
            ? translate(translations.error.preReleaseStart).replace('{date}', DateService.toString(machineCollection.fairPreStartDate))
            : new Date(date) >= DateService.getNextDayBeginning(machineCollection.fairStartDate)
                ? translate(translations.error.preReleaseEnd).replace('{date}', DateService.toString(machineCollection.fairStartDate))
                : '';
    };

    const isLanguageValid = (language: string) => {
        return (delegated && ContentService.getLength(product.description[language as keyof Content].toString()) === 0) 
            || ContentService.isValid(product.description[language as keyof Content].toString(), MAX_LENGTH);
    };

    const renderFairPickerModal = () => {
        const filteredTags = tags ? tags.filter(x => product.tags.every(y => x.id !== y.id) && !x.outdated) : [];

        return shouldDisplayTagPicker && (
            <Modal dark={dark} open={true}>
                <Title style={{ marginBottom: 30 }} text={translate(translations.dialog.fairSelect)} bold uppercase />
                {filteredTags.map(x => <FairTag key={x.id} id={x.id} text={x.name} color={x.color} handleOnClick={() => handleAddTagToProduct(x, filteredTags.length)} noClose />)}
                <Flex direction={FlexDirection.Row} style={{ marginTop: 30 }} justification={FlexJustification.FlexEnd}>
                    <Button dark={dark} type={ButtonType.Tertiary} onClick={closeFairPickerModal}>
                        <Icon type={IconType.Close} />
                        {translate(translations.action.close)}
                    </Button>
                </Flex>
            </Modal>
        );
    };

    const renderDescriptionInput = (language: string) => {
        const shouldShowCheckBox = machineCollection.availableLanguages.length > 1 || machineCollection.availableLanguages[0] !== 'en';
        const delegateDisabled = readOnly
            || (!delegated && ContentService.addEnglish(machineCollection.availableLanguages)
                .every(x => ContentService.getLength(product.description[x as keyof Content].toString()) === 0
                    || ContentService.getLength(product.secondDescription[x as keyof Content].toString()) === 0));
        
        return (
            <>
                <TextEditor dark={dark} disabled={readOnly} error={!ContentService.removeTags(product.description[language as keyof Content].toString()).length} 
                            value={product.description[language as keyof Content].toString()} label={translate(translations.common.description)} maxLength={1000} 
                            onChange={x => handleDescriptionChange(x, language)} required={!delegated} allowFormating />
                <TextEditor dark={dark} disabled={readOnly} error={!ContentService.removeTags(product.secondDescription[language as keyof Content].toString()).length} 
                            value={product.secondDescription[language as keyof Content].toString()} label={translate(translations.common.descriptionCNC)} maxLength={1000} 
                            onChange={x => handleSecondDescriptionChange(x, language)} required={!delegated} allowFormating />
                {shouldShowCheckBox &&
                    <Checkbox dark={dark} label={translate(translations.dialog.delegateTranslation)} checked={delegated} disabled={delegateDisabled} 
                              onChange={onDelegationToggled} />
                }
            </>
        );
    };

    const renderForm = () => {
        const languagesToShow = ContentService.addEnglish(machineCollection.availableLanguages);
        const selectedHall = machineCollection.halls.find(x => x.id === product.hallId);
        const descriptionSectionMargin = machineCollection.type === MachineCollectionType.EBooklet ? 30 : 0;
        /*const languageHighlighted = delegated
                                    ? languagesToShow.every(x => ContentService.getLength(product.description[x as keyof Content].toString()) === 0)
                                    : languagesToShow.some(x => !isLanguageValid(x));*/
        const dateHighlighted = product.customReleaseDate < DateService.getDayBeginning(machineCollection.fairPreStartDate)
            || product.customReleaseDate >= DateService.getNextDayBeginning(machineCollection.fairStartDate);
        const hasAllTags = tags.every(x => product.tags.some(y => y.id === x.id));

        return (
            <>
                <Grid columns={2} columnsMedium={1} columnGap={40} rowGap={30}>
                    <SpacingContainer>
                        {machineCollection.type === MachineCollectionType.EBooklet &&
                            <>
                                <SideIcon iconType={IconType.Bookmark} highlighted={product.hallId === undefined || product.hallId === null}>
                                    <Select dark={dark} disabled={readOnly} error={!selectedHall} value={selectedHall} values={machineCollection.halls} mapToString={_x => ''} 
                                            label={translate(translations.hall.hall)} onSelect={handleHallChange} required />
                                </SideIcon>
                                <SideIcon iconType={IconType.Warning} highlighted={!product.booth}>
                                    <TextInput dark={dark} disabled={readOnly} label={translate(translations.product.booth)} error={!product.booth} value={product.booth} 
                                               name='booth' onChange={handleInputChange} required />
                                </SideIcon>
                            </>
                        }
                        <SideIcon iconType={IconType.Information}>
                            <TextInput dark={dark} disabled={readOnly} label={translate(translations.common.name)} error={!product.title} value={product.title} name='title'
                                       onChange={handleInputChange} required />
                        </SideIcon>
                        <SideIcon iconType={IconType.Image} style={{ marginTop: 30 }}>
                            <img src={imageUrl} width='100%' />
                        </SideIcon>
                    </SpacingContainer>
                    <div>
                        {machineCollection.type === MachineCollectionType.EBooklet &&
                            <SideIcon iconType={IconType.Calendar} highlighted={dateHighlighted}>
                                <DateInput dark={dark} disabled={readOnly} label={translate(translations.product.customReleaseDate)} 
                                           value={product.customReleaseDate.toDateString()} onChange={handleDateChange} getMessage={getDateErrorMessage} />
                            </SideIcon>
                        }
                        <SideIcon style={{ marginTop: descriptionSectionMargin }} iconType={IconType.Menu}>
                            <Tabs
                                dark={dark}
                                selectedTab={activeTab}
                                tabs={languagesToShow.map<Tab>(x => ({ 
                                    id: x, 
                                    label: translate(translations.language[x as keyof typeof translations.language]), 
                                    content: renderDescriptionInput(x), 
                                    highlighted: !isLanguageValid(x) }))}
                                onSelect={handleTabSelect}
                            />
                        </SideIcon>
                        <SideIcon style={{ marginTop: 30 }} iconType={IconType.Bookmark}>
                            <div>
                                <Title style={{ marginBottom: 10 }} text={translate(translations.dialog.fairExhibited)} bold />
                                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexStart}>
                                    {product.tags.map(x => <FairTag key={x.id} id={x.id} text={x.name} color={x.color} handleOnClick={() => handleRemoveTagFromProduct(x)}
                                                                    noClose={readOnly} />)}
                                    {!readOnly && !hasAllTags &&
                                        <Button dark={dark} type={ButtonType.Secondary} onClick={() => openFairPickerModal()}>
                                            <Icon type={IconType.Plus} />
                                        </Button>
                                    }
                                </Flex>
                            </div>
                        </SideIcon>
                    </div>
                    {renderFairPickerModal()}
                </Grid>
            </>
        );
    };

    return renderForm();
};
export default ProductReleaseBasicForm;
