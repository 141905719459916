import React, { useState } from 'react';
import { Hall } from '../../../models';
import { EMPTY_HALL, TRANSLATIONS } from '../../../constants';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import ImageUpload from '../../common/ImageUpload';
import Link from '../../common/Link';
import Modal from '../../common/Modal';
import SpacingContainer from '../../common/SpacingContainer';
import Title from '../../common/Title';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import TextInput from '../../common/TextInput';
import Table from '../../common/Table';

interface MachineCollectionHallFormProps {
    halls: Hall[];
    onHallAdd: (hall: Hall) => Promise<void>;
    onHallChange: (index: number, hall: Hall) => Promise<void>;
    onHallDelete: (index: number) => Promise<void>;
}

const MachineCollectionHallForm = ({ halls, onHallAdd, onHallChange, onHallDelete }: MachineCollectionHallFormProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [hallIndex, setHallIndex] = useState(null);
    const [hall, setHall] = useState(null);
    const [shouldDisplayDialog, setShouldDisplayDialog] = useState(false);
    const [shouldDisplayModal, setShouldDisplayModal] = useState(false);

    const openModalWindow = (newHall: Hall = null) => {
        if (newHall) {
            setHall(newHall);
            setHallIndex(halls.indexOf(newHall));
            setShouldDisplayModal(true);
        } else {
            setHall({ ...EMPTY_HALL });
            setShouldDisplayModal(true);
        }
    };

    const closeModalWindow = async () => {
        setHallIndex(null);
        setHall(null);
        setShouldDisplayModal(false);
    };

    const saveModalWindow = async () => {
        if (hallIndex !== null) {
            await onHallChange(hallIndex, hall);
        } else {
            await onHallAdd(hall);
        }
        
        closeModalWindow();
    };
    
    const openDialog = (newHall: Hall) => {
        setHallIndex(halls.indexOf(newHall));
        setShouldDisplayDialog(true);
    };

    const closeDialog = () => {
        setHallIndex(null);
        setShouldDisplayDialog(false);
    };

    const confirmDelete = async () => {
        await onHallDelete(hallIndex);
        closeDialog();
    };

    const handleNameChange = (value: string, _name: string) => {
        setHall({ ...hall, name: value });
    };

    const handleFileReceived = (file: File) => {
        if (!hall.image || hall.image.name !== file.name) {
            setHall({ ...hall, image: file });

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = x => setHall({ ...hall, imageUrl: x.target['result'] as string });
        }
    };

    const isModalValid = () => {
        return hall.name && isImageValid();
    };

    const isImageValid = () => {
        return Boolean(hall.imageId || hall.image);
    };
    
    const renderDeleteDialog = () => {
        return shouldDisplayDialog && (
            <Modal dark={dark} open={shouldDisplayDialog}>
                <Title text={translate(translations.dialog.confirmDelete)} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    <Link dark={dark} text={translate(translations.action.yes)} onClick={confirmDelete} />
                    <Link dark={dark} text={translate(translations.action.no)} onClick={closeDialog} />
                </Flex>
            </Modal>            
            );
    };
    
    const renderModalWindow = () => {
        return shouldDisplayModal && (
            <Modal dark={dark} open={true}>
                <SpacingContainer>
                    <Title style={{ marginBottom: 30 }} text={hallIndex !== null ? translate(translations.hall.editHall) : translate(translations.hall.createHall)}
                        uppercase bold />
                    <TextInput dark={dark} error={!hall.name} name='name' label={translate(translations.common.name)} value={hall.name} onChange={handleNameChange} required />
                    <ImageUpload dark={dark} label={translate(translations.common.picture)} additonalText={translate(translations.dialog.imageFormat)} imageUrl={hall.imageUrl} 
                                 validTypes={['image/jpeg', 'image/jpg', 'image/png']} onFileReceived={handleFileReceived} highlighted={!isImageValid()} 
                                 minWidth={800} minHeight={600} maxSizeInMegabytes={8} />
                    <Flex direction={FlexDirection.Row} style={{ marginTop: 30 }} justification={FlexJustification.FlexEnd}>
                        <Button dark={dark} type={ButtonType.Secondary} onClick={closeModalWindow}>{translate(translations.action.cancel)}</Button>
                        <Button dark={dark} type={ButtonType.Primary} disabled={!isModalValid()} onClick={saveModalWindow}>{translate(translations.action.save)}</Button>
                    </Flex>
                </SpacingContainer>
            </Modal>
        );
    };

    const renderHeaderActions = () => {
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                <Link dark={dark} text={translate(translations.action.create)} onClick={() => openModalWindow()} />
            </Flex>
        );
    };

    const renderRowActions = (hall: Hall) => {
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                <Link dark={dark} text={translate(translations.action.edit)} onClick={() => openModalWindow(hall)} />
                <Link dark={dark} text={translate(translations.action.delete)} onClick={() => openDialog(hall)} />
            </Flex>
        );
    };

    const renderTable = () => {
        return <Table
            dark={dark}
            columns={[
                { label: translate(translations.common.name), min: '100px', max: '2fr' },
                { label: translate(translations.common.name), min: '100px', max: '2fr' },
                { label: '', min: '100px', max: '2fr' }
            ]}
            rows={[
                ...halls.map(x => ({
                    key: x.id,
                    cells: [
                        { content: x.name },
                        { content: x.image ? x.image.name : '' },
                        { content: renderRowActions(x) }]
                }))
            ]}
        />;
    };

    const renderForm = () => {
        return (
            <>
                <ContentContainer style={{ marginTop: 30 }}>
                    {renderHeaderActions()}
                </ContentContainer>
                <ContentContainer style={{ marginTop: 30, marginBottom: 50 }}>
                    {renderTable()}
                </ContentContainer>
                {renderDeleteDialog()}
                {renderModalWindow()}
            </>
        );
    };

    return renderForm();
};
export default MachineCollectionHallForm;
