import React, { KeyboardEvent } from 'react';
import Icon, { IconType } from './Icon';

interface MenuIconProps {
    open: boolean;
    onClick: () => void;
}

const MenuIcon = ({ open, onClick }: MenuIconProps) => {
    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onClick();
        }
    };

    return (
        <div className={`menu-icon ${open ? 'open' : ''}`} tabIndex={0} onClick={onClick} onKeyDown={handleKeyDown}>
            <Icon type={IconType.Menu} />
        </div>
    );
};

export default MenuIcon;
