import { configureStore } from '@reduxjs/toolkit';
import { appReducers, bannerReducer, layoutReducer, loaderReducer, snackbarReducer, translationReducer } from '.';

export const store = configureStore({
    reducer: {
        app: appReducers,
        banners: bannerReducer,
        layout: layoutReducer,
        loader: loaderReducer,
        snackbars: snackbarReducer,
        translation: translationReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
