import { useEffect, useState } from 'react';

export const useScrolledPast = (treshold?: number) => {
    const [scrolledPast, setScrolledPast] = useState(false);

    useEffect(() => {
        if (!treshold || treshold >= 0) {
            const handleScroll = () => {
                setScrolledPast(window.scrollY > (treshold ?? 0));
            };

            handleScroll();

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [treshold]);

    return scrolledPast;
};
