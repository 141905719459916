import React from 'react';
import { useSelector } from 'react-redux';
import { AuthService, ProductService } from '../../../services';
import { Company, ProductCreation, User } from '../../../models';
import { EMPTY_COMPANY, EMPTY_GUID, TRANSLATIONS } from '../../../constants';
import CompanyForm from './CompanyForm';
import ContentContainer from '../../common/ContentContainer';
import Grid from '../../common/Grid';
import Select from '../../common/Select';
import SideIcon from '../../common/SideIcon';
import SpacingContainer from '../../common/SpacingContainer';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { IconType } from '../../common/Icon';

interface CompanyFormWithSelectionProps {
    companies: Company[];
    company: Company;
    editing?: boolean
    product?: ProductCreation;
    readOnly?: boolean;
    user?: User;
    onChange: (company: Partial<Company>) => void;
    onProductChange?: (product: Partial<ProductCreation>) => void;
    onSet: (company: Company) => Promise<void>;
}

const CompanyFormWithSelection = ({ companies, company, editing, product, readOnly, user, onChange, onProductChange, onSet }: CompanyFormWithSelectionProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const renderForm = () => {
        const emptyCompany = { ...EMPTY_COMPANY, id: EMPTY_GUID };
        const selectableCompanies = companies ? [ProductService.addRepresentativeToCompany(emptyCompany, AuthService.getUserName()), ...companies] : null;
        const getContent = (company: Company) => company.id === EMPTY_GUID
            ? translate(translations.company.newCompany)
            : `${company.name}, ${company.city} (${translate(translations.countries[company.country as keyof typeof translations.countries])})`;

        return (
            <ContentContainer>
                <SpacingContainer>
                    {companies && selectableCompanies &&
                        <Grid columns={2} columnsMedium={1} columnGap={40}>
                            <SideIcon iconType={IconType.GroupPeople}>
                                <Select dark={dark} disabled={readOnly || !AuthService.hasRole(['SiemensRepresentative'])} label={translate(translations.company.company)} 
                                        value={company.id ? company : selectableCompanies[0]} values={selectableCompanies} mapToString={x => getContent(x as Company)} 
                                        onSelect={onSet} />
                            </SideIcon>
                        </Grid>
                    }
                    <CompanyForm company={company} product={product} readOnly={readOnly} reviewMode={editing} user={user} onChange={onChange} onProductChange={onProductChange} />
                </SpacingContainer>
            </ContentContainer>
        );
    };

    return renderForm();
};
export default CompanyFormWithSelection;
