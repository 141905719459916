import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { TRANSLATIONS } from '../../../constants';
import ContentContainer from '../../common/ContentContainer';
import MainLink from '../../common/MainLink';
import { useTranslate } from '../../../hooks/common';

const FakeLogin = () => {
    const location = useLocation();
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const renderUser = (email: string) => {
        const returnUrl = queryString.parse(location.search)['returnUrl'];
        const returnUrlQuery = returnUrl ? `&returnUrl=${returnUrl}` : '';

        return <MainLink key={email} label={email} externalLink={`/account/fakelogin?email=${email}${returnUrlQuery}`} />;
    };

    const renderUserList = () => {
        const emails = ['john.doe@john.com', 'admin.almighty@evosoft.com', 'test.elek@siemens.com'];

        return (
            <ContentContainer style={{ marginTop: 30, marginBottom: 50 }}>
                <div style={{ marginBottom: 15, fontSize: 20 }}>
                    {translate(translations.dialog.choseUser)}
                </div>
                {emails.map(x => renderUser(x))}
            </ContentContainer>
        );
    };

    return renderUserList();
};
export default FakeLogin;
