import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api, apiCall } from '../../../services';
import { User } from '../../../models';
import { HeaderTabType } from '../../../enums';
import Loader from '../../common/Loader';
import UserTable from './UserTable';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { TRANSLATIONS } from '../../../constants';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const UserTableManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const controller = new AbortController();
    const dispatch = useDispatch();
    const signal = controller.signal;
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [pagedUserList, setPagedUserList] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(setActiveTabType(HeaderTabType.Users));
        loadUsers();

        return () => {
            controller.abort();
            Api.cancelUserRequests();
        };
    }, []);

    const loadUsers = async () => {
        const startingUrl = window.location.pathname;
        const defaultLimit = 10;

        dispatch(setLoading(true));

        await apiCall(
            pagedUserList
                ? Api.getUsers(signal, pagedUserList.offset, pagedUserList.limit, pagedUserList.orderBy, pagedUserList.orderType)
                : Api.getUsers(signal, 0, defaultLimit, 'UserName', 'ASC'),
            async x => {
                setPagedUserList(x.data);
            },
            async () => {
                if (!signal.aborted) {
                    dispatch(addSnackbar({ text: translate(translations.error.usersLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            }
        );

        if (startingUrl === window.location.pathname) {
            dispatch(setLoading(false));
        }
    };

    const handleGetUsers = async (offset: number, limit: number, orderBy: string, orderType: string) => {
        await apiCall(
            Api.getUsers(signal, offset, limit !== 0 ? limit : pagedUserList.limit, orderBy, orderType),
            async x => {
                setPagedUserList(x.data);
            },
            async () => {
                if (!signal.aborted) {
                    dispatch(addSnackbar({ text: translate(translations.error.usersLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            }
        );
    };

    const handleUserChange = async (user: User) => {
        dispatch(setLoading(true));

        await apiCall(
            Api.updateUser(user),
            async () => {
                dispatch(addSnackbar({
                    text: translate(translations.dialog.successfulSave).toString(), type: SnackbarType.Success, dark: dark }));
                dispatch(setLoading(false));
                loadUsers();
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.userSave).toString(), type: SnackbarType.Error, dark: dark }));
                dispatch(setLoading(false));
            }
        );
    };

    const renderTable = () => {
        return (
            <>
                <div className={`compass-section ${dark ? 'dark' : ''}`}>
                    <Loader dark={dark} loading={isLoading}>
                        <UserTable users={pagedUserList} onGetUsers={handleGetUsers} onUserChange={handleUserChange} />
                    </Loader>
                </div>
            </>
        );
    };

    return renderTable();
};
export default UserTableManager;
