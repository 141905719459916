import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api, apiCall } from '../../../services';
import { HeaderTabType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import MachineCollectionTable from './MachineCollectionTable';
import Loader from '../../common/Loader';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const MachineCollectionTableManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const controller = new AbortController();
    const dispatch = useDispatch();
    const signal = controller.signal;
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [machineCollections, setMachineCollections] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.MyMachineCollections));

        loadCollections();

        return () => {
            controller.abort();
            Api.cancelMachineCollectionRequests();
        };
    }, []);

    const loadCollections = async () => {
        const startingUrl = window.location.pathname;

        dispatch(setLoading(true));

        await apiCall(
            Api.getMachineCollectionsForUser(signal),
            async x => {
                setMachineCollections(x.data);
            },
            async () => {
                if (!signal.aborted) {
                    dispatch(addSnackbar({ text: translate(translations.error.collectionsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            }
        );

        if (startingUrl === window.location.pathname) {
            dispatch(setLoading(false));
        }
    };

    const handleCollectionRelease = async (id: string) => {
        dispatch(setLoading(true));

        apiCall(
            Api.releaseMachineCollection(id),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulRelease).toString(), type: SnackbarType.Success, dark: dark }));
                dispatch(setLoading(false));
                await loadCollections();
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.collectionRelease).toString(), type: SnackbarType.Error, dark: dark }));
                dispatch(setLoading(false));
            }
        );
    };

    const handleCollectionDelete = async (id: string) => {
        dispatch(setLoading(true));

        apiCall(
            Api.deleteMachineCollection(id),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulDelete).toString(), type: SnackbarType.Success, dark: dark }));
                dispatch(setLoading(false));
                await loadCollections();
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.collectionDelete).toString(), type: SnackbarType.Error, dark: dark }));
                dispatch(setLoading(false));
            }
        );
    };

    const renderTable = () => {
        return (
            <>
                <div className={`compass-section ${dark ? 'dark' : ''}`}>
                    <Loader dark={dark} loading={isLoading}>
                        <MachineCollectionTable machineCollections={machineCollections} onCollectionRelease={handleCollectionRelease}
                            onCollectionDelete={handleCollectionDelete} />
                    </Loader>
                </div>
            </>
        );
    };

    return renderTable();
};
export default MachineCollectionTableManager;
