import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactText } from 'react';
import { Banner } from '../components/common/Banners';

export interface BannerState {
    banners: Banner[];
}

const initialState: BannerState = {
    banners: []
};

export const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        addBanner: (state: BannerState, action: PayloadAction<Omit<Banner, 'id'>>) => {
            state.banners = [...state.banners, { ...action.payload, id: new Date().getTime() }];
        },
        removeBanner: (state: BannerState, action: PayloadAction<ReactText>) => {
            state.banners = state.banners.filter(x => x.id !== action.payload);
        }
    }
});

export const { addBanner, removeBanner } = bannerSlice.actions;
export const bannerReducer = bannerSlice.reducer;
