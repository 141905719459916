import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api } from '../../../services';
import { HeaderTabType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import { SnackbarType } from '../../common/Snackbar';
import Title from '../../common/Title';
import { addSnackbar, setActiveTabType } from '../../../store';

interface ReportData {
    buffer: ArrayBuffer,
    date: string
}

const ExportManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const dispatch = useDispatch();
    const [lastStoredResult, setLastStoredResult] = useState({} as ReportData);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isReportStored, setIsReportStored] = useState(false);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    let db: IDBDatabase;
    let request: IDBOpenDBRequest;
    let transaction: IDBTransaction;


    useEffect(() => {
        dispatch(setActiveTabType(HeaderTabType.Export));

        //TODO: Refactor indexedDB handling out into it's own service.
        request = window.indexedDB.open('exportFile', 1);

        request.onupgradeneeded = () => {
            db = request.result;
            db.createObjectStore('file');
        };

        request.onsuccess = async () => {
            db = request.result;
            transaction = db.transaction('file', 'readonly');

            const dataRequest = transaction.objectStore('file').get(1);
            
            dataRequest.onsuccess = () => {
                if (dataRequest.result) {
                    setLastStoredResult(dataRequest.result as ReportData);
                    setIsReportStored(true);
                }
            };
        };
    }, []);
    
    const handleDownload = () => {
        request = window.indexedDB.open('exportFile', 1);

        request.onupgradeneeded = () => {
            db = request.result;
            db.createObjectStore('file');
        };

        request.onerror = () => {
            dispatch(addSnackbar({ text: translate(translations.common.excelError), type: SnackbarType.Error }));
        };

        request.onsuccess = async () => {
            db = request.result;
            transaction = db.transaction('file', 'readonly');
            const blobRequest = transaction.objectStore('file').get(1);
            
            blobRequest.onsuccess = () => {
                const buffer: ArrayBuffer = blobRequest.result.buffer;
                const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });

                if (blob) {
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        'monthlyProducts.xls'
                    );

                    document.body.appendChild(link);

                    link.click();

                    link.parentNode.removeChild(link);
                }
            };

        };
    };

    const handleExportRequest = async () => {
        setIsGenerating(true);
        request = window.indexedDB.open('exportFile', 1);
        
        request.onupgradeneeded = () => {
            db = request.result;
            db.createObjectStore('file');
        };

        request.onerror = () => {
            dispatch(addSnackbar({ text: translate(translations.common.excelError), type: SnackbarType.Error }));
        };
        
        request.onsuccess = () => {
            db = request.result;
            transaction = db.transaction('file', 'readwrite');
            transaction.objectStore('file').delete(1);
            
            transaction.onerror = () => {
                dispatch(addSnackbar({ text: translate(translations.common.excelError), type: SnackbarType.Error }));
            };

            Api.getExportProducts().then(x => {
                setIsGenerating(false);
                x.blob().then(y => {
                    y.arrayBuffer().then(z => {
                        const entry: ReportData = { buffer: z, date: new Date(Date.now()).toLocaleString('en-GB') };
                        
                        transaction = db.transaction('file', 'readwrite');
                        transaction.objectStore('file').add(entry, 1);
                        setLastStoredResult(entry);
                        setIsReportStored(true);
                    });
                });
            });
        };
    };

    const renderExportUI = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                <ContentContainer style={{ marginTop: '30px' }}>
                    <Title style={{ fontSize: '18px', marginBottom: '10px' }} text={translate(translations.common.excelExport)} bold />
                    <Title style={{ fontSize: '16px', marginBottom: '10px' }} 
                           text={`${translate(translations.common.excelLastGeneration)} ${lastStoredResult && lastStoredResult.date ? lastStoredResult.date : '---'}`} />
                    <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexStart} gap={10}>
                        <Button disabled={isGenerating} dark={dark} type={ButtonType.CallToAction} onClick={handleExportRequest}>
                            {translate(translations.action.generateReport)}
                        </Button>
                        <Button disabled={!(isReportStored && !isGenerating)} dark={dark} type={ButtonType.Primary} onClick={handleDownload}>
                            {translate(translations.action.download)}
                        </Button>
                    </Flex>
                </ContentContainer>
            </div>
        );
    };

    return renderExportUI();
};
export default ExportManager;
