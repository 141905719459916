import { CookieType } from '../enums/CookieType';

export class CookieService {

    static isCookieAllowed(type: CookieType) {
        const cookieMonster = CookieService.getCookieMonster();

        if (cookieMonster !== undefined && typeof cookieMonster === 'object') {
            switch (type) {
                case CookieType.Necessary:
                    return cookieMonster.permitted('reqd');
                case CookieType.Functional:
                    return cookieMonster.permitted('fnct');
                case CookieType.Performance:
                    return cookieMonster.permitted('perf');
                case CookieType.Marketing:
                    return cookieMonster.permitted('targ');
                default:
                    return false;
            }
        }
    }
    
    static hasInteracted() {
        const cookieMonster = CookieService.getCookieMonster();
        const hasInteracted = cookieMonster ? cookieMonster.hasInteracted() : false;
        
        return hasInteracted;
    }

    static getCookieMonster() {
        // eslint-disable-next-line no-extra-parens
        return (window as any).cookieMonster;
    }
}
