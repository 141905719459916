import React, { Component, ReactNode } from 'react';

export enum HighlightType {
    Blue,
    Green,
    Red
}

interface HighlightProps {
    children: ReactNode;
    type: HighlightType;
}

export default class Highlight extends Component<HighlightProps> {
    private getClass() {
        const { type } = this.props;

        switch (type) {
            case HighlightType.Blue:
                return 'blue';
            case HighlightType.Green:
                return 'green';
            case HighlightType.Red:
                return 'red';
            default:
                return '';
        }
    }

    private renderHighlight() {
        // eslint-disable-next-line react/prop-types
        const { children } = this.props;

        return (
            <div className={`highlight ${this.getClass()}`}>
                {children}
            </div>
        );
    }

    render() {
        return this.renderHighlight();
    }
}
