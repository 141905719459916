import React, { useState } from 'react';
import { CirclePicker } from '@hello-pangea/color-picker';
import { Tag } from '../../../models';
import { COLORS, EMPTY_GUID, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Button, { ButtonType } from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import ContentContainer from '../../common/ContentContainer';
import FairTag from '../../common/FairTag';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Icon, { IconType } from '../../common/Icon';
import Link from '../../common/Link';
import Modal from '../../common/Modal';
import Section, { SectionColor } from '../../common/Section';
import SpacingContainer from '../../common/SpacingContainer';
import Title from '../../common/Title';
import TextInput from '../../common/TextInput';
import Table from '../../common/Table';
import Tooltip from '../../common/Tooltip';

interface TagTableProps {
    tags: Tag[]
    onTagSave: (tag: Tag) => Promise<void>;
    onTagDelete: (id: string) => void;
}

const TagTable = ({ tags, onTagDelete, onTagSave }: TagTableProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [tagName, setTagName] = useState(null);
    const [tagColor, setTagColor] = useState(null);
    const [tagId, setTagId] = useState(null);
    const [outdated, setOutdated] = useState(false);
    const [shouldDisplayDeleteDialog, setShouldDisplayDeleteDialog] = useState(false);
    const [shouldDisplayTagModal, setShouldDisplayTagModal] = useState(false);

    const openTagModalToCreate = async () => {
        setTagId(EMPTY_GUID);
        setTagName('');
        setTagColor(COLORS[0]);
        setOutdated(false);
        setShouldDisplayTagModal(true);
    };

    const openTagModalToEdit = async (tag: Tag) => {
        setTagId(tag.id);
        setTagName(tag.name);
        setTagColor(tag.color);
        setOutdated(tag.outdated);
        setShouldDisplayTagModal(true);
    };

    const closeTagModal = async () => {
        await onTagSave({ id: tagId, name: tagName, color: tagColor, outdated });

        setTagId(null);
        setTagName('');
        setTagColor('');
        setOutdated(false);
        setShouldDisplayTagModal(false);
    };

    const cancelTagModal = async () => {
        setTagId(null);
        setTagName('');
        setTagColor('');
        setOutdated(false);
        setShouldDisplayTagModal(false); };

    const openDeleteDialog = async (tag: Tag) => {
        setTagId(tag.id);
        setShouldDisplayDeleteDialog(true);
    };

    const closeDeleteDialog = () => {
        setTagId(null);
        setShouldDisplayDeleteDialog(false);
    };

    const confirmDeleteDialog = async () => {
        setShouldDisplayDeleteDialog(false);
        onTagDelete(tagId);
        setTagId(null);
    };

    const renderDeleteDialog = () => {
        return (
            <Modal dark={dark} open={shouldDisplayDeleteDialog}>
                <Title style={{ marginBottom: '10px' }} text={translate(translations.dialog.confirmDelete)} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    <Link text={translate(translations.action.yes)} onClick={confirmDeleteDialog} />
                    <Link text={translate(translations.action.no)} onClick={closeDeleteDialog} />
                </Flex>
            </Modal>
        );
    };

    const renderTagModal = () => {
        const isValid = tagName && tagColor;

        return (
               <Modal dark={dark} open={shouldDisplayTagModal}>
                    <SpacingContainer>
                        <Title style={{ marginBottom: 30 }} text={translate(translations.action.createFair)} uppercase bold />
                        <TextInput dark={dark} value={tagName} error={!tagName} name='name' label={translate(translations.common.name)} onChange={x => setTagName(x)} required />
                        <Flex direction={FlexDirection.Row} justification={FlexJustification.SpaceBetween}>
                            <CirclePicker color={tagColor} colors={COLORS} onChange={x => setTagColor(x.hex)} />
                            <Checkbox checked={outdated} label={translate(translations.common.outdated)} onChange={setOutdated} />
                        </Flex>
                        <FairTag id={tagName} text={tagName ? tagName : translate(translations.common.newFair)} color={tagColor} noClose />
                        <Flex direction={FlexDirection.Row} style={{ marginTop: 20 }} justification={FlexJustification.FlexEnd} gap={5}>
                            <Button dark={dark} type={ButtonType.Secondary} onClick={cancelTagModal}>
                                <Icon type={IconType.Close} />
                                {translate(translations.action.cancel)}
                            </Button>
                            <Button dark={dark} type={ButtonType.Primary} disabled={!isValid} onClick={closeTagModal}>
                                <Icon type={isValid ? IconType.Check : IconType.Warning} />
                                {translate(translations.action.create)}
                            </Button>
                        </Flex>
                    </SpacingContainer>
                </Modal>
            );
    };

    const renderTag = (tag: Tag) => {
        return (
            <FairTag id={tag.id} text={tag.name} color={tag.color} noClose />
        );
    };

    const renderRowActions = (tag: Tag) => {
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={5}>
                <Tooltip id={'tagdelete-button'} text={translate(translations.action.delete)}>
                    <Button dark={dark} type={ButtonType.Secondary} onClick={() => openDeleteDialog(tag)}>
                        <Icon type={IconType.Trash} />
                    </Button>
                </Tooltip>
                <Tooltip id={'tagedit-button'} text={translate(translations.action.edit)}>
                    <Button dark={dark} type={ButtonType.Primary} onClick={() => openTagModalToEdit(tag)}>
                        <Icon type={IconType.Edit} />
                    </Button>
                </Tooltip>
            </Flex>
        );
    };

    const renderButton = () => {
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexStart}>
                <Button dark={dark} type={ButtonType.Primary} onClick={openTagModalToCreate}>
                    <Icon type={IconType.Plus} />
                    {translate(translations.action.createFair)}
                </Button>
            </Flex>
        );
    };

    const renderTable = () => {
        return <Table
            dark={dark}
            columns={[
                { label: translate(translations.common.fair), min: '150px', max: '2fr', bold: true },
                { label: '', min: '200px', max: '1fr' }
            ]}
            rows={[
                ...tags.map(x => ({
                    key: x.id,
                    cells: [{ content: renderTag(x) }, { content: renderRowActions(x) }]
                }))
            ]}
        />;
    };

    const renderPage = () => {
        return (
            <>
                <ContentContainer style={{ marginTop: 30 }}>
                    {renderButton()}
                </ContentContainer>
                <ContentContainer>
                    <Section color={dark ? SectionColor.DeepBlue : SectionColor.White} style={{ paddingBottom: 50 }}>
                        {renderTable()}
                    </Section>
                </ContentContainer>
                {renderDeleteDialog()}
                {renderTagModal()}
            </>
        );
    };

    return renderPage();
};
export default TagTable;
