import React, { RefObject, useState } from 'react';
import { CategoryService, ContentService, DateService, isEmail } from '../../../services';
import { Categories, Company, Content, ProductCreation, ReleasedMachineCollection, Tag, User } from '../../../models';
import { CommentType, MachineCollectionType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import CompanyForm from '../company/CompanyForm';
import ProductCategoryForm from '../product/ProductCategoryForm';
import ProductImageForm from '../product/ProductImageForm';
import ProductReleaseBasicForm from '../product/ProductReleaseBasicForm';
import ReviewForm from './ReviewForm';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Float from '../../common/Float';
import Icon, { IconType } from '../../common/Icon';
import Title from '../../common/Title';
import Wizard from '../../common/Wizard';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { WizardStep } from '../../../enums/WizardStep';

interface ReviewWizardProps {
    accordionHeaderRefs: RefObject<HTMLDivElement>[];
    accordionsOpen: boolean[];
    approved: boolean;
    categories: Categories;
    image: File;
    imageUrl: string;
    machineCollection: ReleasedMachineCollection;
    message: string;
    product: ProductCreation;
    tags: Tag[];
    user: User;
    onAccordionToggle: (index: number) => void;
    onCompanyChange: (company: Partial<Company>) => void;
    onFileReceived: (file: File) => void;
    onMessageChange: (message: string) => void;
    onProductChange: (product: Partial<ProductCreation>) => Promise<void>;
    onStatusChange: (approve: boolean) => void;
    onWizardSubmit: () => Promise<void>;
}

const ReviewWizard = ({ accordionHeaderRefs, accordionsOpen, approved, categories, image, imageUrl, machineCollection, message, product, tags, user,
    onAccordionToggle, onCompanyChange, onFileReceived, onMessageChange, onProductChange, onStatusChange, onWizardSubmit }: ReviewWizardProps) => {
    const MAX_LENGTH = 1000;
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [delegated, setDelegated] = useState(false);
    const [wizardStep, setWizardStep] = useState(0);

    const handleSubmit = async () => {
        if (wizardStep < WizardStep.Review) {
           setWizardStep(wizardStep + 1);
        } else {
            await onWizardSubmit();
        }
    };

    const handleStepClick = (step: number, currentStep: number) => {
        if (step < currentStep) {
            setWizardStep(step);
        }
    };

    const toggleDelegation = () => {
        setDelegated(!delegated);
    };

    const isValid = () => {
        switch (wizardStep) {
            case WizardStep.Company:
                return product && product.company && product.company.name && product.company.street1 && product.company.zip && product.company.city && product.company.country
                    && (!product.company.email || isEmail(product.company.email));
            case WizardStep.Image:
                return Boolean((product && product.imageId) || image);
            case WizardStep.Category:
                return product && categories && categories.groups.every(x => CategoryService.isGroupValid(x, machineCollection, product));
            case WizardStep.BasicInformation:
                return product && machineCollection && product.title && isDescriptionValid()
                    && (machineCollection.type === MachineCollectionType.MachineCompass || (
                        product.hallId !== undefined && product.hallId !== null && product.booth
                        && product.customReleaseDate >= DateService.getDayBeginning(machineCollection.fairPreStartDate)
                        && product.customReleaseDate < DateService.getNextDayBeginning(machineCollection.fairStartDate)
                    ));
            case WizardStep.Review:
                return approved !== null && Boolean(approved || ContentService.isValid(message));
            default:
                return true;
        }
    };

    const isDescriptionValid = () => {
        const languagesToShow = ContentService.addEnglish(machineCollection.availableLanguages);

        return delegated
            ? languagesToShow.every(x => ContentService.getLength(product.description[x as keyof Content].toString()) === 0 
                || ContentService.isValid(product.description[x as keyof Content].toString()), MAX_LENGTH)
                && languagesToShow.some(x => ContentService.isValid(product.description[x as keyof Content].toString(), MAX_LENGTH))
            : languagesToShow.every(x => ContentService.isValid(product.description[x as keyof Content].toString()), MAX_LENGTH);
    };

    const renderWizard = () => {
        const stepNames: string[] = [translate(translations.company.company), translate(translations.common.image), translate(translations.category.category), 
            translate(translations.common.info), translate(translations.common.review)];

        return <Wizard dark={dark} step={wizardStep} stepNames={stepNames} onStepClick={x => handleStepClick(x, wizardStep)} />;
    };

    const renderForm = () => {
        const forms: JSX.Element[] = [
            <ContentContainer>
                <CompanyForm company={product ? product.company : null} product={product} reviewMode user={user} onChange={onCompanyChange} />
            </ContentContainer>,
            <ContentContainer>
                <ProductImageForm image={image} imageUrl={imageUrl} product={product} onFileReceived={onFileReceived} />
            </ContentContainer>,
            <ContentContainer>
                <ProductCategoryForm accordionHeaderRefs={accordionHeaderRefs} accordionsOpen={accordionsOpen} categories={categories} machineCollection={machineCollection} 
                                     product={product}
                    onAccordionToggle={onAccordionToggle} onChange={onProductChange} />,
            </ContentContainer>,
            <ContentContainer>
                <ProductReleaseBasicForm imageUrl={imageUrl} delegated={delegated} machineCollection={machineCollection} product={product} tags={tags} 
                                         onDelegationToggled={toggleDelegation}
                    onProductChange={onProductChange} />
            </ContentContainer>,
            <ContentContainer>
                <ReviewForm approved={approved} message={message} onMessageChange={onMessageChange} onStatusChange={onStatusChange} />
            </ContentContainer>
        ];

        return forms[wizardStep];
    };

    const renderButton = () => {
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                <Float dark={dark} key={wizardStep} bottom={50}>
                    <Button dark={dark} type={ButtonType.Primary} disabled={!isValid()} onClick={handleSubmit}>
                        <Icon type={isValid() ? IconType.Check : IconType.Warning} />
                        {translate(wizardStep < WizardStep.Review ? translations.action.nextStep : translations.action.save)}
                    </Button>
                </Float>
            </Flex>
        );
    };

    const renderLabels = () => {        
        const machineName = product && product.title ? product.title : translate(translations.product.newMachine);
        const companyName = product && product.company && product.company.name ? product.company.name : translate(translations.company.noCompany);
        const releaseComment = product && product.comments && product.comments.find(x => x.type === CommentType.ReleaseComment)
            && product.comments.find(x => x.type === CommentType.ReleaseComment).comment.length
            ? product.comments.find(x => x.type === CommentType.ReleaseComment).comment
            : translate(translations.common.noComment);

        return (
            <div style={{ marginBottom: 30 }}>
                <Title style={{ fontSize: 24 }} text={machineName} bold />
                <Title style={{ fontSize: 20 }} text={companyName} />
                {user && <a href={`mailto:${user.email}`}>{user.email}</a>}
                {` (${translate(translations.common.comment)}: ${releaseComment})`}
            </div>
        );
    };

    const renderPage = () => {
        return (
            <>
                <ContentContainer style={{ marginTop: 30, marginBottom: 30 }}>
                    {renderLabels()}
                    {renderWizard()}
                </ContentContainer>
                {renderForm()}
                <ContentContainer style={{ marginTop: 30, marginBottom: 30 }}>
                    {renderButton()}
                </ContentContainer>
            </>
        );
    };

    return renderPage();
};
export default ReviewWizard;
