import { Hall } from '../models';

export const EMPTY_HALL: Hall = {
    id: '',
    name: '',
    imageId: '',
    imageUrl: '',
    thumbnailImageUrl: '',
    image: null
};
