import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Api, apiCall } from '../../../services';
import { HeaderTabType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import ProductView from './ProductView';
import Loader from '../../common/Loader';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const ProductPreviewManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const id = useMatch('/product/:id').params.id;
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [product, setProduct] = useState(null);

    useEffect(() => {
        dispatch(setActiveTabType(HeaderTabType.MyProducts));

        window.scrollTo(0, 0);

        (async () => {
            const startingUrl = window.location.pathname;

            dispatch(setLoading(true));

            await apiCall(
                Api.getProduct(id),
                async x => {
                    setProduct(x.data);
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.productLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );

            if (startingUrl === window.location.pathname) {
                dispatch(setLoading(false));
            }
        })();
    }, []);

    const renderView = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                <Loader dark={dark} loading={isLoading}>
                    <ProductView product={product} />
                </Loader>
            </div>
        );
    };

    return renderView();
};
export default ProductPreviewManager;
