import React, { ReactNode } from 'react';
import { AuthService } from './ts/services';

interface RequireAuthProps {
    children: ReactNode;
}

const RequireAuth = ({ children }: RequireAuthProps): JSX.Element => {
    const authorized = AuthService.isAuthenticated();

    return authorized ? <>{children}</> : <>{window.open('/account/login', '_self')}</>;
};

export default RequireAuth;
