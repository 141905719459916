import React, { createRef, RefObject, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import queryString from 'query-string';
import { Api, apiCall, AuthService, CategoryService, getFormData, ProductService } from '../../../services';
import { Company, ProductCreation } from '../../../models';
import { HeaderTabType } from '../../../enums';
import { EMPTY_COMPANY, EMPTY_GUID, EMPTY_PRODUCT, PRODUCT_FOLDER, ROLES, TRANSLATIONS } from '../../../constants';
import Loader from '../../common/Loader';
import ProductWizard from './ProductWizard';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const ProductWizardManager = () => {
    const { id } = useMatch('/productwizard/:id').params;
    const { search } = useLocation();
    const dark = useAppSelector(state => state.layout.dark);
    const dispatch = useDispatch();
    const isLoading = useAppSelector(state => state.app.isLoading);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [accordionHeaderRefs, setAccordionHeaderRefs] = useState(null);
    const [accordionsOpen, setAccordionsOpen] = useState(null);
    const [categories, setCategories] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [machineCollections, setMachineCollections] = useState([]);
    const [product, setProduct] = useState(null);
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(setActiveTabType(HeaderTabType.MyProducts));

        (async () => {
            const startingUrl = window.location.pathname;

            if (!isLoading) {
                dispatch(setLoading(true));
            }

            setReadOnly(queryString.parse(search)['readonly'] === 'true');
            const isVisibleForSiemensRepresentative = queryString.parse(search)['incompleteVisible'] === 'true';

            const categoryPromise = apiCall(
                Api.getCategories(),
                async x => {
                    const length = x.data.groups.length;

                    setAccordionHeaderRefs(Array<RefObject<HTMLDivElement>>(length).fill(null).map(_ => createRef()));
                    setAccordionsOpen(Array<boolean>(length).fill(true));
                    setCategories(x.data);
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.categoryLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );

            const companiesPromise = !id && AuthService.hasRole([ROLES.SIEMENS_REPRESENTATIVE])
                ? apiCall(
                    Api.getAssignedCompanies(),
                    async x => {
                        setCompanies(x.data);
                    },
                    async () => {
                        dispatch(addSnackbar({
                            text: translate(translations.error.companiesLoad).toString(), type: SnackbarType.Error, dark: dark
                        }));
                    }
                )
                : (async () => { })();

            const productPromise = id
                ? apiCall(
                    Api.getProduct(id),
                    async x => {
                        await categoryPromise;

                        const product = x.data;

                        setProduct({
                            ...ProductService.map(product),
                            categoryGroups: [...product.categoryGroups, ...CategoryService.getProductCategoryGroups(categories)
                                .filter(x => product.categoryGroups.every(y => y.groupId !== x.groupId))]
                        });
                        setImageUrl(product.imageUrl);
                    },
                    async () => {
                        setProduct({ ...EMPTY_PRODUCT, isVisibleForSiemensRepresentative });
                        dispatch(addSnackbar({ text: translate(translations.error.productLoad).toString(), type: SnackbarType.Error, dark: dark }));
                    }
                )
                : !AuthService.hasRole([ROLES.SIEMENS_REPRESENTATIVE])
                    ? apiCall(
                        Api.getCompanyForUser(),
                        async x => {
                            await categoryPromise;

                            const company = x.data;

                            setProduct({
                                    ...EMPTY_PRODUCT,
                                    companyId: company.id,
                                    company,
                                    isVisibleForSiemensRepresentative,
                                    categoryGroups: CategoryService.getProductCategoryGroups(categories)
                                }
                            );
                        },
                        async () => {
                            setProduct({ ...EMPTY_PRODUCT, isVisibleForSiemensRepresentative });
                            dispatch(addSnackbar({ text: translate(translations.error.companyLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        }
                    )
                    : (async () => {
                        await categoryPromise;

                        setProduct({
                                ...EMPTY_PRODUCT,
                                companyId: EMPTY_COMPANY.id,
                                company: ProductService.addRepresentativeToCompany(EMPTY_COMPANY, AuthService.getUserName()),
                                isVisibleForSiemensRepresentative,
                                categoryGroups: CategoryService.getProductCategoryGroups(categories)
                            }
                        );
                    })();

            await categoryPromise;
            await companiesPromise;
            await productPromise;

            if (startingUrl === window.location.pathname && isLoading) {
                dispatch(setLoading(false));
            }
        })();
    }, []);

    const handleAccordionToggle = (index: number) => setAccordionsOpen(accordionsOpen.map((x: boolean, i: number) => i === index ? !x : x));
    
    const handleCompanyChange = (company: Partial<Company>) => {
        setProduct({ ...product, company: { ...product.company, ...company } });
    };

    const handleCompanySet = async (company: Company) => {
        setProduct({ ...product, companyId: company.id, company });
    };

    const handleFileReceived = (file: File) => {
        if (!image || image.name !== file.name) {
            setImage(file);

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = x => setImageUrl(x.target['result'] as string);
        }
    };

    const handleMachineCollectionsLoad = async () => {
        dispatch(setLoading(true));

        await apiCall(
            Api.getReleasedMachineCollections(),
            async x => {
                setMachineCollections(x.data);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.releasedCollectionsLoad).toString(), type: SnackbarType.Error, dark: dark }));
            }
        );

        dispatch(setLoading(false));
    };

    const handleProductChange = (newProduct: Partial<ProductCreation>) => {
        setProduct({ ...product, ...newProduct });
    };

    const handleProductSave = async (isIncomplete = false) => {
        dispatch(setLoading(true));

        let productId: string = null;

        let imageError = false;
        let imageId = product.imageId;

        if (image) {
            await apiCall(
                Api.saveImage(PRODUCT_FOLDER, getFormData(image)),
                async x => {
                    imageId = x.data;
                },
                async () => {
                    imageError = true;
                    dispatch(addSnackbar({ text: translate(translations.error.imageSave).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );
        }

        if (!imageError) {
            await apiCall(
                product.id === EMPTY_GUID 
                    ? Api.saveProduct({ ...product, imageId }, isIncomplete) 
                    : Api.updateProduct({ ...product, imageId }, isIncomplete),
                async x => {
                    productId = x.data.id;
                    dispatch(addSnackbar({ text: translate(translations.dialog.successfulSave).toString(), type: SnackbarType.Success, dark: dark }));
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.productSave).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );
        }

        dispatch(setLoading(false));

        return productId;
    };

    const renderWizard = () => {        
        return (
            <div className={'compass-section'}>
                <Loader loading={isLoading} />
                <ProductWizard accordionHeaderRefs={accordionHeaderRefs} accordionsOpen={accordionsOpen} categories={categories} companies={companies} image={image} 
                               imageUrl={imageUrl} machineCollections={machineCollections} product={product} readOnly={readOnly} onAccordionToggle={handleAccordionToggle} 
                               onCompanyChange={handleCompanyChange} onCompanySet={handleCompanySet} onFileReceived={handleFileReceived} 
                               onMachineCollectionsLoad={handleMachineCollectionsLoad} onProductChange={handleProductChange} onProductSave={handleProductSave}
                />
            </div>
        );
    };

    return renderWizard();
};
export default ProductWizardManager;
