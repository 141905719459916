import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
import { debounce } from '@material-ui/core';
import { addSnackbar, setFilter, setShownCollectionModal } from '../../../store';
import { AuthService, CategoryService, ContentService, copyToClipboard, deepCopy, FilterService, ProductService, toggleListElement } from '../../../services';
import { Content, Favourite, Filter, MachineCollection, MachineCollectionCategoryGroup, PossibleValues, Product, ProductCategory, Tag } from '../../../models';
import { LandingPageOrderType, MachineCollectionType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import { useAppSelector, useResized, useScrolledPast, useTranslate } from '../../../hooks/common';
import ChipsLaneManager from './ChipsLaneManager';
import Result from './Result';
import Accordion from '../../common/Accordion';
import Button, { ButtonType } from '../../common/Button';
import ButtonWithPopup from '../../common/ButtonWithPopup';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification, FlexWrap } from '../../common/Flex';
import Grid from '../../common/Grid';
import Icon, { IconType } from '../../common/Icon';
import Jumbotron from '../../common/Jumbotron';
import KeyVisual from '../../common/KeyVisual';
import Link from '../../common/Link';
import Modal from '../../common/Modal';
import Multiselect from '../../common/Multiselect';
import Search from '../../common/Search';
import SideIcon from '../../common/SideIcon';
import SideSheet from '../../common/SideSheet';
import SideSheetInputContainer from '../../common/SideSheetInputContainer';
import { SnackbarType } from '../../common/Snackbar';
import Sticky from '../../common/Sticky';
import Title from '../../common/Title';
import Tooltip from '../../common/Tooltip';

interface MachineCollectionViewProps {
    machineCollection: MachineCollection;
    onRemoveFavourite: (id: string) => Promise<void>;
    onSaveFavourite: (id: string) => Promise<void>;
    onCopiedToClipboard?: () => Promise<void>;
    onFilterProducts?: (reset: boolean, tempFilter?: Filter) => Promise<void>
    onGetNextProducts?: (offset: number) => Promise<void>
    onGetShareLink?: () => string;
    onSortProducts?: (sortBy: LandingPageOrderType) => void;
}

const MachineCollectionView = ({ machineCollection, onRemoveFavourite, onSaveFavourite, onCopiedToClipboard, onFilterProducts, onGetNextProducts, onGetShareLink, onSortProducts }: MachineCollectionViewProps) => {
    const SIDE_SHEET_TRESHOLD = 1400;
    const SCROLL_TRESHOLD = 400;
    const ACCORDIONS = 3;
    const activeLanguage = useAppSelector(state => state.translation.language);
    const availableFilters = useAppSelector(state => state.app.availableFilters);
    const dark = useAppSelector(state => state.layout.dark);
    const dropdownProducts = useAppSelector(state => state.app.dropdownProducts);
    const filter = useAppSelector(state => state.app.filter);
    const shownCollectionModal = useAppSelector(state => state.layout.shownCollectionModal);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scrolled = useScrolledPast(SCROLL_TRESHOLD);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const mobileView = useResized(x => x <= SIDE_SHEET_TRESHOLD);
    const [accordionsOpen, setAccordionsOpen] = useState(Array<boolean>(ACCORDIONS).fill(true));
    const [allowSideSheet, setAllowSideSheet] = useState(false);
    const [filteredProducts, setFilteredProducts] = useState(dropdownProducts);
    const setDebouncedProducts = debounce(setFilteredProducts, 500);
    const [productSearch, setProductSearch] = useState('');
    const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
    const [sideSheetOpen, setSideSheetOpen] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [sortBy, setSortBy] = useState(window.localStorage.getItem('sortByType') 
        ? window.localStorage.getItem('sortByType') 
        : LandingPageOrderType.DateDescending);

    useEffect(() => {
        handleResize();
    }, [mobileView, showAll]);

    const closeMachineCollectionModal = async () => {
        dispatch(setShownCollectionModal(false));
    };
    
    const handleCopyToClipboard = async () => {
        copyToClipboard(onGetShareLink());
        await onCopiedToClipboard();
    };

    const handleFavouriteToggle = async (product: Product) => {
        const updatedFilter: Filter = { ...filter, ...{ favourites: filter.favourites.some(x => x.id === product.id) 
                    ? filter.favourites.filter(x => x.id !== product.id) 
                    : [...filter.favourites, { id: product.id, title: product.title, companyName: product.company.name }] } };

        if (AuthService.isAuthenticated()) {
            if (filter.favourites.map(x => x.id).includes(product.id)) {
                await onRemoveFavourite(product.id);
            } else {
                await onSaveFavourite(product.id);
            }
        } else {
            window.localStorage.setItem('storedFavourites', JSON.stringify(updatedFilter.favourites));
        }

        dispatch(setFilter(updatedFilter));
    };
    
    const handleFilteringProducts = (searchTerm: string) => {
        setProductSearch(searchTerm);

        setDebouncedProducts(dropdownProducts.filter(x => x.title.toLowerCase().includes(searchTerm.toLowerCase())));
    };
    
    const handlePrint = () => {
        dispatch(addSnackbar({ text: 'Only the currently loaded machines are printable', type: SnackbarType.Info }));
        setTimeout(() => {
            window.print();

        }, 2000);
    };

    const handleResize = () => {
        const shouldAllowSideSheet = isSideSheetAllowed() || showAll;

        if (allowSideSheet !== shouldAllowSideSheet) {
            setAllowSideSheet(shouldAllowSideSheet);
            setSideSheetOpen(false);
        }
    };

    const handleShowAllClick = () => {
        if (allowSideSheet) {
            setSideSheetOpen(true);
        }
    };

    const closeShareModal = async () => setShouldShowShareModal(false);

    const setSideSheet = (open: boolean) => setSideSheetOpen(open);

    const toggleAccordion = (index: number) => setAccordionsOpen([...accordionsOpen.map((x, i) => i === index ? !x : x)]);

    const handleReset = () => {
        const newFilter = deepCopy(filter);

        Object.keys(filter).filter(y => y !== 'favourites').forEach(y => y === 'shouldFilter' ? newFilter[y] = true : newFilter[y as keyof Filter] = [] as ProductCategory[] & string[] & Tag[] & Favourite[] & boolean);

        dispatch(setFilter({ ...filter, ...newFilter }));
        
        onFilterProducts(true);
    };

    const handleSort = (sortBy: LandingPageOrderType) => {
        onSortProducts(sortBy);
        setSortBy(sortBy);
        window.localStorage.setItem('sortByType', sortBy.toString());
    };

    const translateContent = (content: Content) => ContentService.translate(content, activeLanguage);

    const isSideSheetAllowed = () => window.innerWidth < SIDE_SHEET_TRESHOLD;

    const getManufacturerText = () => {
        return `${translate(translations.common.manufacturers)}`;
    };

    const getHallText = () => {
        return `${translate(translations.hall.halls)}`;
    };

    const getCategoryText = (group: MachineCollectionCategoryGroup, isPrimary = false) => {
        if (!group) {
            return 'Category';
        }
        
        const secondary = group.primaryCategories.length ? `(${translate(translations.category.secondary)})` : '';

        return `${translateContent(group.content)} ${isPrimary ? `(${translate(translations.category.primary)})` : secondary}`;
    };

    const getCountryText = () => {
        return `${translate(translations.common.countries)}`;
    };

    const getFairText = () => {
        return `${translate(translations.common.fairs)}`;
    };

    const getKeyVisual = () => {
        let keyVisualPrefix = machineCollection && machineCollection.type === MachineCollectionType.MachineCompass ? 'machine-compass' : 'ebooklet';
        keyVisualPrefix = dark ? `${keyVisualPrefix}-dark` : keyVisualPrefix;

        return machineCollection ? `assets/images/${keyVisualPrefix}-key-visual.jpg` : `assets/images/machine-compass${dark ? '-dark' : ''}-key-visual.jpg`;
    };

    const renderProductDropdown = () => {
        /* eslint-disable no-extra-parens */
        return <Search
            dark={dark}
            searchValue={productSearch}
            results={productSearch ? filteredProducts : dropdownProducts}
            onSelect={async x => navigate(ProductService.getLink(machineCollection.id, (x as Product).id))}
            getId={x => x.id}
            mapToString={x => (x as Product).title}
            label={translate(translations.common.searchForMachineName)}
            onChange={value => handleFilteringProducts(value)}
        />;
    };
    
    const renderCompanyDropdown = () => {
        return <Multiselect
            dark={dark}
            values={availableFilters.companyNames.map(x => x.trim()).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))}
            label={getManufacturerText()}
            selected={x => filter.companyNames.some(y => y === x)}
            onSelect={x => dispatch(setFilter({ ...filter, ...{ companyNames: toggleListElement(filter.companyNames, x), shouldFilter: true } }))}
            mapToString={x => x}
            onBlur={() => onFilterProducts(false)}
        />;
    };

    const renderHallDropdown = (possibleValues: PossibleValues) => {
        const hallText = translate(translations.hall.hall);

        return <Multiselect
            dark={dark}
            values={possibleValues.halls}
            onSelect={x => dispatch(setFilter({ ...filter, ...{ hallIds: toggleListElement(filter.hallIds, x.id), shouldFilter: true } }))}
            mapToString={x => `${hallText} ${x.name}`}
            label={getHallText()}
            selected={x => filter.hallIds.some(y => y === x.id)}
            onBlur={() => onFilterProducts(false)}
        />;
    };

    const renderCategoryDropdown = (categoryGroup: MachineCollectionCategoryGroup, possibleValues: PossibleValues, isPrimary = false) => {
        const currentAvailableGroup = categoryGroup 
            ? availableFilters.availableCategories.find(x => x.groupId === categoryGroup.id)
            : null;
        
        return <Multiselect
            dark={dark}
            values={currentAvailableGroup ? currentAvailableGroup.categories.filter(x => isPrimary
                ? categoryGroup.primaryCategories.some(y => y.id === x.id && x.isPrimary)
                : categoryGroup.categories.some(y => y.id === x.id && !x.isPrimary)).sort((x, y) => x.content.en > y.content.en ? 1 : -1 )
            : []}
            label={getCategoryText(categoryGroup, isPrimary)}
            selected={x => filter[categoryGroup.id].some(y => x.id === y.id && y.isPrimary === isPrimary)}
            onSelect={x => dispatch(setFilter({
                ...filter, ...{
                    [categoryGroup.id]: filter[categoryGroup.id].some(z => z.id === x.id && z.isPrimary === x.isPrimary)
                        ? filter[categoryGroup.id].filter(z => z.id !== x.id || z.isPrimary !== x.isPrimary)
                        : filter[categoryGroup.id].concat([x]),
                    shouldFilter: true
                }
            }))}
            mapToString={x => translateContent(x.content)}
            onBlur={() => onFilterProducts(false)}
        />;
    };

    const renderCountryDropdown = () => {
        type countriesType = typeof translations.countries;

        return <Multiselect
            dark={dark}
            values={deepCopy(availableFilters.countries).sort()}
            onSelect={x => dispatch(setFilter({ ...filter, ...{ countries: toggleListElement(filter.countries, x as string), shouldFilter: true } }))}
            mapToString={x => translate(translations.countries[x.toString().toLowerCase() as keyof countriesType])}
            label={getCountryText()}
            selected={x => filter.countries.some(y => y === x)}
            onBlur={() => onFilterProducts(false)}
        />;
    };

    const renderFairDropdown = () => {
        return <Multiselect
            dark={dark}
            values={availableFilters.tags}
            onSelect={async (x: Tag) => dispatch(setFilter(
                { ...filter, ...{ tags: filter.tags.find(y => y.id === x.id) ? filter.tags.filter(y => y.id !== x.id) : toggleListElement(filter.tags, x), shouldFilter: true } }
            ))}
            mapToString={x => x.name}
            label={getFairText()}
            selected={x => filter.tags.some(y => y.id === x.id)}
            onBlur={() => onFilterProducts(false)}
        />;
    };

    const renderFavouriteProducts = () => {
        const favouriteProducts = filter && filter.favourites ? filter.favourites : [] ;

        return favouriteProducts.map(x => <Link key={x.id} dark={dark} text={`${x.title}: ${x.companyName}`} 
            onClick={() => navigate(ProductService.getLink(machineCollection.id, x.id))} />);
    };

    const renderSelection = () => {
        return (
            <KeyVisual height={400} url={getKeyVisual()} >
                <Flex direction={FlexDirection.Column} justification={FlexJustification.Center}>
                    <ContentContainer>
                        <Jumbotron dark={dark}>
                            <div className={'jumbotron-title'}>{translate(translations.common.landingPageTitle)}</div>
                            {renderProductDropdown()}
                        </Jumbotron>
                    </ContentContainer>
                </Flex>
            </KeyVisual>
        );
    };

    const renderSecondaryFilters = (possibleValues: PossibleValues) => {
        return (
            <>
                <Accordion dark={dark} label={translate(translations.common.myFavourites)} icon={IconType.Favorite} open={filter.favourites.length && accordionsOpen[0]} 
                           onClick={() => toggleAccordion(0)}>
                    {renderFavouriteProducts()}
                </Accordion>
                <Accordion dark={dark} label={translate(translations.common.basicFilters)} icon={IconType.Configure} open={accordionsOpen[1]} 
                           onClick={() => toggleAccordion(1)}>
                    <SideIcon iconType={IconType.User}>
                        <SideSheetInputContainer>
                            {renderCompanyDropdown()}
                        </SideSheetInputContainer>
                    </SideIcon>
                    {machineCollection && machineCollection.type === MachineCollectionType.EBooklet &&
                        <SideIcon iconType={IconType.Pin}>
                            <SideSheetInputContainer>
                                {renderHallDropdown(possibleValues)}
                            </SideSheetInputContainer>
                        </SideIcon>
                    }
                    {machineCollection && machineCollection.type === MachineCollectionType.MachineCompass &&
                        <SideIcon iconType={CategoryService.getIconType(0)}>
                            <SideSheetInputContainer>
                                {renderCategoryDropdown(machineCollection.categoryGroups[0], possibleValues)}
                            </SideSheetInputContainer>
                        </SideIcon>
                    }
                    {machineCollection && machineCollection.categoryGroups.filter(x => x.primaryCategories.length > 0).map((x, _i) => x &&
                        <SideIcon iconType={CategoryService.getIconType(machineCollection.categoryGroups.indexOf(x))}>
                            <SideSheetInputContainer>
                                {renderCategoryDropdown(x, possibleValues, true)}
                            </SideSheetInputContainer>
                        </SideIcon>
                    )}
                    {machineCollection && machineCollection.categoryGroups.map((x, i) => (i > 0 || machineCollection.type === MachineCollectionType.EBooklet) &&
                        <SideIcon iconType={CategoryService.getIconType(i)}>
                            <SideSheetInputContainer>
                                {renderCategoryDropdown(x, possibleValues)}
                            </SideSheetInputContainer>
                        </SideIcon>
                    )}
                    <SideIcon iconType={IconType.Pin}>
                        <SideSheetInputContainer>
                            {renderCountryDropdown()}
                        </SideSheetInputContainer>
                    </SideIcon>
                    <SideIcon iconType={IconType.Bookmark}>
                        <SideSheetInputContainer>
                            {renderFairDropdown()}
                        </SideSheetInputContainer>
                    </SideIcon>
                </Accordion>
            </>
        );
    };

    const renderShareModal = () => {
        return (shouldShowShareModal &&
            <Modal dark={dark} open={true}>
                <Title style={{ marginBottom: 10 }} text={translate(translations.action.shareAs)} bold uppercase />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.SpaceEvenly} gap={10} >
                    <FacebookShareButton url={onGetShareLink()}>
                        <FacebookIcon size={48} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={onGetShareLink()}>
                        <TwitterIcon size={48} round />
                    </TwitterShareButton>
                    <ViberShareButton url={onGetShareLink()}>
                        <ViberIcon size={48} round />
                    </ViberShareButton>
                    <WhatsappShareButton url={onGetShareLink()}>
                        <WhatsappIcon size={48} round />
                    </WhatsappShareButton>                    
                    <LinkedinShareButton url={onGetShareLink()}>
                        <LinkedinIcon size={48} round />
                    </LinkedinShareButton>
                    <EmailShareButton url={onGetShareLink()}>
                        <EmailIcon size={48} round />
                    </EmailShareButton>
                </Flex>
                <Flex direction={FlexDirection.Row} style={{ marginTop: 30 }} justification={FlexJustification.FlexEnd} gap={5}>
                    <Button dark={dark} type={ButtonType.Primary} onClick={async () => handleCopyToClipboard()}>
                        <Icon type={IconType.Folder} />
                        {translate(translations.action.copyLink)}
                    </Button>
                    <Button dark={dark} type={ButtonType.Secondary} onClick={closeShareModal}>
                        <Icon type={IconType.Close} />
                        {translate(translations.action.close)}
                    </Button>
                </Flex>
            </Modal>
        );
    };

    const renderSideSheet = (possibleValues: PossibleValues) => {        
        return (
            <SideSheet dark={dark} open={sideSheetOpen} 
                       actions={[{ children: translate(translations.common.resetFilters), key: translate(translations.common.resetFilters), onClick: handleReset }]} 
                       title={translate(translations.common.allFilters)} onClose={() => setSideSheet(false)}>
                {renderSecondaryFilters(possibleValues)}
            </SideSheet>
        );
    };

    const renderChipsLane = () => {
        const defaultOrderType = window.localStorage.getItem('sortByType')
            ? parseInt(window.localStorage.getItem('sortByType'), 10)
            : LandingPageOrderType.DateDescending;
        
        return (
            <Sticky anchor='top' distance={60}>
                <ContentContainer noPrint style={{ width: 'auto', maxWidth: '1450px', margin: 'auto', marginTop: '10px' }}>
                    <Flex style={dark ? {} : { backgroundColor: 'white' }} direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} wrap={FlexWrap.Wrap} gap={5}>
                        <ChipsLaneManager machineCollection={machineCollection} onFilterProducts={onFilterProducts} />
                            <ButtonWithPopup dark={dark} disabled={false} buttonType={ButtonType.Secondary} buttonIconType={IconType.Sort} 
                                             defaultSelectedId={defaultOrderType} dropdownActions={[
                                { id: LandingPageOrderType.DateDescending, name: 'Date', iconType: IconType.SortDescending, onClick: () => handleSort(LandingPageOrderType.DateDescending) },
                                { id: LandingPageOrderType.DateAscending, name: 'Date', iconType: IconType.SortAscending, onClick: () => handleSort(LandingPageOrderType.DateAscending) },
                                { id: LandingPageOrderType.NameDescending, name: 'Machine Name', iconType: IconType.SortDescending, onClick: () => handleSort(LandingPageOrderType.NameDescending) },
                                { id: LandingPageOrderType.NameAscending, name: 'Machine Name', iconType: IconType.SortAscending, onClick: () => handleSort(LandingPageOrderType.NameAscending) },
                                { id: LandingPageOrderType.CompanyNameDescending, name: 'Company Name', iconType: IconType.SortDescending, onClick: () => handleSort(LandingPageOrderType.CompanyNameDescending) },
                                { id: LandingPageOrderType.CompanyNameAscending, name: 'Company Name', iconType: IconType.SortAscending, onClick: () => handleSort(LandingPageOrderType.CompanyNameAscending) }
                            ]} />
                        {showAll &&
                            <Tooltip id={'print-button'} place={scrolled ? 'bottom' : 'top'} text={translate(translations.action.print)}>
                                <Button dark={dark} type={ButtonType.Secondary} onClick={handlePrint}>
                                    <Icon type={IconType.Print} />
                                </Button>
                            </Tooltip>
                        }
                        {onGetShareLink &&
                            <Tooltip id={'share-button'} place={scrolled ? 'bottom' : 'top'} text={translate(translations.action.share)}>
                                <Button dark={dark} type={ButtonType.Secondary} onClick={async () => setShouldShowShareModal(true)}>
                                    <Icon type={IconType.Share} />
                                </Button>
                            </Tooltip>
                        }
                        <Tooltip id={`showall-${showAll}`} place={scrolled ? 'bottom' : 'top'} text={showAll ? translate(translations.action.compact) : translate(translations.action.detail)}>
                            <Button dark={dark} type={ButtonType.Secondary} onClick={async () => {
                                setShowAll(!showAll);
                                setAllowSideSheet(!showAll);
                            }}>
                                <Icon type={showAll ? IconType.VerticalView : IconType.HorizontalView} />
                            </Button>
                        </Tooltip>
                        {isSideSheetAllowed() || showAll ?
                            <Tooltip id={'filter-button'} place={scrolled ? 'bottom' : 'top'} text={translate(translations.common.filter)}>
                                <Button dark={dark} type={ButtonType.Secondary} onClick={handleShowAllClick}>
                                    <Icon type={IconType.Filter} />
                                </Button>
                            </Tooltip>
                            :
                            <Tooltip id={'reset-button'} place={scrolled ? 'bottom' : 'top'} text={translate(translations.common.resetFilters)}>
                                <Button dark={dark} type={ButtonType.Secondary} onClick={handleReset}>
                                    <Icon type={IconType.Reset} />
                                </Button>
                            </Tooltip>
                        }
                    </Flex>
                </ContentContainer>
            </Sticky>
        );
    };

    const renderResult = (possibleValues: PossibleValues) => {
        const products = machineCollection && machineCollection.products ? machineCollection.products : [];
        
        return (
            <ContentContainer style={{ marginTop: 30, marginBottom: 80 }}>
                <Result machineCollection={machineCollection} products={products} productCount={availableFilters.productCount} secondaryFilters={renderSecondaryFilters(possibleValues)}
                    onFavouriteToggle={x => handleFavouriteToggle(x)} onGetNextProducts={onGetNextProducts} showAll={showAll} />
            </ContentContainer>
        );
    };

    const renderMachineCollection = () => {
        return machineCollection && shownCollectionModal && (
            <Modal open={shownCollectionModal} allowClickAway={true} dark={dark} fullWidth={true} onClose={closeMachineCollectionModal}>
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    <Button type={ButtonType.Tertiary} onClick={closeMachineCollectionModal}>
                        <Icon type={IconType.Close} />
                    </Button>
                </Flex>
                <ContentContainer style={{ marginTop: 30, marginBottom: 80, fontSize: 16 }}>
                    <Grid columns={2} columnsMedium={1} columnGap={50}>
                        <img alt={'Sinumerik Machine Compass'} src={machineCollection.imageUrl} style={{ width: '100%' }} />
                        <div style={{ marginTop: 30 }}>
                            {ContentService.translateWithParsing(machineCollection.description1, activeLanguage) as ReactNode}
                        </div>
                    </Grid>
                    <div style={{ marginTop: 30 }}>
                        {ContentService.translateWithParsing(machineCollection.description2, activeLanguage) as ReactNode}
                    </div>
                    <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                        <Button type={ButtonType.Primary} onClick={closeMachineCollectionModal}>
                            <Icon type={IconType.ArrowRight} />
                            {translate(translations.action.goToCompass)}
                        </Button>
                    </Flex>
                </ContentContainer>
            </Modal>
        );
    };

    const renderMachineCollectionView = () => {
        const possibleValues = FilterService.getPossibleValues(filter, machineCollection);
        
        return (
            <>
                {renderSelection()}
                {renderSideSheet(possibleValues)}
                {renderChipsLane()}
                {renderShareModal()}
                {renderResult(possibleValues)}
                {renderMachineCollection()}
            </>
        );
    };

    return renderMachineCollectionView();
};
export default MachineCollectionView;
