import React, { RefObject, useState } from 'react';
import { AuthService, CategoryService, ContentService, DateService, isEmail, ProductService } from '../../../services';
import { Categories, Company, Content, Comment, ProductCreation, ReleasedMachineCollection, Tag, User } from '../../../models';
import { CommentType, MachineCollectionType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import ConsentModal from './ConsentModal';
import CompanyFormWithSelection from '../company/CompanyFormWithSelection';
import ProductCategoryForm from './ProductCategoryForm';
import ProductImageForm from './ProductImageForm';
import ProductReleaseBasicForm from './ProductReleaseBasicForm';
import Banner, { BannerType } from '../../common/Banner';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexJustification, FlexDirection } from '../../common/Flex';
import Float from '../../common/Float';
import Icon, { IconType } from '../../common/Icon';
import KeyVisual from '../../common/KeyVisual';
import Sticky from '../../common/Sticky';
import Title from '../../common/Title';
import Wizard from '../../common/Wizard';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Modal from '../../common/Modal';
import Checkbox from '../../common/Checkbox';
import Link from '../../common/Link';
import { WizardStep } from '../../../enums/WizardStep';

interface ProductReleaseWizardProps {
    accordionHeaderRefs: RefObject<HTMLDivElement>[];
    accordionsOpen: boolean[];
    categories: Categories;
    companies: Company[];
    image: File;
    imageUrl: string;
    machineCollection: ReleasedMachineCollection;
    product: ProductCreation;
    readOnly: boolean;
    tags: Tag[];
    user: User;
    editing?: boolean;
    onAccordionToggle: (index: number) => void;
    onCompanyChange: (company: Partial<Company>) => void;
    onCompanySet: (company: Company) => Promise<void>;
    onFileReceived: (file: File) => void;
    onProductChange: (product: Partial<ProductCreation>) => Promise<void>;
    onProductSave: (isIncomplete?: boolean, isVisibleForSiemensRepresentative?: boolean, comments?: Comment[]) => Promise<void>;
    onWizardCancel: () => Promise<void>;
    onMaxResExceeded?: () => void;
}

const ProductReleaseWizard = ({ accordionHeaderRefs, accordionsOpen, categories, companies, image, imageUrl, machineCollection, product, readOnly, tags,
    user, editing, onAccordionToggle, onCompanyChange, onCompanySet, onFileReceived, onProductChange, onProductSave, onWizardCancel,
    onMaxResExceeded }: ProductReleaseWizardProps) => {
    const MAX_LENGTH = 1000;
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [delegated, setDelegated] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isVisibleForSiemensRepresentative, setisVisibleForSiemensRepresentative] = useState(true);
    const [_legalAccepted, setLegalAccepted] = useState(false);
    const [releaseComment, setReleaseComment] = useState('');
    const [selectedReleaseDate, setSelectedReleaseDate] = useState(null);
    const [shouldDisplaySaveDialog, setShouldDisplaySaveDialog] = useState(false);
    const [shouldDisplayConsentModal, setShouldDisplayConsentModal] = useState(false);
    const [wizardStep, setWizardStep] = useState(0);

    const getRelevantCommentType = (): CommentType => {
        const isSiemensRepresentative = AuthService.hasRole(['SiemensRepresentative']);

        return isSiemensRepresentative ? CommentType.RepresentativeComment : CommentType.ReleaseComment;
    };

    const handleSubmit = async () => {
        if (wizardStep < WizardStep.BasicInformation) {
            setWizardStep(wizardStep + 1);
        } else {
            openReleaseModal();
        }
    };

    const handleStepClick = (step: number) => {
        setWizardStep(step);
    };

    const handleCommentChange = (value: string, _name: string) => {
        setReleaseComment(value);
    };

    const handleReleaseDateChange = (date: Date) => setSelectedReleaseDate(date);

    const openReleaseModal = () => {
        setShouldDisplayConsentModal(true);
        setSelectedReleaseDate(DateService.areEqual(product.customReleaseDate, machineCollection.fairStartDate)
            ? machineCollection.fairPreStartDate
            : product.customReleaseDate);
    };

    const cancelReleaseModal = async () => {
        setLegalAccepted(false);
        setReleaseComment('');
        setSelectedReleaseDate(null);
        setShouldDisplayConsentModal(false);
    }; 

    const closeReleaseModal = async () => {
        setShouldDisplayConsentModal(false);

        const commentType = getRelevantCommentType();
        const existingReleaseComment = product.comments.find(x => x.type == commentType);

        const comments = existingReleaseComment
            ? [...product.comments.filter(x => x.type !== commentType), { comment: releaseComment, type: commentType }]
            : [...product.comments, { comment: releaseComment, type: commentType }];

        await onProductChange({ comments, customReleaseDate: selectedReleaseDate });
        await onProductSave(false, isVisibleForSiemensRepresentative, comments);
    };

    const openSaveDialog = async (isSaving: boolean) => {
        if (readOnly) {
            await onWizardCancel();
        } else {
            setIsSaving(isSaving);
            setShouldDisplaySaveDialog(true);
        }
    };

    const closeSaveDialog = () => {
        setIsSaving(false);
        setShouldDisplaySaveDialog(false);
    };

    const toggleDelegation = () => {
        setDelegated(!delegated);
    };

    const isValid = () => {
        return isWizardStepValid(wizardStep);
    };

    const isWizardStepValid = (wizardStep: number) => {
        const isSiemensRepresentative = AuthService.hasRole(['SiemensRepresentative']);
        const correctSteps = [false, false, false, false];

        switch (wizardStep) {
            case WizardStep.Company:
                correctSteps[WizardStep.Company] = ProductService.isCompanyValid(product) && product.company.siemensRepresentativeEmails.length !== 0
                    && (!isSiemensRepresentative || editing || isSiemensRepresentative && isEmail(product.customerApproverEmail));
                return correctSteps[WizardStep.Company];
            case WizardStep.Image:
                correctSteps[WizardStep.Image] = Boolean((product && product.imageId) || image);
                return correctSteps[WizardStep.Image];
            case WizardStep.Category:
                correctSteps[WizardStep.Category] = product && machineCollection && categories && categories.groups
                    .every(x => CategoryService.isGroupValid(x, machineCollection, product));
                return correctSteps[WizardStep.Category];
            case WizardStep.BasicInformation:
                correctSteps[WizardStep.Company] = ProductService.isCompanyValid(product) && product.company.siemensRepresentativeEmails.length !== 0
                    && (!isSiemensRepresentative || editing || isSiemensRepresentative && isEmail(product.customerApproverEmail));
                correctSteps[WizardStep.Image] = Boolean((product && product.imageId) || image);
                correctSteps[WizardStep.Category] = product && machineCollection && categories && categories.groups
                    .every(x => CategoryService.isGroupValid(x, machineCollection, product));
                correctSteps[WizardStep.BasicInformation] = product && machineCollection && product.title && isDescriptionValid()
                    && (machineCollection.type === MachineCollectionType.MachineCompass || (
                        product.hallId !== undefined && product.hallId !== null && product.booth
                        && product.customReleaseDate >= DateService.getDayBeginning(machineCollection.fairPreStartDate)
                        && product.customReleaseDate < DateService.getNextDayBeginning(machineCollection.fairStartDate)
                    ));
                return correctSteps[WizardStep.Company] && correctSteps[WizardStep.Image] && correctSteps[WizardStep.Category] && correctSteps[WizardStep.BasicInformation];
            default:
                return true;
        }
    };

    const isDescriptionValid = () => {
        const languagesToShow = ContentService.addEnglish(machineCollection.availableLanguages);

        return delegated
            ? languagesToShow.every(x => ContentService.getLength(product.description[x as keyof Content].toString()) === 0 
                || ContentService.isValid(product.description[x as keyof Content].toString(), MAX_LENGTH))
                && languagesToShow.some(x => ContentService.isValid(product.description[x as keyof Content].toString(), MAX_LENGTH))
            : languagesToShow.every(x => ContentService.isValid(product.description[x as keyof Content].toString(), MAX_LENGTH)) 
                && languagesToShow.every(x => ContentService.isValid(product.secondDescription[x as keyof Content].toString(), MAX_LENGTH));
    };

    const renderConsentModal = () => {
        return shouldDisplayConsentModal && (
            <ConsentModal approvalMode={false} product={product} machineCollection={machineCollection} releaseComment={releaseComment} selectedReleaseDate={selectedReleaseDate}
                onCancelModal={cancelReleaseModal} onCloseModal={closeReleaseModal} onCommentChange={handleCommentChange} onReleaseDateChange={handleReleaseDateChange} />
        );
    };

    const renderSaveDialog = () => {
        return shouldDisplaySaveDialog && (
            <Modal dark={dark} open={shouldDisplaySaveDialog}>
                <Title style={{ marginBottom: '10px' }} text={translate(translations.dialog.saveItem)} />
                <Checkbox dark={dark} checked={isVisibleForSiemensRepresentative} label={translate(translations.dialog.incompleteVisible)}
                    onChange={() => setisVisibleForSiemensRepresentative(!isVisibleForSiemensRepresentative)} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    {(!companies || ProductService.isCompanyValid(product)) && 
                        <Link dark={dark} text={translate(translations.action.yes)} onClick={() => onProductSave(true, isVisibleForSiemensRepresentative)} />
                    }
                    {!isSaving && 
                        <Link dark={dark} text={translate(translations.action.no)} onClick={onWizardCancel} />
                    }
                    <Link dark={dark} text={translate(translations.action.cancel)} onClick={closeSaveDialog} />
                </Flex>
            </Modal>
        );
    };

    const renderKeyVisual = () => {
        return (
            <KeyVisual height={170} url={`images/machine-compass-${dark ? 'dark-' : ''}key-visual.jpg`}>
                {!isValid() &&
                    <Flex direction={FlexDirection.Column} justification={FlexJustification.FlexEnd}>
                        <Sticky distance={60} anchor={'top'}>
                            <Banner dark={dark} type={BannerType.Warning} text={translate(translations.dialog.fillMandatory)} />
                        </Sticky>
                    </Flex>
                }
            </KeyVisual>
        );
    };

    const renderWizard = () => {
        const stepNames: string[] = [translate(translations.company.company),
            translate(translations.common.image),
            translate(translations.category.category), 
            translate(translations.common.info)];

        return <Wizard dark={dark} step={wizardStep} stepNames={stepNames} onStepClick={x => handleStepClick(x)} />;
    };

    const renderForm = () => {
        const forms: JSX.Element[] = [
            <CompanyFormWithSelection company={product ? product.company : null} companies={companies} editing={editing} product={product} readOnly={readOnly} user={user}
                                      onChange={onCompanyChange} onProductChange={onProductChange} onSet={onCompanySet} />,
            <ContentContainer>
                <ProductImageForm image={image} imageUrl={imageUrl} product={product} readOnly={readOnly} onFileReceived={onFileReceived} onMaxResExceeded={onMaxResExceeded} />
            </ContentContainer>,
            <ContentContainer>
                <ProductCategoryForm accordionHeaderRefs={accordionHeaderRefs} accordionsOpen={accordionsOpen} categories={categories} machineCollection={machineCollection} 
                                     product={product} readOnly={readOnly} onAccordionToggle={onAccordionToggle} onChange={onProductChange} />
            </ContentContainer>,
            <ContentContainer>
                <ProductReleaseBasicForm imageUrl={imageUrl} delegated={delegated} machineCollection={machineCollection} product={product} readOnly={readOnly}
                    tags={tags} onDelegationToggled={toggleDelegation} onProductChange={onProductChange} />
            </ContentContainer>
        ];

        return product && machineCollection && forms[wizardStep];
    };

    const renderButton = () => {
        return product && machineCollection && (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={5}>
                <Float dark={dark} key={`${wizardStep}-cancel`} bottom={50} paddingRight={20}>
                    <Button dark={dark} type={ButtonType.Secondary} onClick={() => !editing ? openSaveDialog(false) : onWizardCancel()}>
                        <Icon type={IconType.Close} />
                        {translate(translations.action.cancel)}
                    </Button>
                </Float>
                {!readOnly &&
                    <Float dark={dark} key={`${wizardStep}-save`} bottom={50} paddingRight={10}>
                        <Button dark={dark} type={ButtonType.Secondary} onClick={() => !editing ? openSaveDialog(true) : closeReleaseModal()}>
                            <Icon type={IconType.Folder} />
                            {translate(translations.action.save)}
                        </Button>
                    </Float>
                }
                {(!readOnly && !editing || wizardStep < 3) &&
                    <Float dark={dark} key={`${wizardStep}-submit`} bottom={50}>
                        <Button dark={dark} type={ButtonType.Primary} disabled={!isValid()} onClick={handleSubmit}>
                            <Icon type={isValid() ? IconType.Check : IconType.Warning} />
                            {translate(wizardStep < 3 ? translations.action.nextStep : translations.action.release)}
                        </Button>
                    </Float>
                }
            </Flex>
        );
    };

    const renderLabels = () => {        
        const machineName = product && product.title ? product.title : translate(translations.product.newMachine);
        const date = product && product.createdDate ? product.createdDate : new Date(Date.now());
        const title = `${machineName}, created on ${DateService.toString(date)}`;
        const companyName = product && product.company && product.company.name ? product.company.name : translate(translations.company.noCompany);
        const commentType = getRelevantCommentType();
        const releaseComment = product && product.comments && product.comments.find(x => x.type === commentType) 
        && product.comments.find(x => x.type === commentType).comment.length
            ? product.comments.find(x => x.type === commentType).comment
            : translate(translations.common.noComment);

        return (
            <div style={{ marginBottom: 30 }}>
                <Title style={{ fontSize: 24 }} text={title} bold />
                <Title style={{ fontSize: 20 }} text={companyName} />
                {user && <a href={`mailto:${user.email}`}>{user.email}</a>}
                {` (${translate(translations.common.comment)}: ${releaseComment})`}
            </div>
        );
    };

    const renderPage = () => {
        return (
            <>
                {renderKeyVisual()}
                <ContentContainer style={{ marginTop: 30, marginBottom: 30 }}>
                    {renderLabels()}
                    {renderWizard()}
                </ContentContainer>
                {renderForm()}
                <ContentContainer style={{ marginTop: 30, marginBottom: 50 }}>
                    {renderButton()}
                </ContentContainer>
                {renderSaveDialog()}
                {renderConsentModal()}
            </>
        );
    };

    return renderPage();
};
export default ProductReleaseWizard;
