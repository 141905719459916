import { Component } from 'react';
import $ from 'jquery';
import { ServiceResponse } from '../models';

export const apiCall = async <T extends ServiceResponse>(responsePromise: Promise<T>, onSuccess: (reponse: T) => Promise<void>, onError: (response?: T) => Promise<void>) => {
    let response: T = null;

    try { 
        response = await responsePromise; 
    } catch { }

    if (response && !response.hasError) {
        await onSuccess(response);
    } else {
        await onError(response);
    }
};

export const apiCalls = async <T extends ServiceResponse>(responsePromises: Promise<T[]>, onSuccess: (reponses: T[]) => Promise<void>, onError: () => Promise<void>) => {
    let responses: T[] = [];

    try { 
        responses = await responsePromises; 
    } catch { }

    if (responses && responses.every(x => x && !x.hasError)) {
        await onSuccess(responses);
    } else {
        await onError();
    }
};

export const areIntersecting = <T extends string | number>(array1: T[], array2: T[]) => array1.some(x => array2.some(y => x === y));

export const convertMegabytesToBytes = (megaBytes: number) => megaBytes * Math.pow(1024, 2);

export const copyToClipboard = (text: string) => {
    const windowNavigator: Navigator = window.navigator;

    windowNavigator.clipboard.writeText(text);
};

/*eslint-disable-next-line @typescript-eslint/ban-types*/
export const deepCopy = <T extends object>(object: T): T => JSON.parse(JSON.stringify(object));

export const flat = <T>(array: T[][]) => array.reduce((flattened, array) => flattened.concat(array), []);

export const getFormData = (file: File) => {
    const formData = new FormData();

    formData.append('file', file);

    return formData;
};

export const getNumberValueFromCss = (cssValue: string, unit: string) => Number(cssValue.replace(unit, ''));

export const getSetStateAsync = <P, S>(instance: Component<P, S>) => <K extends keyof S>(stateChange: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null)) =>
    new Promise<void>(resolve => instance.setState(stateChange, resolve));

export const isEmail = (value: string) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

export const isPhone = (value: string) => {
    const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

    return !value || (value && regex.test(value) === true);
};

export const isHeaderOverlapping = (element: HTMLDivElement) => {
    const navbar = $('#navbar-container');

    if (!navbar.length) {
        return false;
}

    const reducedNavbarHeight = 60;
    const navbarOffset = getNumberValueFromCss(navbar.css('top'), 'px');
    const reducedNavbarBottomPosition = navbarOffset + navbar.offset().top + reducedNavbarHeight;

    return reducedNavbarBottomPosition > element.offsetTop;
};

export const toggleListElement = <T>(list: T[], element: T) => list.some(x => x === element) ? list.filter(x => x !== element) : [...list, element];

export const unique = <T>(values: T[], map: (value: T) => number | string) => values.filter((value, index, array) => array.findIndex(x => map(x) === map(value)) === index);

