import { flat, unique } from '.';
import { Filter, Hall, MachineCollection, MachineCollectionCategoryGroup, PossibleValues, Product, ProductCategory, RequestFilter, Tag } from '../models';
import { CategoryGroupRule, LandingPageOrderType } from '../enums';

export class FilterService {
    static getFilteredProducts(filter: Filter, collection: MachineCollection): Product[] {
        const products = collection ? collection.products : [];
        const categoryGroups = collection ? collection.categoryGroups : [];

        return products.filter(x =>
            (!filter.companyNames.length || filter.companyNames.some(y => x.company && x.company.name === y)) &&
            (!filter.countries.length || filter.countries.some(y => x.company && x.company.country !== null && x.company.country.toLowerCase() === y.toLowerCase())) &&
            (!filter.hallIds.length || filter.hallIds.some(y => x.hall && x.hall.id === y)) &&
            (!filter.tags.length || filter.tags.some(y => x.tags.some(z => y.id === z.id))) &&
            categoryGroups.every(y => this.filterByCategoryGroup(filter, x, y.id)));
    }

    static isFiltered(filter: Filter) {
        // eslint-disable-next-line no-extra-parens
        return Object.keys(filter).filter(x => x !== 'favouriteIds' && Array.isArray(filter[x as keyof Filter])).some(x => (filter[x as keyof Filter] as string[] | ProductCategory[]).length);
    }
    
    static createRequestFilter(filter: Filter, categoryGroups?: MachineCollectionCategoryGroup[], orderBy?: LandingPageOrderType, offset?: number): RequestFilter {
        const requestFilter: RequestFilter = { 
            companyNames: filter.companyNames, 
            countries: filter.countries,
            tags: filter.tags,
            selectedProductCategories: [],
            availableCategories: [],
            orderBy: orderBy ? orderBy : null,
            offset: offset ? offset : 0,
            productCount: null
        };
        
        if (categoryGroups) {
            categoryGroups.forEach(x => {
                if (filter[x.id]) {
                    const productCategoriesToUpdate = requestFilter.selectedProductCategories.find(y => y.groupId == x.id);
                    
                    if (productCategoriesToUpdate) {
                        productCategoriesToUpdate.categories.concat(filter[x.id]);
                        
                        requestFilter.selectedProductCategories.find(y => y.groupId == x.id).categories = productCategoriesToUpdate.categories;
                    } else {
                        requestFilter.selectedProductCategories.push({ groupId: x.id, groupRule: x.rule, categories: filter[x.id] });
                    }
                }
            });
        }
        
        return requestFilter;
    }

    static getPossibleValues(filter: Filter, collection: MachineCollection) {
        const possibleValues: PossibleValues = {
            companyNames: FilterService.getPossibleCompanies(filter, collection).sort(),
            countries: FilterService.getPossibleCountries(filter, collection).sort(),
            halls: FilterService.getPossibleHalls(filter, collection),
            tags: FilterService.getPossibleTags(filter, collection).sort()
        };

        if (collection) {
            collection.categoryGroups.forEach(x => possibleValues[x.id] = FilterService.getPossibleCategories(filter, collection, x)
                .sort((y, z) => y.content.en > z.content.en ? 1 : -1 ));
        }

        return possibleValues;
    }

    static isCategoryDeletable(filter: Filter, collection: MachineCollection, categoryGroup: MachineCollectionCategoryGroup, categoryId: number) {
        let isDeletable = false;

        if (collection) {
            switch (categoryGroup.rule) {
                case CategoryGroupRule.ExactlyOne:
                    isDeletable = Boolean(FilterService.getFilteredProducts({ ...filter, [categoryGroup.id]: filter[categoryGroup.id].filter(x => categoryId !== x.id) }, collection).length);
                    break;
                case CategoryGroupRule.OneOrMore:
                    isDeletable = true;
                    break;
                default:
                    break;
            }
        }

        return isDeletable;
    }

    static isCompanyDeletable(filter: Filter, collection: MachineCollection, companyName: string) {
        return collection
            ? Boolean(FilterService.getFilteredProducts({ ...filter, companyNames: filter.companyNames.filter(x => companyName !== x) }, collection).length)
            : false;
    }

    static isCountryDeletable(filter: Filter, collection: MachineCollection, country: string) {
        return collection
            ? Boolean(FilterService.getFilteredProducts({ ...filter, countries: filter.countries.filter(x => country !== x) }, collection).length)
            : false;
    }

    static isHallDeletable(filter: Filter, collection: MachineCollection, hallId: string) {
        return collection
            ? Boolean(FilterService.getFilteredProducts({ ...filter, hallIds: filter.hallIds.filter(x => hallId !== x) }, collection).length)
            : false;
    }

    static isTagDeletable(filter: Filter, collection: MachineCollection, tagId: string) {
        return collection
            ? Boolean(FilterService.getFilteredProducts({ ...filter, tags: filter.tags.filter(x => tagId !== x.id) }, collection).length)
            : false;
    }
    
    static mergeFilterWithRequestFilter(filter: Filter, requestFilter: RequestFilter) {
        const newAvailableFilters = requestFilter;
        
        newAvailableFilters.companyNames = unique([...filter.companyNames, ...requestFilter.companyNames], x => x);
        newAvailableFilters.countries = unique([...filter.countries, ...requestFilter.countries], x => x);
        newAvailableFilters.tags = unique([...filter.tags, ...requestFilter.tags], x => x.id);

        newAvailableFilters.availableCategories.forEach(x => {
            if (filter[x.groupId]) {
                x.categories = [...new Map([...filter[x.groupId], ...x.categories].map(v => [JSON.stringify([v.id, v.isPrimary]), v])).values()];
            }
        });

        return newAvailableFilters;
    }

    private static filterByCategoryGroup(filter: Filter, product: Product, categoryGroupId: number) {
        const group = product.categoryGroups.find(x => x.groupId === categoryGroupId);
        
        let isMatching = !filter[categoryGroupId].length;

        if (!isMatching && group) {
            switch (group.groupRule) {
                case CategoryGroupRule.ExactlyOne:
                    if (group.categories.some(x => x.isPrimary)) {
                        isMatching = (filter[categoryGroupId].every(x => !x.isPrimary) || filter[categoryGroupId].filter(x => x.isPrimary).some(x => group.categories.some(y => x.id === y.id && x.isPrimary === y.isPrimary)))
                            && filter[categoryGroupId].filter(x => !x.isPrimary).every(x => group.categories.some(y => x.id === y.id && x.isPrimary === y.isPrimary));
                    } else {
                        isMatching = filter[categoryGroupId].some(x => group.categories.some(y => x.id === y.id && x.isPrimary === y.isPrimary));
                    }
                    break;
                case CategoryGroupRule.OneOrMore:
                    isMatching = filter[categoryGroupId].every(x => group.categories.some(y => x.id === y.id));
                    break;
                default:
                    break;
            }
        }

        return isMatching;
    }

    private static getPossibleCategories(filter: Filter, collection: MachineCollection, categoryGroup: MachineCollectionCategoryGroup) {
        let possibleCategories: ProductCategory[];

        switch (categoryGroup.rule) {
            case CategoryGroupRule.ExactlyOne:
                if (categoryGroup.primaryCategories.length) {
                    possibleCategories = unique(flat(FilterService.getFilteredProducts({ ...filter, [categoryGroup.id]: [] }, collection)
                        .map(x => FilterService.getCategoriesForGroup(x, categoryGroup.id))).filter(x => x !== null && x.isPrimary), x => x.id);
                    possibleCategories = possibleCategories.concat(unique(flat(FilterService.getFilteredProducts(filter, collection)
                        .map(x => FilterService.getCategoriesForGroup(x, categoryGroup.id))).filter(x => x !== null && !x.isPrimary), x => x.id));
                }
                else {
                    possibleCategories = unique(flat(FilterService.getFilteredProducts({ ...filter, [categoryGroup.id]: [] }, collection)
                        .map(x => FilterService.getCategoriesForGroup(x, categoryGroup.id))).filter(x => x !== null), x => x.id);
                }
                break;
            case CategoryGroupRule.OneOrMore:
                possibleCategories = unique(flat(FilterService.getFilteredProducts(filter, collection)
                    .map(x => FilterService.getCategoriesForGroup(x, categoryGroup.id))).filter(x => x !== null), x => x.id);
                break;
            default:
                break;
        }

        return possibleCategories;
    }

    private static getPossibleCompanies(filter: Filter, collection: MachineCollection): string[] {
        return collection
            ? unique(FilterService.getFilteredProducts({ ...filter, companyNames: [] }, collection).map(x => x.company).filter(x => x !== null).map(x => x.name).filter(x => x !== null), x => x)
            : [];
    }

    private static getPossibleCountries(filter: Filter, collection: MachineCollection): string[] {
        return collection
            ? unique(FilterService.getFilteredProducts({ ...filter, countries: [] }, collection).map(x => x.company).filter(x => x !== null).map(x => x.country).filter(x => x), x => x.toLowerCase())
            : [];
    }

    private static getPossibleHalls(filter: Filter, collection: MachineCollection): Hall[] {
        return collection
            ? unique(FilterService.getFilteredProducts({ ...filter, hallIds: [] }, collection).map(x => x.hall).filter(x => x !== null), x => x.id)
            : [];
    }

    private static getPossibleTags(filter: Filter, collection: MachineCollection): Tag[] {
        return collection
            ? unique(flat(FilterService.getFilteredProducts({ ...filter, tags: [] }, collection).map(x => x.tags)).filter(x => x !== null), x => x.id)
            : [];
    }

    private static getCategoriesForGroup(product: Product, categoryGroupId: number) {
        const group = product.categoryGroups.find(y => y.groupId === categoryGroupId);
        
        return group ? group.categories : [];
    }
}
