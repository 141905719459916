import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useMatch } from 'react-router-dom';
import { Api, apiCall } from '../../../services';
import { HeaderTabType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import ProductView from './ProductView';
import Loader from '../../common/Loader';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const ProductApprovalManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const match = useMatch('approval/:id');
    const id = match?.params?.id;
    const [product, setProduct] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [releaseComment, setReleaseComment] = useState('');

    useEffect(() => {
        dispatch(setActiveTabType(HeaderTabType.MachineCollection));

        window.scrollTo(0, 0);

        const errorText = translate(translations.error.productNotAvailable);

        (async () => {
            const startingUrl = window.location.pathname;

            dispatch(setLoading(true));

            await apiCall(
                Api.getProductByToken(id),
                async x => {
                    setProduct(x.data);
                },
                async () => {
                    dispatch(addSnackbar({ text: errorText, type: SnackbarType.Error, dark: dark }));
                    setRedirect(true);
                }
            );

            if (startingUrl === window.location.pathname) {
                dispatch(setLoading(false));
            }
        })();
    }, []);

    const handleCommentChange = (comment: string) => {
        setReleaseComment(comment);
    };

    const handleApproval = async () => {
        dispatch(setLoading(true));

        await apiCall(
            Api.approveProduct(id, releaseComment),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulRelease), type: SnackbarType.Success, dark: dark }));
                setRedirect(true);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.productUpdate), type: SnackbarType.Error, dark: dark }));
            }
        );
    };

    const handleRejection = async () => {
        dispatch(setLoading(false));

        await apiCall(
            Api.rejectProduct(id, releaseComment),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulReject), type: SnackbarType.Success, dark: dark }));
                setRedirect(true);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.productUpdate), type: SnackbarType.Error, dark: dark }));
            }
        );
    };

    const renderView = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                <Loader dark={dark} loading={isLoading}>
                    <ProductView product={product} releaseComment={releaseComment} approvalMode
                        onApprove={handleApproval} onReject={handleRejection} onCommentChange={handleCommentChange} />
                    {redirect &&
                        <Navigate to='/' />
                    }
                </Loader>
            </div>
        );
    };

    return renderView();
};
export default ProductApprovalManager;
