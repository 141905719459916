import { AuthService } from './AuthService';
import { CookieType } from '../enums';
import { CookieService } from './CookieService';

declare global {
    interface Window {
        ste_statistic: any[]
    }
}

export class TrackingService {
    
    static InitializeStatistics = () => {
        window.ste_statistic = window.ste_statistic || [];
    };
    static Track (trigger: string, path: string, title: string) {
        if (CookieService.isCookieAllowed(CookieType.Performance)){
            TrackingService.InitializeStatistics();
            window.ste_statistic.push({
                action: trigger,
                data: {
                    page: {
                        path: path,
                        title: title,
                        country: 'WW',
                        language: 'en'
                    }
                }
            });
        }
    }

    static TrackProduct (trigger: string, path: string, title: string, company: string){
        if (CookieService.isCookieAllowed(CookieType.Performance)){
            TrackingService.InitializeStatistics();
            window.ste_statistic.push({
                action: trigger,
                data: {
                    page: {
                        path: path,
                        title: title,
                        country: 'WW',
                        language: 'en'
                    },
                    product: {
                        manufacturer: company
                    },
                    user: {
                        loginStatus: AuthService.isAuthenticated() ? 'logged_in' : 'logged_out'
                    }
                }
            });
        }
    }
    
    static TrackError (trigger: string, errorCode: string) {
        if (CookieService.isCookieAllowed(CookieType.Performance)) {
            TrackingService.InitializeStatistics();
            window.ste_statistic.push({
                action: trigger,
                data: {
                    page: {
                        template: errorCode
                    }
                }
            });
        }
    }
}
