import React from 'react';
import { ContentService } from '../../../services';
import { ProductCreation } from '../../../models';
import { TRANSLATIONS } from '../../../constants';
import Grid from '../../common/Grid';
import { IconType } from '../../common/Icon';
import SideIcon from '../../common/SideIcon';
import TextEditor from '../../common/TextEditor';
import { useTranslate } from '../../../hooks/common';
import TextInput from '../../common/TextInput';

interface ProductBasicFormProps {
    imageUrl: string;
    product: ProductCreation;
    readOnly?: boolean
    onChange: (product: Partial<ProductCreation>) => void;
}

const ProductBasicForm = ({ imageUrl, product, readOnly, onChange }: ProductBasicFormProps) => {
    const MAX_LENGTH = 1000;
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const handleInputChange = (value: string, name: string) => {
        onChange({ [name]: value });
    };

    const handleDescriptionChange = (value: string) => {
        onChange({ description: { ...product.description, en: value } });
    };

    const renderForm = () => {
        return (
            <Grid columns={2} columnsMedium={1} columnGap={40} rowGap={30}>
                <div>
                    <SideIcon iconType={IconType.Bookmark} highlighted={!product.title}>
                        <TextInput label={translate(translations.product.machineName)} error={product.title && product.title.length < 1} value={product.title} name='title' 
                                   onChange={handleInputChange} disabled={readOnly} required={!readOnly} />
                    </SideIcon>
                    <SideIcon style={{ marginTop: 30 }}>
                        <img src={imageUrl} width='100%' />
                    </SideIcon>
                </div>
                <div>
                    <SideIcon iconType={IconType.Menu} highlighted={!ContentService.isValid(product.description.en, MAX_LENGTH)}>
                        <TextEditor value={product.description.en} label={translate(translations.common.description)} maxLength={MAX_LENGTH} onChange={handleDescriptionChange} 
                                    disabled={readOnly} required={!readOnly} allowFormating />
                    </SideIcon>
                    <SideIcon iconType={IconType.Menu} highlighted={!ContentService.isValid(product.secondDescription.en, MAX_LENGTH)}>
                        <TextEditor value={product.secondDescription.en} label={translate(translations.common.descriptionCNC)} maxLength={MAX_LENGTH} onChange={handleDescriptionChange} 
                                    disabled={readOnly} required={!readOnly} allowFormating />
                    </SideIcon>
                </div>
            </Grid>
        );
    };

    return renderForm();
};
export default ProductBasicForm;
