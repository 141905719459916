import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoaderState {
    productsLoading: boolean;
    sharedMachinesLoading: boolean;
    chiefEditorProductsLoading: boolean;
    incompleteProductsLoading: boolean;
    inReviewProductsLoading: boolean;
    productRequestsLoading: boolean;
    reviewedProductsLoading: boolean;
}

const initialState: LoaderState = {
    productsLoading: false,
    sharedMachinesLoading: false,
    chiefEditorProductsLoading: false,
    incompleteProductsLoading: false,
    inReviewProductsLoading: false,
    productRequestsLoading: false,
    reviewedProductsLoading: false
};

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setProductsLoading: (state: LoaderState, action: PayloadAction<boolean>) => {
            state.productsLoading = action.payload;
        },              
        setSharedMachinesLoading: (state: LoaderState, action: PayloadAction<boolean>) => {
            state.sharedMachinesLoading = action.payload;
        },        
        setChiefEditorProductsLoading: (state: LoaderState, action: PayloadAction<boolean>) => {
            state.chiefEditorProductsLoading = action.payload;
        },        
        setIncompleteProductsLoading: (state: LoaderState, action: PayloadAction<boolean>) => {
            state.incompleteProductsLoading = action.payload;
        },        
        setInReviewProductsLoading: (state: LoaderState, action: PayloadAction<boolean>) => {
            state.inReviewProductsLoading = action.payload;
        },        
        setProductRequestsLoading: (state: LoaderState, action: PayloadAction<boolean>) => {
            state.productRequestsLoading = action.payload;
        },        
        setReviewedProductsLoading: (state: LoaderState, action: PayloadAction<boolean>) => {
            state.reviewedProductsLoading = action.payload;
        }
    }
});


export const { setProductsLoading, setReviewedProductsLoading, 
    setIncompleteProductsLoading, setProductRequestsLoading, 
    setInReviewProductsLoading, setChiefEditorProductsLoading,
    setSharedMachinesLoading } = loaderSlice.actions;
export const loaderReducer = loaderSlice.reducer;
