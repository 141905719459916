import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeaderTabType } from '../enums';

export interface LayoutState {
    activeTabType?: HeaderTabType;
    dark?: boolean;
    shownCollectionModal?: boolean;
}

const initialState: LayoutState = {
    activeTabType: null,
    dark: window.localStorage.getItem('dark')
        ? window.localStorage.getItem('dark') === 'true'
        : false,
    shownCollectionModal: false
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setActiveTabType: (state: LayoutState, action: PayloadAction<HeaderTabType>) => {
            state.activeTabType = action.payload;
        },
        setDark: (state: LayoutState, action: PayloadAction<boolean>) => {
            window.localStorage.setItem('dark', action.payload ? 'true' : 'false');
            state.dark = action.payload;
        },
        setShownCollectionModal: (state: LayoutState, action: PayloadAction<boolean>) => {
            state.shownCollectionModal = action.payload;
        }
    }
});

export const { setActiveTabType, setDark, setShownCollectionModal } = layoutSlice.actions;
export const layoutReducer = layoutSlice.reducer;
