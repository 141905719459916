import React, { Component, CSSProperties, ReactNode } from 'react';
import Icon, { IconType } from './Icon';

interface SideIconProps {
    className?: string;
    children?: ReactNode;
    iconType?: IconType;
    highlighted?: boolean;
    style?: CSSProperties;
}

const SideIcon = ({ className, children, iconType, highlighted, style }: SideIconProps) => {
    const renderSideIcon = () => {
        const sideIconClassName = `side-icon ${className ? className : ''}`;
        const iconColumnClass = `icon-column ${highlighted ? 'highlighted' : ''}`;

        return (
            <div className={sideIconClassName} style={style}>
                <div className={iconColumnClass}>
                    {iconType !== null &&
                        <Icon type={iconType} />
                    }
                </div>
                <div className='content-column'>
                    {children}
                </div>
            </div>
        );
    };

    return renderSideIcon();
};
export default SideIcon;
