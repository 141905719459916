import React from 'react';
import { CollectionService } from '../../../services';
import { MachineCollectionListItem } from '../../../models';
import { TRANSLATIONS } from '../../../constants';
import ContentContainer from '../../common/ContentContainer';
import MainLink from '../../common/MainLink';
import { useTranslate } from '../../../hooks/common';

interface MachineCollectionListProps {
    machineCollections: MachineCollectionListItem[];
}

const MachineCollectionList = ({ machineCollections }: MachineCollectionListProps) => {
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const renderMachineCollectionList = () => {
        const sortedMachineCollections = machineCollections.slice().sort((x, y) => x.title.localeCompare(y.title));

        return (
            <ContentContainer style={{ marginTop: 30, marginBottom: 30 }}>
                <div style={{ marginBottom: 15, fontSize: 20 }}>
                    {translate(translations.dialog.choseEBooklet)}
                </div>
                {sortedMachineCollections.map(x => <MainLink key={x.id} label={x.title} internalLink={CollectionService.getLink(x.id)} />)}
            </ContentContainer>
        );
    };

    return renderMachineCollectionList();
};
export default MachineCollectionList;
