import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import Title from '../../common/Title';
import { setActiveTabType } from '../../../store';
/*eslint-disable react/no-unknown-property*/
const CookieNotice = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    useEffect(() => {
        dispatch(setActiveTabType(null));
    }, []);

    const renderText = () => {
        const notice: JSX.Element[] = [];

        for (let i = 1; i <= 6; i++) {
            notice.push(<p>{translate(translations.cookie.notice[i.toString() as keyof typeof translations.cookie.notice])}</p>);
        }

        notice.push(<p><b>{translate(translations.cookie.necessaryTitle)}</b></p>);
        
        for (let i = 1; i <= 3; i++){
            notice.push(<p>{translate(translations.cookie.necessaryDescription[i.toString() as keyof typeof translations.cookie.necessaryDescription])}</p>);
        }

        notice.push(<p><b>{translate(translations.cookie.performanceTitle)}</b></p>);

        for (let i = 1; i <= 3; i++){
            notice.push(<p>{translate(translations.cookie.performanceDescription[i.toString() as keyof typeof translations.cookie.performanceDescription])}</p>);
        }

        notice.push(<p><b>{translate(translations.cookie.functionalityTitle)}</b></p>);

        for (let i = 1; i <= 2; i++){
            notice.push(<p>{translate(translations.cookie.functionalityDescription[i.toString() as keyof typeof translations.cookie.functionalityDescription])}</p>);
        }

        notice.push(<p><b>{translate(translations.cookie.marketingTitle)}</b></p>);

        for (let i = 1; i <= 2; i++){
            notice.push(<p>{translate(translations.cookie.marketingDescription[i.toString() as keyof typeof translations.cookie.marketingDescription])}</p>);
        }

        for (let i = 1; i <= 2; i++){
            notice.push(<p>{translate(translations.cookie.noticeFinal[i.toString() as keyof typeof translations.cookie.noticeFinal])}</p>);
        }
        
        return notice;
    };
    
    const renderNotice = () => {
        return (
          <ContentContainer style={{ marginTop: '30px' }}>
              <Title style={{ fontSize: '2.25rem' }} text={'Cookie Notice'} bold />
              <Button type={ButtonType.Primary} onClick={() => eval('UC_UI.showSecondLayer();')}>
                  Manage Preferences
              </Button>
              <ContentContainer style={{ marginTop: '30px' }}>
                  {renderText()}
              </ContentContainer>
              <div className='uc-embed' uc-data='all' uc-embedding-type='category' uc-styling='true'></div>
          </ContentContainer>  
        );
    };
    
    const renderPage = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                {renderNotice()}
            </div>
        );
    };

    return renderPage();
};
export default CookieNotice;
