import React from 'react';
import { AuthService, isEmail, isPhone } from '../../../services';
import { Company, ProductCreation, User } from '../../../models';
import { COUNTRY_CODES, IMAGES, TRANSLATIONS } from '../../../constants';
import Grid from '../../common/Grid';
import { IconType } from '../../common/Icon';
import Select from '../../common/Select';
import SideIcon from '../../common/SideIcon';
import SpacingContainer from '../../common/SpacingContainer';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import TagInput from '../../common/TagInput';
import TextInput from '../../common/TextInput';

interface CompanyFormProps {
    company: Company;
    product?: ProductCreation;
    readOnly?: boolean;
    reviewMode?: boolean;
    user?: User;
    onChange: (company: Partial<Company>) => void;
    onProductChange?: (product: Partial<ProductCreation>) => void;
}

const CompanyForm = ({ company, product, readOnly, reviewMode, user, onChange, onProductChange }: CompanyFormProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const handleChange = (value: string, name: string) => {        
        onChange({ [name]: value });
    };

    const handleCountryChange = (code: string) => {
        onChange({ country: code });
    };

    const handleEmailAdd = (email: string) => {
        const { siemensRepresentativeEmails } = company;

        if (isEmail(email)) {
            onChange({ siemensRepresentativeEmails: [...siemensRepresentativeEmails, email] });
        }
    };

    const handleEmailRemove = (email: string) => {
        const { siemensRepresentativeEmails } = company;

        onChange({ siemensRepresentativeEmails: siemensRepresentativeEmails.filter(x => x !== email) });
    };

    const handleApprovingCustomerChange = (customer: string) => {
        onProductChange({ customerApproverEmail: customer });
    };

    const getEmailErrorMessage = (value: string, required = false) => {
        return isEmail(value) || (!value && !required) ? null : translate(translations.error.invalidFormat);
    };

    const getPhoneErrorMessage = (value: string) => {
        return isPhone(value) ? null : translate(translations.error.invalidFormat);
    };

    const getZipErrorMessage = (value: string) => {
        const maxZipLength = 12;
        
        return value && value.length > maxZipLength ? translate(translations.error.tooLong) : null;
    };

    const renderCountryText = (code: string) => {
        return code ? translate(translations.countries[code as keyof typeof translations.countries]) : '';
    };

    const renderCountry = (code: string) => {
        return (
            <>
                {code && <img alt={code} style={{ marginRight: '5px' }} src={IMAGES[code]} />}
                {renderCountryText(code)}
            </>
        );
    };

    const renderForm = () => {
        const isSiemensRepresentative = AuthService.hasRole(['SiemensRepresentative']);
        let customerApprovalEmail: string = null;

        if (readOnly || reviewMode) {
            if (user && user.roles && !user.roles.includes('SiemensRepresentative')){
                customerApprovalEmail = user.email;
            }
        }
        
        return company && (
            <Grid columns={2} columnsMedium={1} columnGap={40} rowGap={10}>
                <SpacingContainer>
                    <SideIcon iconType={IconType.Information}>
                        <TextInput dark={dark} label={translate(translations.company.companyName)} value={company.name} name='name' onChange={handleChange} disabled={readOnly} 
                                   required={!readOnly} error={!company.name} />
                    </SideIcon>
                    <SideIcon iconType={IconType.Information}>
                        <TextInput dark={dark} label={translate(translations.common.subtitle)} value={company.subtitle} name='subtitle' disabled={readOnly} 
                                   onChange={handleChange} />
                    </SideIcon>
                    <SideIcon iconType={IconType.Location}>
                        <TextInput dark={dark} label={translate(translations.common.street1)} value={company.street1} name='street1' disabled={readOnly} onChange={handleChange} 
                                   required={!readOnly} error={!company.street1} />
                    </SideIcon>
                    <SideIcon iconType={IconType.Location}>
                        <TextInput dark={dark} label={translate(translations.common.street2)} value={company.street2} name='street2' disabled={readOnly} onChange={handleChange} />
                    </SideIcon>
                    <SideIcon iconType={IconType.Location}>
                        <TextInput dark={dark} label={translate(translations.common.zip)} error={getZipErrorMessage(company.zip) !== null || !company.zip} value={company.zip} 
                                   name='zip' disabled={readOnly} onChange={handleChange} required={!readOnly} getMessage={getZipErrorMessage} />
                    </SideIcon>
                    <SideIcon iconType={IconType.Location}>
                        <TextInput dark={dark} label={translate(translations.common.city)} error={!company.city} value={company.city} name='city' disabled={readOnly} 
                                   onChange={handleChange} required={!readOnly} />
                    </SideIcon>
                    <SideIcon iconType={IconType.Language}>
                        <Select dark={dark} label={translate(translations.common.country)} value={company.country} values={COUNTRY_CODES} disabled={readOnly} 
                                mapToString={x => renderCountryText(x)} mapToContent={x => renderCountry(x)} onSelect={handleCountryChange} required={!readOnly} 
                                error={!company.country} />
                    </SideIcon>
                </SpacingContainer>
                <SpacingContainer>
                    <SideIcon iconType={IconType.Language}>
                        <TextInput dark={dark} label={translate(translations.common.web)} value={company.web} name='web' disabled={readOnly} onChange={handleChange} />
                    </SideIcon>
                    <SideIcon iconType={IconType.Email}>
                        <TextInput dark={dark} label={translate(translations.common.email)} error={getEmailErrorMessage(company.email) !== null} value={company.email} name='email' 
                                   disabled={readOnly} onChange={handleChange} getMessage={getEmailErrorMessage} />
                    </SideIcon>
                    <SideIcon iconType={IconType.Phone}>
                        <TextInput dark={dark} label={translate(translations.common.phone)} error={getPhoneErrorMessage(company.phoneNumber) !== null} value={company.phoneNumber} 
                                   name='phoneNumber' disabled={readOnly} onChange={handleChange} getMessage={getPhoneErrorMessage} maxLength={20} />
                    </SideIcon>
                    <SideIcon iconType={IconType.Phone}>
                        <TextInput dark={dark} label={translate(translations.common.fax)} value={company.fax} name='fax' disabled={readOnly} maxLength={20}
                                   onChange={handleChange} />
                    </SideIcon>
                    <SideIcon style={{ marginTop: 30 }} iconType={IconType.Support}>
                        <TagInput dark={dark} label={translate(translations.company.siemensRepresentativeEmails)} 
                                  error={company.siemensRepresentativeEmails.length === 0 || company.siemensRepresentativeEmails.some(x => getEmailErrorMessage(x, true) !== null)} 
                                  values={company.siemensRepresentativeEmails} disabled={readOnly} onAdd={handleEmailAdd} onRemove={handleEmailRemove} 
                                  getMessage={(x, y) => getEmailErrorMessage(x, false)} 
                                  getValueError={x => x.length > 0 && Boolean(getEmailErrorMessage(x, true))} required={!readOnly} />
                    </SideIcon>
                    {(isSiemensRepresentative || reviewMode) &&
                        <SideIcon iconType={IconType.User}>
                            <TextInput dark={dark} label={translate(translations.common.approvingCustomer)} 
                                       error={getEmailErrorMessage(product.customerApproverEmail, true) !== null} value={product.customerApproverEmail ? product.customerApproverEmail : customerApprovalEmail} 
                                       name='approvingCustomer' disabled={readOnly || reviewMode} onChange={x => handleApprovingCustomerChange(x)} 
                                       getMessage={getEmailErrorMessage} required={!readOnly && !reviewMode} />
                        </SideIcon>
                    }
                </SpacingContainer>
            </Grid>
        );
    };

    return renderForm();
};
export default CompanyForm;
