import { MachineCollectionCreation } from '../models';
import { MachineCollectionType } from '../enums';
import { EMPTY_GUID } from '.';

export const EMPTY_MACHINE_COLLECTION: MachineCollectionCreation = {
    id: EMPTY_GUID,
    type: MachineCollectionType.MachineCompass,
    title: '',
    location: '',
    imageId: '',
    imageUrl: '',
    subtitle: { de: '', en: '', es: '', gr: '', id: null, it: '' },
    description1: { de: '', en: '', es: '', gr: '', id: null, it: '' },
    description2: { de: '', en: '', es: '', gr: '', id: null, it: '' },
    imageDescription: { de: '', en: '', es: '', gr: '', id: null, it: '' },
    exhibitorsStartDate: new Date(Date.now()),
    exhibitorsEndDate: new Date(Date.now()),
    fairStartDate: new Date(Date.now()),
    fairEndDate: new Date(Date.now()),
    fairPreStartDate: new Date(Date.now()),
    availableLanguages: [],
    halls: [],
    categoryGroups: [],
    chiefEditorEmails: [],
    agencyUserEmails: [],
    creatorId: ''
};
