import React, { createRef, RefObject, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useMatch } from 'react-router-dom';
import { Api, apiCall, CategoryService, CollectionService, ContentService, getFormData, ProductService } from '../../../services';
import { Categories, Comment, Company, Content, Product, ProductCreation, ProductReview, ReleasedMachineCollection } from '../../../models';
import { CommentType, HeaderTabType, Language } from '../../../enums';
import { PRODUCT_FOLDER, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import ReviewWizard from './ReviewWizard';
import Loader from '../../common/Loader';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const ReviewWizardManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const dispatch = useDispatch();
    const isLoading = useAppSelector(state => state.app.isLoading);
    const match = useMatch('/product/:id/review');
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [accordionHeaderRefs, setAccordionHeaderRefs] = useState(null);
    const [accordionsOpen, setAccordionsOpen] = useState(null);
    const [approved, setApproved] = useState(null);
    const [filteredCategories, setFilteredCategories] = useState(null);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [machineCollection, setMachineCollection] = useState(null);
    const [message, setMessage] = useState('');
    const [product, setProduct] = useState(null);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [tags, setTags] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const { id } = match.params;

        window.scrollTo(0, 0);

        dispatch(setActiveTabType(HeaderTabType.MyProducts));

        (async () => {
            const startingUrl = window.location.pathname;

            dispatch(setLoading(true));

            let resultCategories: Categories;
            let resultProduct: Product;

            const categoryPromise = apiCall(
                Api.getCategories(),
                async x => {
                    const length = x.data.groups.length;

                    setAccordionHeaderRefs(Array<RefObject<HTMLDivElement>>(length).fill(null).map(_ => createRef()));
                    setAccordionsOpen(Array<boolean>(length).fill(true));
                    resultCategories = x.data;
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.categoryLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );

            const machineCollectionPromise = apiCall(
                Api.getReleasedMachineCollections(),
                async x => {
                    await categoryPromise;
                    await productPromise;
                    
                    setNewMachineCollection(x.data, resultProduct, resultCategories);
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.collectionsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );

            const productPromise = apiCall(
                Api.getProductRequestForUser(id),
                async x => {
                    await categoryPromise;

                    resultProduct = x.data;

                    setProduct({
                        ...ProductService.reviewMap(resultProduct),
                        categoryGroups: [...resultProduct.categoryGroups, ...CategoryService.getProductCategoryGroups(resultCategories)
                            .filter(y => resultProduct.categoryGroups.every(z => z.groupId !== y.groupId))]
                    });
                    setImageUrl(resultProduct.imageUrl);
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.productLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );

            const userPromise = id
                ? apiCall(
                    Api.getCreatorUser(id),
                    async x => {
                        setUser(x.data);
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.usersLoad).toString(), type: SnackbarType.Error, dark: dark }));
                    }
                )
                : setUser(null);

            const tagPromise = apiCall(
                Api.getTags(),
                async x => {
                    setTags(x.data);
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.tagLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );

            await categoryPromise;
            await machineCollectionPromise;
            await productPromise;
            await tagPromise;
            await userPromise;

            if (startingUrl === window.location.pathname) {
                dispatch(setLoading(false));
            }
        })();
    }, []);

    const setNewMachineCollection = async (machineCollections: ReleasedMachineCollection[], resultProduct: Product, resultCategories: Categories) => {
        const newMachineCollection = machineCollections.find(x => x.id === resultProduct.machineCollectionId);

        setFilteredCategories(CategoryService.getFilteredCategories(resultCategories, newMachineCollection));
        setMachineCollection(CollectionService.convertDates(newMachineCollection));
    };

    const handleAccordionToggle = (index: number) => setAccordionsOpen(accordionsOpen.map((x: boolean, i: number) => i === index ? !x : x));

    const handleCompanyChange = (company: Partial<Company>) => {
        setProduct({ ...product, company: { ...product.company, ...company } });
    };

    const handleFileReceived = (file: File) => {
        if (!image || image.name !== file.name) {
            setImage(file);

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = x => setImageUrl(x.target['result'] as string);
        }
    };

    const handleMessageChange = (message: string) => {
        setMessage(message);
    };

    const handleProductChange = async (newProduct: Partial<ProductCreation>) => {
        setProduct({ ...product, ...newProduct });
    };

    const handleStatusChange = (approved: boolean) => {
        setApproved(approved);
    };

    const handleWizardSubmit = async () => {
        dispatch(setLoading(true));

        let imageId = product.imageId;

        if (image) {
            await apiCall(
                Api.saveImage(PRODUCT_FOLDER, getFormData(image)),
                async x => {
                    imageId = x.data;
                },
                async () => {
                    imageId = null;
                    dispatch(addSnackbar({ text: translate(translations.error.imageSave).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );
        }

        if (imageId) {
            await apiCall(
                Api.reviewProduct(getProductReview(imageId)),
                async () => {
                    setShouldRedirect(true);
                    dispatch(addSnackbar({ text: translate(translations.dialog.successfulSave).toString(), type: SnackbarType.Success, dark: dark }));
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.productReviewSave).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );
        }

        dispatch(setLoading(false));
    };

    const getProductReview = (imageId: string): ProductReview => {
        const description: Content = { ...product.description };

        machineCollection.availableLanguages.filter((x: Language) => !ContentService.isValid(description[x.toString() as keyof Content].toString()))
            .forEach((x: Language) => description[x.toString() as keyof Content]);

        if (!approved) {
            const comments = [...product.comments.filter((x: Comment) => x.type !== CommentType.ReviewComment), { comment: message, type: CommentType.ReviewComment }];

            return { approved, message: approved ? '' : message, product: { ...product, imageId, description, comments } };
        }

        return { approved, message: approved ? '' : message, product: { ...product, imageId, description } };
    };

    const renderWizard = () => {        
        return (
            <div className={`compass-section no-overflow ${dark ? 'dark' : ''}`}>
                <Loader dark={dark} loading={isLoading} />
                <ReviewWizard accordionHeaderRefs={accordionHeaderRefs} accordionsOpen={accordionsOpen} approved={approved} categories={filteredCategories} image={image} 
                              imageUrl={imageUrl} machineCollection={machineCollection} message={message} product={product} tags={tags} user={user} 
                              onAccordionToggle={handleAccordionToggle} onCompanyChange={handleCompanyChange} onFileReceived={handleFileReceived} 
                              onMessageChange={handleMessageChange} onProductChange={handleProductChange} onStatusChange={handleStatusChange} onWizardSubmit={handleWizardSubmit}
                />
                {shouldRedirect && <Navigate to='/products' />}
            </div>
        );
    };

    return renderWizard();
};
export default ReviewWizardManager;
