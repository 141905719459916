import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import Tag from './Tag';

interface TagInputProps {
    label: string;
    values: string[];
    onAdd: (value: string) => void;
    onRemove: (value: string) => void;
    dark?: boolean;
    disabled?: boolean;
    error?: boolean;
    required?: boolean;
    getMessage?: (value: string, values: string[]) => string;
    getValueError?: (value: string) => boolean;
}

const TagInput = ({ dark, disabled, error, label, required, values, getMessage, getValueError, onAdd, onRemove }: TagInputProps) => {
    const [value, setValue] = useState('');
    const textInputRef = useRef<HTMLInputElement>(null);
    const hasError = error || getValueError && getValueError(value);
    const message = getMessage ? getMessage(value, values) : '';

    const enterValue = () => {
        if (value && (!getValueError || !getValueError(value))) {
            setValue('');
            onAdd(value);
        }
    };

    const handleBlur = () => {
        enterValue();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            enterValue();
        }
    };
    
    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && value === '') {
            onRemove(values[values.length - 1]);
        }
    };

    return (
        <div className={`tag-input ${disabled ? 'disabled' : ''} ${hasError ? 'error' : ''} ${dark ? 'dark' : ''}`}>
            <div className='tag-input-field' onClick={() => textInputRef.current?.focus()}>
                <div className={`tag-input-label ${required ? 'required' : ''}`}>
                    {label}
                </div>
                <div className='tag-input-container'>
                    {values.map(x => <Tag key={x} text={x} dark={dark} close onClick={disabled ? undefined : () => onRemove(x)} />)}
                    {!disabled &&
                        <input ref={textInputRef} type='text' value={value} onChange={handleChange} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown} onBlur={handleBlur} />
                    }
                </div>
            </div>
            {getMessage &&
                <div className={`tag-input-message ${hasError ? 'error' : ''}`}>
                    {message ? message : <br />}
                </div>
            }
        </div>
    );
};

export default TagInput;
