import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FakeLogin from './FakeLogin';
import Loader from '../../common/Loader';
import { useAppSelector } from '../../../hooks/common';
import { setActiveTabType, setLoading } from '../../../store';

const FakeLoginManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading(false));


        dispatch(setActiveTabType(null));
    }, []);

    const renderPage = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                <Loader dark={dark} loading={isLoading} />
                <FakeLogin />
            </div>
        );
    };

    return renderPage();
};
export default FakeLoginManager;
