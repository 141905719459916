import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Api, apiCall } from '../../../services';
import { Company } from '../../../models';
import { TRANSLATIONS } from '../../../constants';
import CompanyEdit from './CompanyEdit';
import Loader from '../../common/Loader';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const CompanyEditManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [company, setCompany] = useState(null);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        dispatch(setActiveTabType(null));

        window.scrollTo(0, 0);

        const startingUrl = window.location.pathname;

        (async () => {
            dispatch(setLoading(true));

            await apiCall(
                Api.getCompanyForUser(),
                async x => {
                    setCompany(x.data);
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.companyLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );

            if (startingUrl === window.location.pathname) {
                dispatch(setLoading(false));
            }
        })();
    }, []);

    const handleChange = (newCompany: Partial<Company>) => {
        setCompany({ ...company, ...newCompany });
    };

    const handleSubmit = async () => {
        dispatch(setLoading(true));

        await apiCall(
            Api.updateCompany(company),
            async () => {
                setShouldRedirect(true);
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulSave).toString(), type: SnackbarType.Success, dark: dark }));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.companySave).toString(), type: SnackbarType.Error, dark: dark }));
            }
        );

        dispatch(setLoading(false));
    };

    const renderPage = () => {
        return (
            <>
                <div className={`compass-section ${dark ? 'dark' : ''}`}>
                    <Loader dark={dark} loading={isLoading}>
                        <CompanyEdit company={company} onChange={handleChange} onSubmit={handleSubmit} />
                        {shouldRedirect && <Navigate to='/products' />}
                    </Loader>
                </div>
            </>
        );
    };

    return renderPage();
};
export default CompanyEditManager;
