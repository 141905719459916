import React from 'react';
import { useNavigate } from 'react-router-dom';
import fallbackImage from '../../../../assets/images/fallback-product.png';
import { ContentService, ProductService } from '../../../services';
import { MachineCollection, Product } from '../../../models';
import { MachineCollectionType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import Icon, { IconType } from '../../common/Icon';
import FairTag from '../../common/FairTag';
import { useAppSelector, useResized, useTranslate } from '../../../hooks/common';
import Link from '../../common/Link';
import Tooltip from '../../common/Tooltip';

interface ProductItemProps {
    machineCollection: MachineCollection;
    product: Product;
    dark?: boolean;
    onFavouriteToggle: (product: Product) => void;
}

const ProductItem = ({ dark, machineCollection, product, onFavouriteToggle }: ProductItemProps) => {
    const LARGE_IMAGE_THRESHOLD = 576;
    const activeLanguage = useAppSelector(state => state.translation.language);
    const filter = useAppSelector(state => state.app.filter);
    const mobileView = useResized(x => x <= LARGE_IMAGE_THRESHOLD);
    const navigate = useNavigate();

    const translate = useTranslate();
    const translations = TRANSLATIONS;
    
    const handleProductNavigation = (productId: string) => {
        window.sessionStorage.setItem('yScroll', scrollY.toString());
        navigate(ProductService.getLink(machineCollection.id, productId));
    };

    const renderProductLink = (productId: string, text: string) => {
        return (
            <Link dark={dark} text={text} onClick={() => handleProductNavigation(productId)} />
        );
    };

    const renderMainInfos = () => {
        const characterCount = 240;
        const description = ContentService.getFirstCharacters(ContentService.translateWithoutTags(product.description, activeLanguage), characterCount);
        let imageToUse = fallbackImage;
        
        if (mobileView && product.imageUrl) {
            imageToUse = product.imageUrl;
        } else if (!mobileView && product.thumbnailImageUrl) {
            imageToUse = product.thumbnailImageUrl;
        }

        return (
            <>
                <div className='image-container'>
                    <img alt={''} src={imageToUse} loading={'lazy'} onError={e => {
                        e.target['onerror' as keyof typeof e.target] = null;
                    }} />
                </div>
                <div className='title'>
                    {renderProductLink(product.id, product.title)}
                </div>
                <div className='description'>
                    {description}
                </div>
                <div className='show-all'>
                    {renderProductLink(product.id, translate(translations.dialog.showAllDetails))}
                </div>
            </>
        );
    };

    const renderSecondaryInfos = () => {
        const isFavourite = filter.favourites.some(x => x.id === product.id);
        const favoriteText = isFavourite ? translations.common.addedToFavourites : translations.common.addToFavourites;
        const favouriteClassName = `info favourite ${isFavourite ? 'highlighted' : ''}`;

        return (
            <div className={`secondary-infos ${dark ? 'dark' : ''}`} >
                <div className={favouriteClassName} onClick={() => onFavouriteToggle(product)}>
                    <Icon type={isFavourite ? IconType.FavoriteMarked : IconType.Favorite} />
                    <Tooltip id={'favorite-tooltip'} text={translate(favoriteText)}>
                        <div className='text'>
                            {translate(favoriteText)}
                        </div>
                    </Tooltip>
                </div>
                {machineCollection.type === MachineCollectionType.EBooklet && product.hall && product.hall.name &&
                    <div className='info'>
                    <Icon type={IconType.Buildings} />
                        <div className='text'>
                            {`${translate(translations.hall.hall)} ${product.hall.name}`}
                        </div>
                    </div>
                }
                {product.company && product.company.name &&
                    <div className='info'>
                        <Icon type={IconType.Buildings} />
                        <Tooltip id={'company-tooltip'} text={product.company.name}>
                            <div className='text'>
                                {product.company.name}
                            </div>
                        </Tooltip>
                    </div>
                }
                {product.tags.map(x => <FairTag key={x.id} id={x.id} text={x.name} color={x.color} noClose />)}
            </div>
        );
    };

    const renderProductItem = () => {
        return (
            <div className='product-item-container'>
                {renderMainInfos()}
                {renderSecondaryInfos()}
            </div>
        );
    };

    return renderProductItem();
};
export default ProductItem;
