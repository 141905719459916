import React, { ReactNode } from 'react';

interface SpacingContainerProps {
    children?: ReactNode;
}

const SpacingContainer = ({ children }: SpacingContainerProps) => (
    <div className='spacing-container'>
        {children}
    </div>
);

export default SpacingContainer;
