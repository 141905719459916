import React, { KeyboardEvent, ReactText, useEffect, useRef, useState } from 'react';
import { usePageSize } from '../../hooks/common';

export interface Tab {
    content: JSX.Element;
    label: ReactText;
    required?: boolean;
}

interface TabsProps {
    selectedTab: number;
    tabs: Tab[];
    onSelect: (index: number) => void;
    dark?: boolean;
}

export const Tabs = ({ selectedTab, dark, tabs, onSelect }: TabsProps) => {
    const [activeTab, setActiveTab] = useState(-1);
    const [underline, setUnderline] = useState({ left: -1, width: -1 });
    const size = usePageSize();
    const selectedTabRef = useRef<HTMLDivElement>(null);
    const previousSelectedTabRef = useRef(-1);

    useEffect(() => {
        setUnderline({ left: selectedTabRef.current?.offsetLeft ?? -1, width: selectedTabRef.current?.offsetWidth ?? -1 });
    }, [selectedTab, size]);

    const handleSelect = (index: number) => {
        if (index !== selectedTab) {
            previousSelectedTabRef.current = selectedTab;
            onSelect(index);
        }
    };

    const handleFocus = () => {
        setActiveTab(selectedTab);
    };

    const handleBlur = () => {
        setActiveTab(-1);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleSelect(activeTab);
        } else if (event.key === 'ArrowLeft') {
            setActiveTab(activeTab === -1
                ? 0
                : activeTab > 0 ? activeTab - 1 : tabs.length - 1);
        } else if (event.key === 'ArrowRight') {
            setActiveTab(activeTab === -1
                ? tabs.length - 1
                : activeTab < tabs.length - 1 ? activeTab + 1 : 0);
        }
    };

    const handleTabClick = (index: number) => {
        handleSelect(index);
        setActiveTab(index);
    };

    const renderTab = (tab: Tab, index: number) => {
        return (
            <div key={tab.label} ref={index === selectedTab ? selectedTabRef : null} className={`tab ${index === activeTab ? 'active' : ''} ${tab.required ? 'required' : ''}`}
                onClick={() => handleTabClick(index)}>
                {tab.label}
            </div>
        );
    };

    const renderTabContent = (tab: Tab, animationClass: string) => {
        return (
            <div key={tab.label} className={`tab-content ${animationClass}`}>
                {tab.content}
            </div>
        );
    };

    return (
        <div className={`tabs ${dark ? 'dark' : ''}`}>
            <div className='tab-switcher-container' tabIndex={0} onFocus={handleFocus} onBlur={handleBlur} onKeyDown={handleKeyDown}>
                <div className='tab-switcher'>
                    {tabs.map(renderTab)}
                    {underline.left >= 0 && underline.width >= 0 &&
                        <div className='tab-switcher-underline' style={{ left: underline.left, width: underline.width }} />
                    }
                </div>
            </div>
            <div className='tab-content-container'>
                {renderTabContent(tabs[selectedTab], previousSelectedTabRef.current === -1 ? '' : previousSelectedTabRef.current > selectedTab ? 'open-from-left' : 'open-from-right')}
                {previousSelectedTabRef.current !== -1 &&
                    renderTabContent(tabs[previousSelectedTabRef.current], previousSelectedTabRef.current > selectedTab ? 'close-to-left' : 'close-to-right')
                }
            </div>
        </div>
    );
};

export default Tabs;
