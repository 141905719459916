import React from 'react';
import { TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { ProductCreation } from '../../../models';
import { IconType } from '../../common/Icon';
import ImageUpload from '../../common/ImageUpload';
import SideIcon from '../../common/SideIcon';

interface ProductImageFormProps {
    image: File;
    imageUrl: string;
    product: ProductCreation;
    readOnly?: boolean;
    onFileReceived: (file: File) => void;
    onMaxResExceeded?: () => void;
}

const ProductImageForm = ({ image, imageUrl, product, readOnly, onFileReceived, onMaxResExceeded }: ProductImageFormProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const isImageValid = () => {
        return Boolean((product && product.imageId) || image);
    };

    const renderForm = () => {        
        return (
            <SideIcon iconType={IconType.Image}>
                <ImageUpload dark={dark} label={translate(translations.common.picture)} additonalText={translate(translations.dialog.imageFormat)} imageUrl={imageUrl} disabled={readOnly}
                    validTypes={['image/jpeg', 'image/jpg', 'image/png']} onFileReceived={onFileReceived} highlighted={!isImageValid()} maxWidth={2500}
                    minWidth={800} maxHeight={2500} minHeight={600} maxSizeInMegabytes={4} onMaxResExceeded={onMaxResExceeded} />
            </SideIcon>
        );
    };

    return renderForm();
};
export default ProductImageForm;
