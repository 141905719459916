import React, { Component, ReactNode } from 'react';

interface SideSheetInputContainerProps {
    title?: string;
    children?: ReactNode;
}

const SideSheetInputContainer = ({ title, children }: SideSheetInputContainerProps) => {
    const renderSideSheetInputContainer = () => {
        return (
            <div className='side-sheet-input-container'>
                {title &&
                    <div className='title'>
                        {title}
                    </div>
                }
                {children}
            </div>
        );
    };

    return renderSideSheetInputContainer();
};
export default SideSheetInputContainer;
