import { AuthService } from '.';

export class ApiBase {
    static readonly defaultContentType = 'application/json';
    protected static get = (url: string, signal?: AbortSignal): Promise<any> => {
        return fetch(url, ApiBase.getFetchOptions('GET', null, null, signal))
            .then(ApiBase.processResponse);
    };

    protected static request = (method: string, url: string, body?: unknown, serializeBody = true, contentType = ApiBase.defaultContentType): Promise<unknown> => {
        return fetch(url, ApiBase.getFetchOptions(method, contentType, body !== undefined && serializeBody ? JSON.stringify(body) : body))
            .then(ApiBase.processResponse);
    };

    protected static requestWithoutProcess = (method: string, url: string, body?: unknown, serializeBody = true, contentType = ApiBase.defaultContentType): Promise<unknown> => {
        return fetch(url, ApiBase.getFetchOptions(method, contentType, body !== undefined && serializeBody ? JSON.stringify(body) : body));
    };

    protected static post = (url: string, body?: unknown, serializeBody = true, contentType = ApiBase.defaultContentType): Promise<unknown> => {
        return ApiBase.request('POST', url, body, serializeBody, contentType);
    };

    protected static put = (url: string, body?: unknown, serializeBody = true): Promise<unknown> => {
        return ApiBase.request('PUT', url, body, serializeBody);
    };

    protected static delete = (url: string, body?: unknown, serializeBody = true): Promise<unknown> => {
        return ApiBase.request('DELETE', url, body, serializeBody);
    };

    private static readonly getFetchOptions = (method: string, contentType = ApiBase.defaultContentType, body?: FormData | string | unknown | null, signal?: AbortSignal): RequestInit => {
        const headers = new Headers();
        headers.append('Accept', 'application/json');

        if (contentType) {
            headers.append('Content-Type', contentType);
        }

        if (AuthService.isAuthenticated()) {
            headers.append('Authorization', `Bearer ${AuthService.getToken()}`);
        }

        const baseOptions = { credentials: 'same-origin', method, body, headers, signal } as RequestInit;

        return baseOptions;
    };

    private static readonly processResponse = (response: Response) => {
        if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
                AuthService.handleUnauthorizedResponse();

                return null;
            }

            throw response;
        }

        return response.json();
    };
}
