import React from 'react';
import { DateService, isEmail } from '../../../services';
import { MachineCollectionCreation } from '../../../models';
import { MachineCollectionType } from '../../../enums';
import { LANGUAGES, TRANSLATIONS } from '../../../constants';
import Checkbox from '../../common/Checkbox';
import DateInput from '../../common/DateInput';
import List, { ListItem } from '../../common/List';
import SpacingContainer from '../../common/SpacingContainer';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import TagInput from '../../common/TagInput';
import TextInput from '../../common/TextInput';

interface MachineCollectionGeneralFormProps {
    machineCollection: MachineCollectionCreation;
    onChange: (machineCollection: Partial<MachineCollectionCreation>) => Promise<void>;
}

const MachineCollectionGeneralForm = ({ machineCollection, onChange }: MachineCollectionGeneralFormProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const handleChange = (value: string, name: string) => {
        return onChange({ [name]: value });
    };

    const handleStartDateChange = (date: Date) => {
        return onChange({ exhibitorsStartDate: date });
    };

    const handleEndDateChange = (date: Date) => {
        return onChange({ exhibitorsEndDate: date });
    };

    const handleChiefEditorEmailAdd = (email: string) => {
        const { chiefEditorEmails } = machineCollection;

        onChange({ chiefEditorEmails: [...chiefEditorEmails, email] });
    };

    const handleChiefEditorEmailRemove = (email: string) => {
        const { chiefEditorEmails } = machineCollection;

        onChange({ chiefEditorEmails: chiefEditorEmails.filter(x => x !== email) });
    };

    const handleAgencyEmailAdd = (email: string) => {
        const { agencyUserEmails } = machineCollection;

        onChange({ agencyUserEmails: [...agencyUserEmails, email] });
    };

    const handleAgencyEmailRemove = (email: string) => {
        const { agencyUserEmails } = machineCollection;

        onChange({ agencyUserEmails: agencyUserEmails.filter(x => x !== email) });
    };

    const getStartDateError = (date: string) => {
        return DateService.getDayBeginning(new Date(date)) >= DateService.getDayBeginning(machineCollection.exhibitorsEndDate)
            ? translate(translations.error.dateStartBeforeEnd)
            : DateService.getDayBeginning(new Date(date)) < DateService.getDayBeginning(new Date(Date.now()))
                ? translate(translations.error.dateStartAfterToday)
                : '';
    };

    const getEndDateError = (date: string) => {
        return DateService.getDayBeginning(new Date(date)) <= DateService.getDayBeginning(machineCollection.exhibitorsStartDate)
            ? translate(translations.error.dateEndAfterStart)
            : '';
    };

    const isLanguageSelected = (language: string) => {
        const { availableLanguages } = machineCollection;

        return availableLanguages.some(x => x === language);
    };

    const selectLanguage = (language: string) => {
        if (isLanguageSelected(language)) {
            onChange({
                availableLanguages: machineCollection.availableLanguages.filter(x => x !== language),
                subtitle: { ...machineCollection.subtitle, [language]: null },
                description1: { ...machineCollection.description1, [language]: null },
                description2: { ...machineCollection.description2, [language]: null },
                imageDescription: { ...machineCollection.imageDescription, [language]: null }
            });
        } else {
            onChange({ availableLanguages: [...machineCollection.availableLanguages, language] });
        }
    };

    const getEmailErrorMessage = (value: string) => {
        return !value || isEmail(value) ? null : translate(translations.error.invalidFormat);
    };

    const renderForm = () => {
        return machineCollection && (
            <SpacingContainer>
                <TextInput dark={dark} label={translate(translations.common.title)} error={!machineCollection.title} value={machineCollection.title} name='title' 
                           onChange={handleChange} required />
                {machineCollection.type === MachineCollectionType.EBooklet &&
                    <>
                    <DateInput dark={dark} label={translate(translations.collection.startDateOfEdit)} 
                               error={getStartDateError(DateService.toDateString(machineCollection.exhibitorsStartDate)) !== ''} 
                               value={DateService.toDateString(machineCollection.exhibitorsStartDate)} onChange={x => handleStartDateChange(new Date(x))} 
                               getMessage={getStartDateError} />
                    <DateInput dark={dark} label={translate(translations.collection.endDateOfEdit)} 
                               error={getEndDateError(DateService.toDateString(machineCollection.exhibitorsEndDate)) !== ''} 
                               value={DateService.toDateString(machineCollection.exhibitorsEndDate)} onChange={x => handleEndDateChange(new Date(x))} 
                               getMessage={getEndDateError} />
                    </>
                }
                <TagInput dark={dark} error={!machineCollection.chiefEditorEmails.length} label={translate(translations.collection.chiefEditorEmails)} 
                          values={machineCollection.chiefEditorEmails} onAdd={handleChiefEditorEmailAdd} onRemove={handleChiefEditorEmailRemove} getMessage={getEmailErrorMessage} 
                          required />
                <TagInput dark={dark} label={translate(translations.collection.agencyEmails)} values={machineCollection.agencyUserEmails} onAdd={handleAgencyEmailAdd} 
                          onRemove={handleAgencyEmailRemove} getMessage={getEmailErrorMessage} />
                <List
                    highlighted={LANGUAGES.every(x => !isLanguageSelected(x))}
                    header={translate(translations.language.languages)}
                    items={LANGUAGES.map<ListItem>(x => ({
                        key: x,
                        content: <Checkbox dark={dark} checked={isLanguageSelected(x)} label={translate(translations.language[x as keyof typeof translations.language])} 
                                           onChange={() => selectLanguage(x)} />
                    }))}
                />
            </SpacingContainer>
        );
    };

    return renderForm();
};
export default MachineCollectionGeneralForm;
