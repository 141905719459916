import React, { RefObject, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentService, DateService, ProductService } from '../../../services';
import { Categories, Company, ProductCreation, ReleasedMachineCollection } from '../../../models';
import { TRANSLATIONS } from '../../../constants';
import CompanyFormWithSelection from '../company/CompanyFormWithSelection';
import ProductBasicForm from './ProductBasicForm';
import ProductCategoryForm from './ProductCategoryForm';
import ProductImageForm from './ProductImageForm';
import Banner, { BannerType } from '../../common/Banner';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Float from '../../common/Float';
import Icon, { IconType } from '../../common/Icon';
import KeyVisual from '../../common/KeyVisual';
import List, { ListItem } from '../../common/List';
import Modal from '../../common/Modal';
import Sticky from '../../common/Sticky';
import Title from '../../common/Title';
import Wizard from '../../common/Wizard';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import RadioButton from '../../common/RadioButton';
import Link from '../../common/Link';
import { WizardStep } from '../../../enums/WizardStep';

interface ProductWizardProps {
    accordionHeaderRefs: RefObject<HTMLDivElement>[];
    accordionsOpen: boolean[];
    categories: Categories;
    companies: Company[];
    image: File;
    imageUrl: string;
    machineCollections: ReleasedMachineCollection[];
    product: ProductCreation;
    readOnly: boolean;
    onAccordionToggle: (index: number) => void;
    onCompanyChange: (company: Partial<Company>) => void;
    onCompanySet: (company: Company) => Promise<void>;
    onFileReceived: (file: File) => void;
    onMachineCollectionsLoad: () => Promise<void>;
    onProductChange: (product: Partial<ProductCreation>) => void;
    onProductSave: (isIncomplete?: boolean) => Promise<string>;
}

const ProductWizard = ({ accordionHeaderRefs, accordionsOpen, categories, companies, image, imageUrl, machineCollections, product, readOnly,
    onAccordionToggle, onCompanyChange, onCompanySet, onFileReceived, onMachineCollectionsLoad, onProductChange, onProductSave }: ProductWizardProps) => {
    const MAX_LENGTH = 1000;
    const dark = useAppSelector(state => state.layout.dark);
    const navigate = useNavigate();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [isSaving, setIsSaving] = useState(false);
    const [machineCollectionId, setMachineCollectionId] = useState(null);
    const [productId, setProductId] = useState(null);
    const [shouldDisplayReleaseModal, setShouldDisplayReleaseModal] = useState(false);
    const [shouldDisplaySaveDialog, setShouldDisplaySaveDialog] = useState(false);
    const [wizardStep, setWizardStep] = useState(0);

    const handleSubmit = async () => {
        if (wizardStep < WizardStep.BasicInformation) {
            setWizardStep(wizardStep + 1);
        } else {
            await submitWizard();
        }
    };

    const handleStepClick = (step: number) => {
        setWizardStep(step);
    };

    const submitWizard = async () => {
        const machineCollectionPromise = onMachineCollectionsLoad();
        const productId = await onProductSave();

        if (productId) {
            await machineCollectionPromise;

            setProductId(productId);
            setShouldDisplayReleaseModal(true);
        }
    };

    const closeWizard = async () => {
        setShouldDisplayReleaseModal(false);
        navigate('/products');
    };

    const redirectToRelease = async () => {
        setShouldDisplayReleaseModal(false);
        navigate(`/productreleasewizard/${productId}?collectionid=${machineCollectionId}`);
    };

    const openSaveDialog = async (isSaving: boolean) => {
        if (readOnly) {
            navigate('/products');
        } else {
            setShouldDisplaySaveDialog(true);
            setIsSaving(isSaving);
        }
    };

    const closeSaveDialog = () => {
        setShouldDisplaySaveDialog(false);
        setIsSaving(false);
    };

    const submitSaveDialog = async () => {        
        await onProductSave(true);
        navigate('/products');
    };

    const isValid = () => {
        switch (wizardStep) {
            case WizardStep.Company:
                return ProductService.isCompanyValid(product);
            case WizardStep.Image:
                return Boolean((product && product.imageId) || image);
            case WizardStep.Category:
                return product && product.categoryGroups.every(x => Boolean(x.categories.length));
            case WizardStep.BasicInformation:
                return product && product.title && ContentService.isValid(product.description.en, MAX_LENGTH);
            default:
                return true;
        }
    };

    const renderSaveDialog = () => {
        return shouldDisplaySaveDialog && (
            <Modal dark={dark} open={shouldDisplaySaveDialog}>
                <Title style={{ marginBottom: '10px' }} text={translate(translations.dialog.saveItem)} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    {!companies || ProductService.isCompanyValid(product) &&
                        <Link dark={dark} text={translate(translations.action.yes)} onClick={submitSaveDialog} />
                    }
                    {!isSaving &&
                        <Link dark={dark} text={translate(translations.action.no)} onClick={() => navigate('/products')} />
                    }
                    <Link dark={dark} text={translate(translations.action.cancel)} onClick={closeSaveDialog} />
                </Flex>
            </Modal>
        );
    };

    const renderReleaseModal = () => {
        return shouldDisplayReleaseModal && (
            <Modal open={true}>
                <Title style={{ marginBottom: 30 }} text={translate(translations.dialog.releaseQuestion)} bold />
                <List items={[
                    ...machineCollections.map<ListItem>(x => ({
                        key: x.id,
                        content: <RadioButton selected={x.id === machineCollectionId} label={x.title} onChange={() => setMachineCollectionId(x.id)} />
                    }))
                ]} />
                <Flex direction={FlexDirection.Row} style={{ marginTop: 30 }} justification={FlexJustification.FlexEnd}>
                    <Button type={ButtonType.Secondary} onClick={closeWizard}>
                        <Icon type={IconType.Close} />
                        {translate(translations.action.cancel)}
                    </Button>
                    <Button type={ButtonType.Primary} disabled={!productId || !machineCollectionId} onClick={redirectToRelease}>
                        <Icon type={IconType.Check} />
                        {translate(translations.action.release)}
                    </Button>
                </Flex>
            </Modal>
        );
    };

    const renderKeyVisual = () => {
        return (
            <KeyVisual height={170} url='images/key-visual.jpg'>
                {!isValid() &&
                    <Flex direction={FlexDirection.Column} justification={FlexJustification.FlexEnd}>
                        <Sticky distance={60} anchor={'top'} >
                            <Banner type={BannerType.Warning} text={translate(translations.dialog.fillMandatory)} />
                        </Sticky>
                    </Flex>
                }
            </KeyVisual>
        );
    };

    const renderWizard = () => {
        const stepNames: string[] = [translate(translations.company.company),
            translate(translations.common.image),
            translate(translations.category.category), 
            translate(translations.common.info)];

        return <Wizard step={wizardStep} stepNames={stepNames} onStepClick={handleStepClick} />;
    };

    const renderForm = () => {
        const forms: JSX.Element[] = [
            <CompanyFormWithSelection company={product ? product.company : null} product={product} companies={companies} readOnly={readOnly} onChange={onCompanyChange} 
                                      onProductChange={onProductChange} onSet={onCompanySet} />,
            <ContentContainer>
                <ProductImageForm image={image} imageUrl={imageUrl} product={product} readOnly={readOnly} onFileReceived={onFileReceived} />
            </ContentContainer>,
            <ProductCategoryForm accordionHeaderRefs={accordionHeaderRefs} accordionsOpen={accordionsOpen} categories={categories} product={product} readOnly={readOnly}
                onAccordionToggle={onAccordionToggle} onChange={onProductChange} />,
            <ContentContainer>
                <ProductBasicForm imageUrl={imageUrl} product={product} readOnly={readOnly} onChange={onProductChange} />
            </ContentContainer>
        ];

        return forms[wizardStep];
    };

    const renderButtons = () => {
        return product && (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                <Float key={`${wizardStep}-cancel`} bottom={50}>
                    <Button type={ButtonType.Secondary} onClick={() => openSaveDialog(false)}>
                        <Icon type={IconType.Close} />
                        {translate(translations.action.cancel)}
                    </Button>
                </Float>
                {!readOnly &&
                    <Float key={`${wizardStep}-save`} bottom={50}>
                        <Button type={ButtonType.Secondary} onClick={() => openSaveDialog(true)}>
                            <Icon type={IconType.Folder} />
                            {translate(translations.action.save)}
                        </Button>
                    </Float>
                }
                {(!readOnly || wizardStep < WizardStep.BasicInformation) &&
                    <Float key={`${wizardStep}-submit`} bottom={50}>
                        <Button type={ButtonType.Primary} disabled={!isValid()} onClick={handleSubmit}>
                            <Icon type={isValid() ? IconType.Check : IconType.Warning} />
                            {translate(wizardStep < WizardStep.BasicInformation ? translations.action.nextStep : translations.action.save)}
                        </Button>
                    </Float>
                }
            </Flex>
        );
    };

    const renderLabels = () => {
        const machineName = product && product.title ? product.title : translate(translations.product.newMachine);
        const date = product && product.createdDate ? product.createdDate : new Date(Date.now());
        const title = readOnly ? machineName : `${machineName}, created on ${DateService.toString(date)}`;
        const companyName = product && product.company && product.company.name ? product.company.name : translate(translations.company.noCompany);

        return (
            <>
                <Title style={{ fontSize: 24 }} text={title} bold />
                <Title style={{ fontSize: 20, marginBottom: 30 }} text={companyName} />
            </>
        );
    };

    const renderPage = () => {
        return (
            <>
                {renderKeyVisual()}
                <ContentContainer style={{ marginTop: 30, marginBottom: 30 }}>
                    {renderLabels()}
                    {renderWizard()}
                </ContentContainer>
                {renderForm()}
                <ContentContainer style={{ marginTop: 30, marginBottom: 50 }}>
                    {renderButtons()}
                </ContentContainer>
                {renderSaveDialog()}
                {renderReleaseModal()}
            </>
        );
    };

    return renderPage();
};
export default ProductWizard;
