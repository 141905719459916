import { areIntersecting, flat } from '.';
import { Categories, CategoryGroup, CategorySubgroup, MachineCollectionCreationCategoryGroup, ProductCategoryGroup, ProductCreation, ReleasedMachineCollection } from '../models';
import { CategoryGroupRule, CategorySubgroupRule } from '../enums';
import { IconType } from '../components/common/Icon';

export class CategoryService {
    static getAllCollectionCategoryIds = (collection: ReleasedMachineCollection, categoryGroupId: number) =>
        flat(collection.categoryGroups.find(x => x.groupId === categoryGroupId).subgroups.map(x => x.categoryIds));

    static getAllProductCategoryIds = (product: ProductCreation, categoryGroupId: number) => product.categoryGroups.find(x => x.groupId === categoryGroupId).categories.map(x => x.id);

    static getCollectionCategoryGroups = (categories: Categories) => categories.groups.map<MachineCollectionCreationCategoryGroup>(x => ({ groupId: x.content.id, subgroups: [] }));

    static getFilteredCategories = (categories: Categories, machineCollection: ReleasedMachineCollection): Categories => ({
        groups: categories.groups.filter(x => machineCollection.categoryGroups.some(y => y.groupId === x.content.id)).map(x => CategoryService.filterGroup(x, machineCollection))
    });

    static getFilteredProductCategoryGroups = (categoryGroups: ProductCategoryGroup[], machineCollection: ReleasedMachineCollection): ProductCategoryGroup[] => {
        return categoryGroups.filter(x => machineCollection.categoryGroups.some(y => y.groupId === x.groupId))
            .map(x => ({ ...x, categories: x.categories.filter(y => CategoryService.getAllCollectionCategoryIds(machineCollection, x.groupId).some(z => z === y.id)) }));
    };

    static getIconType = (index: number) => {
        let iconType: IconType = null;

        switch (index) {
            case 0:
                iconType = IconType.Settings;
                break;
            case 1:
                iconType = IconType.BrowseAll;
                break;
            case 2:
                iconType = IconType.Information;
                break;
            case 3:
                iconType = IconType.Apps;
                break;
            default:
                break;
        }

        return iconType;
    };

    static getSubgroupRule = (categoryGroup: CategoryGroup, categorySubgroup: CategorySubgroup, machineCollection: ReleasedMachineCollection) =>
        machineCollection
            ? machineCollection.categoryGroups.find(y => y.groupId === categoryGroup.content.id).subgroups.find(y => y.subgroupId === categorySubgroup.content.id).rule
            : CategorySubgroupRule.NoRule;

    static getProductCategoryGroups = (categories: Categories) => categories.groups.map<ProductCategoryGroup>(x => ({ groupId: x.content.id, groupContent: x.content, groupRule: x.rule, categories: [] }));

    static isGroupValid = (categoryGroup: CategoryGroup, machineCollection: ReleasedMachineCollection, product: ProductCreation) => {
        const selectedSubgroups = categoryGroup.subgroups.filter(x => areIntersecting(x.categories.map(y => y.content.id), CategoryService.getAllProductCategoryIds(product, categoryGroup.content.id)));

        let isValid = false;

        switch (categoryGroup.rule) {
            case CategoryGroupRule.ExactlyOne:
                isValid = selectedSubgroups.length === 1 && categoryGroup.subgroups.every(x => CategoryService.isSubgroupValid(categoryGroup, x, machineCollection, product));
                break;
            case CategoryGroupRule.OneOrMore:
                isValid = selectedSubgroups.length > 0 && categoryGroup.subgroups.every(x => CategoryService.isSubgroupValid(categoryGroup, x, machineCollection, product));
                break;
            default:
                break;
        }

        return isValid;
    };

    static isSubgroupValid = (categoryGroup: CategoryGroup, categorySubgroup: CategorySubgroup, machineCollection: ReleasedMachineCollection, product: ProductCreation) => {
        let isValid = false;
        let selectedCategories;

        switch (CategoryService.getSubgroupRule(categoryGroup, categorySubgroup, machineCollection)) {
            case CategorySubgroupRule.ExactlyOne:
                selectedCategories = product.categoryGroups.find(y => y.groupId === categoryGroup.content.id).categories.filter(y => categorySubgroup.categories.some(z => z.content.id === y.id));

                isValid = categoryGroup.rule === CategoryGroupRule.ExactlyOne || selectedCategories.length === 1;
                break;
            case CategorySubgroupRule.NoRule:
            case CategorySubgroupRule.PrimarySecondary:
                isValid = true;
                break;
            default:
                break;
        }

        return isValid;
    };

    private static readonly filterGroup = (categoryGroup: CategoryGroup, collection: ReleasedMachineCollection): CategoryGroup => ({
        ...categoryGroup,
        subgroups: categoryGroup.subgroups.map(x => ({
            ...x,
            categories: x.categories.filter(y => CategoryService.getAllCollectionCategoryIds(collection, categoryGroup.content.id).some((z: number) => z === y.content.id))
        })).filter(x => x.categories.length)
    });
}
