import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deepCopy, FilterService } from '../services';
import { Filter, MachineCollection, Product, RequestFilter } from '../models';
import { EMPTY_FILTER } from '../constants';

export interface AppState {
    availableFilters: RequestFilter;
    dropdownProducts: Partial<Product>[];
    filter: Filter;
    firstVisit: boolean;
    isLoading: boolean;
    machineCollection: MachineCollection;
    showDefaultCompanyForm: boolean;
    showInitialBanner: boolean;
}

const initialState: AppState = {
    availableFilters: FilterService.createRequestFilter(EMPTY_FILTER),
    dropdownProducts: [],
    filter: deepCopy(EMPTY_FILTER),
    firstVisit: window.localStorage.getItem('firstVisit')
        ? window.localStorage.getItem('firstVisit') === 'true'
        : true,
    isLoading: false,
    machineCollection: null,
    showDefaultCompanyForm: false,
    showInitialBanner: false
};

export const AppSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAvailableFilters: (state: AppState, action: PayloadAction<RequestFilter>) => {
            state.availableFilters = action.payload;
        },
        setDropdownProducts: (state: AppState, action: PayloadAction<Partial<Product>[]>) => {
            state.dropdownProducts = action.payload;
        },
        setFilter: (state: AppState, action: PayloadAction<Filter>) => {
            state.filter = action.payload;
        },
        setFirstVisit: (state: AppState, action: PayloadAction<boolean>) => {
            state.firstVisit = action.payload;
        },
        setLoading: (state: AppState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setMachineCollection: (state: AppState, action: PayloadAction<MachineCollection>) => {
            state.machineCollection = action.payload;
        },
        toggleDefaultCompanyForm: (state: AppState) => {
            state.showDefaultCompanyForm = !state.showDefaultCompanyForm;
        },
        toggleShowInitialBanner: (state: AppState) => {
            state.showInitialBanner = !state.showInitialBanner;
        }
    }
});

export const { setAvailableFilters, setDropdownProducts, setFilter, setLoading, setMachineCollection, setFirstVisit, toggleShowInitialBanner, toggleDefaultCompanyForm } = AppSlice.actions;
export const appReducers = AppSlice.reducer;
