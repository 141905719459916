import React, { KeyboardEvent } from 'react';

interface ToggleProps {
    on: boolean;
    onChange: (value: boolean) => void;
    dark?: boolean;
    disabled?: boolean;
}

const Toggle = ({ dark, disabled, on, onChange }: ToggleProps) => {
    const handleChange = () => {
        if (!disabled) {
            onChange(!on);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === ' ') {
            event.preventDefault();
            handleChange();
        }
    };

    return (
        <div className={`toggle ${on ? 'on' : ''} ${disabled ? 'disabled' : ''} ${dark ? 'dark' : ''}`} tabIndex={disabled ? undefined : 0} onClick={handleChange} onKeyDown={handleKeyDown} />
    );
};

export default Toggle;
