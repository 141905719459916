import { DateService, isEmail, isPhone } from '.';
import { EMPTY_CONTENT } from '../constants';
import { Company, Product, ProductCreation } from '../models';

export class ProductService {
    static addRepresentativeToCompany = (company: Company, email: string): Company => ({ ...company, siemensRepresentativeEmails: [email] });

    static convertDates = (product: Product): Product => ({
        ...product,
        createdDate: DateService.fromString(product.createdDate),
        lastChangeDate: DateService.fromString(product.lastChangeDate),
        releaseDate: DateService.fromString(product.releaseDate),
        deletedDate: DateService.fromString(product.deletedDate),
        customReleaseDate: DateService.fromString(product.customReleaseDate)
    });

    static getLink = (collectionId: string, productId: string) => `/machine-finder/${collectionId}/product/${productId}`;

    static isCompanyValid = (product: ProductCreation) => product && product.company && product.company.name && product.company.street1 && product.company.zip 
        && product.company.zip.length <= 12 && product.company.city && product.company.country && (!product.company.phoneNumber || isPhone(product.company.phoneNumber)) 
        && (!product.company.email || isEmail(product.company.email)) && product.company.siemensRepresentativeEmails.every(x => isEmail(x));

    static map = (product: Product): ProductCreation => ({
        id: product.id,
        machineCollectionId: product.machineCollectionId,
        companyId: product.companyId,
        company: product.company,
        title: product.title,
        description: product.description,
        secondDescription: product.secondDescription ? product.secondDescription : EMPTY_CONTENT,
        imageId: product.imageId,
        customReleaseDate: null,
        createdDate: DateService.fromString(product.createdDate),
        hallId: product.hallId,
        booth: product.booth,
        categoryGroups: product.categoryGroups,
        comments: product.comments,
        tags: product.tags,
        isVisibleForSiemensRepresentative: product.isVisibleForSiemensRepresentative,
        customerApproverEmail: product.customerApproverEmail
    });

    static reviewMap = (product: Product): ProductCreation => ({
        id: product.id,
        machineCollectionId: product.machineCollectionId,
        companyId: product.companyId,
        company: product.company,
        title: product.title,
        description: product.description,
        secondDescription: product.secondDescription ? product.secondDescription : EMPTY_CONTENT,
        imageId: product.imageId,
        customReleaseDate: DateService.fromString(product.customReleaseDate),
        createdDate: DateService.fromString(product.createdDate),
        hallId: product.hallId,
        booth: product.booth,
        categoryGroups: product.categoryGroups,
        comments: product.comments,
        tags: product.tags,
        isVisibleForSiemensRepresentative: product.isVisibleForSiemensRepresentative,
        customerApproverEmail: product.customerApproverEmail
    });
}
