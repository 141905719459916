import React from 'react';
import { useDispatch } from 'react-redux';
import { ContentService } from '../../../services';
import { Filter, MachineCollection } from '../../../models';
import { TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { TagProps } from '../../common/Tag';
import TagBar from '../../common/TagBar';
import { SectionColor } from '../../common/Section';
import { setFilter } from '../../../store';

interface ChipsLaneManagerProps {
    machineCollection: MachineCollection;
    onFilterProducts?: (reset: boolean, tempFilter?: Filter) => void;
}

const ChipsLaneManager = ({ machineCollection, onFilterProducts }: ChipsLaneManagerProps) => {
    const activeLanguage = useAppSelector(state => state.translation.language);
    const dark = useAppSelector(state => state.layout.dark);
    const filter = useAppSelector(state => state.app.filter);

    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const createChipProps = (_id: string, name: string, isDisabled: boolean, handleOnClick: () => void): TagProps => ({
        text: name,
        disabled: isDisabled,
        onClick: handleOnClick,
        close: true
    });

    const getChipsProps = (): TagProps[] => {
        const chipsProps: TagProps[] = [];
        
        chipsProps.push(...filter.companyNames.map(x => createChipProps(
            `company${x}`,
            x,
            false,
            () => onFilterChange({ companyNames: filter.companyNames.filter(y => y !== x), shouldFilter: true })
        )));

        chipsProps.push(...filter.hallIds.map(x => machineCollection.halls.find(y => y.id === x)).map(x => createChipProps(
            `hall${x.id}`,
            `${translate(translations.hall.hall)} ${x.name}`,
            false,
            () => onFilterChange({ hallIds: filter.hallIds.filter(y => y !== x.id), shouldFilter: true })
        )));

        machineCollection.categoryGroups.forEach(x => {
            chipsProps.push(...filter[x.id].map(y => x.categories.find(z => z.id === y.id) || x.primaryCategories.find(z => z.id === y.id)).map(y => createChipProps(
                `${x.id}+${y.id}`,
                ContentService.translate(y.content, activeLanguage),
                false,
                () => onFilterChange({ [x.id]: filter[x.id].filter(v => v.id !== y.id), shouldFilter: true })
            )));
        });

        chipsProps.push(...filter.countries.map(x => createChipProps(
            `country${x}`,
            translate(translations.countries[x.toLowerCase() as keyof typeof translations.countries]),
            false,
            () => onFilterChange({ countries: filter.countries.filter(y => y !== x), shouldFilter: true })
        )));

        chipsProps.push(...filter.tags.map(x => machineCollection.tags.find(y => y.id === x.id)).map(x => createChipProps(
            `tag${x.id}`,
            x.name,
            false,
            () => onFilterChange({ tags: filter.tags.filter(y => y.id !== x.id), shouldFilter: true })
        )));

        return chipsProps;
    };

    const onFilterChange = async (newFilter: Partial<Filter>) => {
        const tempFilter = { ...filter, ...newFilter };
        
        dispatch(setFilter(tempFilter));
        onFilterProducts(false, tempFilter);
    };

    const renderChipsLaneManager = () => {
        return <TagBar backgroundColor={dark ? SectionColor.DeepBlue : SectionColor.None} tags={machineCollection ? getChipsProps() : []} />;
    };

    return renderChipsLaneManager();
};
export default ChipsLaneManager;
