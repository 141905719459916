import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api, apiCall } from '../../../services';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';
import { ServiceResponse, Content } from '../../../models';
import { HeaderTabType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import CategoryForm from './CategoryForm';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Loader from '../../common/Loader';
import { SnackbarType } from '../../common/Snackbar';

const CategoryManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [accordionsOpen, setAccordionsOpen] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(setActiveTabType(HeaderTabType.Categories));
        loadCategories();
    }, []);

    const loadCategories = async () => {
        const startingUrl = window.location.pathname;

        dispatch(setLoading(true));

        await apiCall(
            Api.getCategoriesWithUsage(),
            async x => {
                setAccordionsOpen(accordionsOpen ? accordionsOpen : Array<boolean>(x.data.groups.length).fill(true));
                setCategories(x.data);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.categoryLoad), type: SnackbarType.Error, dark: dark }));
            }
        );

        if (startingUrl === window.location.pathname) {
            dispatch(setLoading(false));
        }
    };

    const handleCategoryAdd = async (parentId: number, content: Content) => {
        await categoryManipulationApiCall(Api
            .saveCategory({ parentId, content }), translate(translations.dialog.successfulSave), translate(translations.error.categorySave));
    };

    const handleCategorySubgroupAdd = async (parentId: number, content: Content) => {
        await categoryManipulationApiCall(Api
            .saveCategorySubgroup({ parentId, content }), translate(translations.dialog.successfulSave), translate(translations.error.categorySave));
    };

    const handleCategoryChange = async (content: Content, obsolete: boolean) => {
        await categoryManipulationApiCall(Api.updateCategory(content, obsolete), translate(translations.dialog.successfulSave), translate(translations.error.categorySave));
    };

    const handleCategorySubgroupChange = async (content: Content) => {
        await categoryManipulationApiCall(Api.updateCategorySubgroup(content), translate(translations.dialog.successfulSave), translate(translations.error.categorySave));
    };    
    
    const handleCategoryGroupChange = async (content: Content) => {
        await categoryManipulationApiCall(Api.updateCategoryGroup(content), translate(translations.dialog.successfulSave), translate(translations.error.categorySave));
    };

    const handleCategoryDelete = async (id: number) => {
        await categoryManipulationApiCall(Api.deleteCategory(id), translate(translations.dialog.successfulDelete), translate(translations.error.categoryDelete));
    };

    const handleCategorySubgroupDelete = async (id: number) => {
        await categoryManipulationApiCall(Api.deleteCategorySubgroup(id), translate(translations.dialog.successfulDelete), translate(translations.error.categoryDelete));
    };

    const handleAccordionToggle = (index: number) => setAccordionsOpen(accordionsOpen.map((x: boolean, i: number) => i === index ? !x : x));

    const categoryManipulationApiCall = async <T extends ServiceResponse>(responsePromise: Promise<T>, successMessage: string, errorMessage: string) => {
        dispatch(setLoading(true));

        await apiCall(
            responsePromise,
            async () => {
                dispatch(addSnackbar({ text: successMessage, type: SnackbarType.Success, dark: dark }));
                await loadCategories();
            },
            async () => {
                dispatch(addSnackbar({ text: errorMessage, type: SnackbarType.Error, dark: dark }));
            }
        );

        dispatch(setLoading(false));
    };

    const renderCategory = () => {
        return (
            <>
                <div className={`compass-section ${dark ? 'dark' : ''}`}>
                    <Loader dark={dark} loading={isLoading}>
                        <CategoryForm accordionsOpen={accordionsOpen} categories={categories} onAccordionToggle={handleAccordionToggle} onCategoryAdd={handleCategoryAdd}
                            onCategoryChange={handleCategoryChange} onCategoryDelete={handleCategoryDelete} onCategorySubgroupAdd={handleCategorySubgroupAdd}
                            onCategorySubgroupChange={handleCategorySubgroupChange} onCategorySubgroupDelete={handleCategorySubgroupDelete} onCategoryGroupChange={handleCategoryGroupChange}
                        />
                    </Loader>
                </div>
            </>
        );
    };

    return renderCategory();
};
export default CategoryManager;
