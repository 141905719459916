import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api, apiCall } from '../../../services';
import { Tag } from '../../../models';
import { HeaderTabType } from '../../../enums';
import { EMPTY_GUID, TRANSLATIONS } from '../../../constants';
import TagTable from './TagTable';
import Loader from '../../common/Loader';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const TagTableManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const dispatch = useDispatch();
    const isLoading = useAppSelector(state => state.app.isLoading);
    const controller = new AbortController(); 
    const signal = controller.signal;
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [tags, setTags] = useState([]);

    useEffect(() => {
        dispatch(setActiveTabType(HeaderTabType.Tags));

        window.scrollTo(0, 0);

        loadTags();

        return () => {
            controller.abort();
            Api.cancelTagRequest();
        };
    }, []);

    const loadTags = async () => {
        const startingUrl = window.location.pathname;

        dispatch(setLoading(true));

        await apiCall(
            Api.getTags(signal),
            async x => {
                setTags(x.data);
            },
            async () => {
                if (!signal.aborted) {
                    dispatch(addSnackbar({ text: translate(translations.error.tagsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            }
        );

        if (startingUrl === window.location.pathname) {
            dispatch(setLoading(false));
        }
    };

    const handleTagSave = async (tag: Tag) => {
        dispatch(setLoading(true));

        await apiCall(
            tag.id !== EMPTY_GUID ? Api.updateTag(tag) : Api.createTag(tag),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulSave).toString(), type: SnackbarType.Success, dark: dark }));
                dispatch(setLoading(false));
                await loadTags();
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.tagSave).toString(), type: SnackbarType.Error, dark: dark }));
                dispatch(setLoading(false));
            }
        );
    };
    const handleTagDelete = async (id: string) => {
        dispatch(setLoading(true));

        await apiCall(
            Api.deleteTag(id),
            async () => {
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulDelete).toString(), type: SnackbarType.Success, dark: dark }));
                dispatch(setLoading(false));
                await loadTags();
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.tagDelete).toString(), type: SnackbarType.Error, dark: dark }));
                dispatch(setLoading(false));
            }
        );
    };

    const renderTable = () => {
        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                <Loader dark={dark} loading={isLoading}>
                    <TagTable tags={tags} onTagSave={handleTagSave} onTagDelete={handleTagDelete} />
                </Loader>
            </div>
        );
    };

    return renderTable();
};
export default TagTableManager;
