import React from 'react';
import { isEmail } from '../../../services';
import { Company } from '../../../models';
import { TRANSLATIONS } from '../../../constants';
import CompanyForm from './CompanyForm';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Float from '../../common/Float';
import Icon, { IconType } from '../../common/Icon';
import Modal from '../../common/Modal';
import Title from '../../common/Title';
import { useAppSelector, useTranslate } from '../../../hooks/common';

interface DefaultCompanyProps {
    company: Company;
    onChange: (company: Partial<Company>) => void;
    onSubmit: () => Promise<void>;
}

const DefaultCompany = ({ company, onChange, onSubmit }: DefaultCompanyProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const isValid = () => {
        return company && company.name && company.street1 && company.zip && company.city && company.country && (!company.email || isEmail(company.email));
    };

    const renderButton = () => {
        return (
            <Flex direction={FlexDirection.Row} style={{ marginTop: 30 }} justification={FlexJustification.FlexEnd}>
                <Float bottom={50}>
                    <Button type={ButtonType.Primary} disabled={!isValid()} onClick={onSubmit}>
                        <Icon type={isValid() ? IconType.Check : IconType.Warning} />
                        {translate(translations.action.save)}
                    </Button>
                </Float>
            </Flex>
        );
    };

    const renderModal = () => {
        return (
            <Modal dark={dark} open={true}>
                <Title style={{ marginBottom: 30 }} text={translate(translations.company.createCompany)} uppercase bold />
                <CompanyForm company={company} onChange={onChange} />
                {renderButton()}
            </Modal>
        );
    };

    return renderModal();
};
export default DefaultCompany;
