import React, { KeyboardEvent, ReactText } from 'react';
import Icon, { IconType } from './Icon';

interface LinkProps {
    text: ReactText;
    onClick: () => void;
    dark?: boolean;
    icon?: IconType;
}

const Link = ({ text, dark, icon, onClick }: LinkProps) => {
    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onClick();
        }
    };

    return (
        <div className={`link ${dark ? 'dark' : ''}`} tabIndex={0} onClick={onClick} onKeyDown={handleKeyDown}>
            {text}
            {icon &&
                <Icon type={icon} />
            }
        </div>
    );
};

export default Link;
