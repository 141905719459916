import { MaintenanceOperation } from '../models';
import { MaintenanceOperationType } from '../enums/MaintenanceOperationType';
import { EMPTY_GUID } from './EmptyGuid';

export const DEFAULT_MAINTENANCE_OPERATION: MaintenanceOperation = {
    id: EMPTY_GUID,
    adminEmail: '', 
    affectedCompanyName: '', 
    newResponsibleEmail: '', 
    oldResponsibleEmail: '', 
    type: MaintenanceOperationType.ReplaceMachineCreator
};
