import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addSnackbar, setActiveTabType, setAvailableFilters, setDropdownProducts, setFilter, setFirstVisit, setLoading, setMachineCollection,
    toggleDefaultCompanyForm } from '../../../store';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { Api, apiCall, AuthService, CookieService, deepCopy, FilterService, flat, TrackingService, unique } from '../../../services';
import { Company, Content, Favourite, Filter, MachineCollection, Product, ProductCategory, RequestFilter, ServiceDataResponse, Tag } from '../../../models';
import { HeaderTabType, LandingPageOrderType } from '../../../enums';
import { DEFAULT_MACHINE_COLLECTION_ID, EMPTY_FILTER, ROLES, TRANSLATIONS } from '../../../constants';
import MachineCollectionView from './MachineCollectionView';
import DefaultCompanyManager from '../company/DefaultCompanyManager';
import Loader from '../../common/Loader';
import { SnackbarType } from '../../common/Snackbar';


const MachineCollectionViewManager = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const translate = useTranslate();
    const dark = useAppSelector(state => state.layout.dark);
    const filter = useAppSelector(state => state.app.filter);
    const firstVisit = useAppSelector(state => state.app.firstVisit);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const machineCollection = useAppSelector(state => state.app.machineCollection);
    const translations = TRANSLATIONS;

    useEffect(() => {
        const storedYScroll = window.sessionStorage.getItem('yScroll');
        window.scrollTo(0, storedYScroll ? parseInt(storedYScroll, 10) : 0);
        dispatch(setActiveTabType(HeaderTabType.MachineCollection));
        
        if (machineCollection && machineCollection.title){
            TrackingService.Track('page.ready', window.location.pathname, machineCollection.title);
        }

        (async () => {
            const startingUrl = window.location.pathname;

            dispatch(setLoading(true));

            const hasRights = AuthService.isAuthenticated() && !AuthService.hasRole([ROLES.SIEMENS_REPRESENTATIVE]);
            let availableFilters: RequestFilter;

            if (hasRights){
                (async () => {
                    let companyResponse: ServiceDataResponse<Company>;

                    try { 
                        companyResponse = await Api.getCompanyForUser(); 
                    } 
                    catch {
                        dispatch(addSnackbar({ text: translate(translations.error.companyLoad), type: SnackbarType.Error, dark: dark }));
                    }

                    if (companyResponse && companyResponse.hasError && companyResponse.errorCode === 1204) {
                        dispatch(toggleDefaultCompanyForm());
                    }
                })();
            }

            if (!machineCollection || machineCollection.id !== id) {
                let favourites: Favourite[] = [];
                const storedFavorites = window.localStorage.getItem('storedFavourites');

                const favouritesPromise = AuthService.isAuthenticated()
                    ? apiCall(
                        Api.getFavouriteProducts(id),
                        async x => {
                            favourites = x.data.map<Favourite>(y => ({ id: y.id, title: y.title, companyName: y.company.name }));
                        },
                        async () => {
                            dispatch(addSnackbar({ text: translate(translations.error.favouritesLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        }
                    )
                    : null;
                
                if (!favouritesPromise && storedFavorites){
                    const parsedFavourites = JSON.parse(storedFavorites);
                    
                    if (parsedFavourites){
                        favourites = parsedFavourites as Favourite[];
                    }
                }
                
                const quickProductsPromise = apiCall(
                    Api.getQuickSearchProductsForMachineCollection(id),
                    async x => {
                        const dropdownProducts = Object.entries(x.data)
                            .map<Partial<Product>>(x => ({ id: x[0], title: x[1] }))
                            .sort((y, z) => y.title > z.title ? 1 : -1);
                        
                        dispatch(setDropdownProducts(dropdownProducts));
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.collectionLoad).toString(), type: SnackbarType.Error, dark: dark }));
                });

                await apiCall(
                    Api.getMachineCollection(id),
                    async x => {
                        const machineCollection = x.data;

                        TrackingService.Track('page.ready', window.location.pathname, machineCollection.title);

                        const cookieMonster = CookieService.getCookieMonster();
                        
                        if (cookieMonster && firstVisit){
                            cookieMonster.once('interacted', function() {
                                dispatch(addSnackbar({ 
                                    text: translate(translations.common.landingPageSnackbar), 
                                    type: SnackbarType.Info, 
                                    dark: dark, 
                                    duration: 10000 }));
                            });
                            dispatch(setFirstVisit(false));
                        }
                        
                        let filter = deepCopy(EMPTY_FILTER);
                        
                        /*if (machineCollection.type === MachineCollectionType.EBooklet) {
                            machineCollection.halls = unique(machineCollection.products.map(y => y.hall).filter(y => y !== null), y => y.id);
                        }*/
                        
                        if (AuthService.isAuthenticated()) {
                            await favouritesPromise;
                        }
                        
                        filter.favourites = favourites;
                        
                        dispatch(setFilter(filter));
                        
                        const sortByType = window.localStorage.getItem('sortByType') 
                            ? parseInt(window.localStorage.getItem('sortByType'), 10)
                            : LandingPageOrderType.DateDescending;
                        
                        await apiCall(
                            Api.filterMachineCollectionProducts(id, FilterService.createRequestFilter(filter, [], sortByType)),
                            async x => {
                                machineCollection.products = x.data.filteredProducts;
                                availableFilters = x.data.requestFilter;

                                machineCollection.categoryGroups = availableFilters.availableCategories.map(y => ({
                                    id: y.groupId,
                                    content: y.groupContent,
                                    rule: y.groupRule,
                                    categories: unique(flat(availableFilters.availableCategories.filter(w => w.groupId == y.groupId).map(x => x.categories)).filter(z => z !== null && !z.isPrimary), z => z.id),
                                    primaryCategories: unique(flat(availableFilters.availableCategories.filter(w => w.groupId == y.groupId).map(x => x.categories)).filter(z => z !== null && z.isPrimary), z => z.id)
                                }));
                                
                                const newFilter = deepCopy(filter);

                                machineCollection.categoryGroups.forEach(y => newFilter[y.id] = []);
                                
                                machineCollection.tags = availableFilters.tags;

                                if (location.search) {
                                    filter = await getFilterFromLink(machineCollection, filter.favourites, availableFilters);
                                    
                                    await filterProducts(false, filter, machineCollection);
                                }
                                else {
                                    dispatch(setFilter(newFilter));
                                    dispatch(setMachineCollection(machineCollection));
                                    dispatch(setAvailableFilters(availableFilters));
                                }
                            },
                            async () => {
                                dispatch(addSnackbar({ text: translate(translations.error.productLoad).toString(), type: SnackbarType.Error, dark: dark }));
                            }
                        );
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.collectionLoad).toString(), type: SnackbarType.Error, dark: dark }));
                    }
                );
                
                await quickProductsPromise;
            }

            if (startingUrl === window.location.pathname) {
                dispatch(setLoading(false));
            }

            navigate({
                pathname: window.location.pathname,
                search: ''
            });
        })();
    }, []);
    
    const getCompanyNameByLinkFilter = (companyName: string, availableCompanies: string[]) => availableCompanies.find(x => x.replace(/ /g, '-') === companyName) ?? '';
    
    const getNextProducts = async (offset: number) => {
        dispatch(setLoading(true));

        const sortByType = window.localStorage.getItem('sortByType')
            ? parseInt(window.localStorage.getItem('sortByType'), 10)
            : LandingPageOrderType.DateDescending;
        
        const requestFilter = FilterService.createRequestFilter(filter, machineCollection.categoryGroups, sortByType, offset);
        
        await apiCall(
            Api.filterMachineCollectionProducts(id, requestFilter),
            async x => {
                const editedMachineCollection = deepCopy(machineCollection);
                editedMachineCollection.products = editedMachineCollection.products.concat(x.data.filteredProducts);
                
                dispatch(setMachineCollection(editedMachineCollection));
                dispatch(setLoading(false));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.productLoad).toString(), type: SnackbarType.Error, dark: dark }));
                dispatch(setLoading(false));
            }
        );
    };
    
    const filterProducts = async (reset: boolean, tempFilter?: Filter, tempMachineCollection?: MachineCollection) => {
        if (filter.shouldFilter || (tempFilter && tempFilter.shouldFilter) || reset){
            dispatch(setLoading(true));
            
            if (!reset && !tempFilter){
                dispatch(setFilter({ ...filter, shouldFilter: false }));
            }

            const sortByType = window.localStorage.getItem('sortByType')
                ? parseInt(window.localStorage.getItem('sortByType'), 10)
                : LandingPageOrderType.DateDescending;
            
            const filterToUse = reset 
                ? EMPTY_FILTER 
                : tempFilter 
                    ? tempFilter 
                    : filter;

            const requestFilter = FilterService.createRequestFilter(filterToUse, tempMachineCollection ? tempMachineCollection.categoryGroups : machineCollection.categoryGroups, sortByType);
            
            await apiCall(
                Api.filterMachineCollectionProducts(id, requestFilter),
                async x => {
                    const editedMachineCollection = tempMachineCollection ? tempMachineCollection : deepCopy(machineCollection);
                    editedMachineCollection.products = x.data.filteredProducts;
                    
                    const newAvailableFilters = FilterService.mergeFilterWithRequestFilter(filter, x.data.requestFilter);
                    
                    dispatch(setAvailableFilters(newAvailableFilters));
                    dispatch(setMachineCollection(editedMachineCollection));
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.productLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );
            
            dispatch(setLoading(false));
        }
    };

    const getFilterFromLink = async (machineCollection: MachineCollection, favourites: Favourite[], availableFilters: RequestFilter) => {
        const search = location.search;
        const params = new URLSearchParams(search);

        let newFilter = deepCopy(EMPTY_FILTER);

        newFilter.companyNames = params.get('companyNames') ? params.get('companyNames').split(';')
            .map(x => getCompanyNameByLinkFilter(x, availableFilters.companyNames)) : filter.companyNames;
        newFilter.countries = params.get('countries') ? params.get('countries').split(';') : filter.countries;
        newFilter.hallIds = params.get('hallIds') ? params.get('hallIds').split(';') : filter.hallIds;
        newFilter.tags = params.get('tagIds') ? params.get('tagIds').split(';').map<Tag>(x => availableFilters.tags.find(y => y.id === x)) : filter.tags;
        newFilter.favourites = favourites;
        
        machineCollection.categoryGroups.forEach(x => newFilter[x.id] = params.get(x.id.toString())
            ? params.get(x.id.toString()).split(';').map<ProductCategory>(y => {
                return {
                    id: parseInt(y.split('-')[0], 10), 
                    isPrimary: y.split('-')[1] === '1', 
                    content: { id: null, en: '' } as Content, 
                    priorityOnUI: false, 
                    link: '', 
                    obsolete: false 
                };
            })
            : filter[x.id]
                ? filter[x.id]
                : []);

        newFilter.shouldFilter = true;

        newFilter = cleanFilter(newFilter, availableFilters);
        
        dispatch(setFilter(newFilter));
        
        return newFilter;
    };

    const handleCopiedToClipboard = async () => {
        dispatch(addSnackbar({ text: translate(translations.dialog.copiedLink), type: SnackbarType.Info, dark: dark }));
    };

    const handleRemoveFavourite = async (id: string) => {
        dispatch(setLoading(true));

        await apiCall(
            Api.removeProductFromFavourites(machineCollection.id, id),
            async () => {
                dispatch(setLoading(false));
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulDelete), type: SnackbarType.Success, dark: dark }));
            },
            async () => {
                dispatch(setLoading(false));
                dispatch(addSnackbar({ text: translate(translations.error.favouriteDelete), type: SnackbarType.Error, dark: dark }));
            }
        );
    };

    const handleSaveFavourite = async (id: string) => {
        dispatch(setLoading(true));

        await apiCall(
            Api.addProductToFavourites(machineCollection.id, id),
            async () => {
                dispatch(setLoading(false));
                dispatch(addSnackbar({ text: translate(translations.dialog.successfulSave), type: SnackbarType.Success, dark: dark }));
            },
            async () => {
                dispatch(setLoading(false));
                dispatch(addSnackbar({ text: translate(translations.error.favouriteSave), type: SnackbarType.Error, dark: dark }));
            }
        );
    };

    const cleanFilter = (filter: Filter, availableFilters: RequestFilter): Filter => {
        const cleanedFilter = filter;

        cleanedFilter.tags = cleanedFilter.tags.filter(x => availableFilters.tags.some(y => y.id === x.id));
        cleanedFilter.countries = cleanedFilter.countries.filter(x => availableFilters.countries.some(y => y === x));
        cleanedFilter.companyNames = cleanedFilter.companyNames.filter(x => availableFilters.companyNames.some(y => y === x));

        return cleanedFilter;
    };

    const getShareLink = (): string => {
        let url = `${window.location.href}?companyNames=${filter.companyNames.map(x => x.replace(/ /g, '-')).join(';')}&countries=${filter.countries.join(';')}&hallIds=${filter.hallIds.join(';')}&tagIds=${filter.tags.map(x => x.id).join(';')}`;
        
        machineCollection.categoryGroups.forEach(x => {
            url = `${url}&${x.id}=`;
            filter[x.id].forEach(y => url = `${url}${y.id.toString()}-${y.isPrimary ? '1' : '0'};`);
            if (url.endsWith(';')) {
                url = url.substring(0, url.length - 1);
            }
        });

        return encodeURI(url);
    };

    const handleSortProducts = async (sortBy: LandingPageOrderType) => {
        dispatch(setLoading(true));
        
        const editedMachineCollection = deepCopy(machineCollection);
        
        await apiCall(
            Api.filterMachineCollectionProducts(DEFAULT_MACHINE_COLLECTION_ID, FilterService.createRequestFilter(filter, machineCollection.categoryGroups, sortBy)),
            async x => {
                editedMachineCollection.products = x.data.filteredProducts;
            },
            async () => {
                dispatch(addSnackbar({ text: translate(translations.error.productLoad).toString(), type: SnackbarType.Error, dark: dark }));
                dispatch(setLoading(false));
            }
        );
        
        dispatch(setLoading(false));
        dispatch(setMachineCollection(editedMachineCollection));
    };

    const renderPage = () => {
        const collection = machineCollection && machineCollection.id === id ? machineCollection : null;

        return (
            <div className={`compass-section ${dark ? 'dark' : ''}`}>
                <Loader dark={dark} loading={isLoading}>
                    <DefaultCompanyManager />
                    <MachineCollectionView machineCollection={collection} onCopiedToClipboard={handleCopiedToClipboard} onFilterProducts={filterProducts} 
                       onGetNextProducts={getNextProducts} onGetShareLink={getShareLink} onRemoveFavourite={handleRemoveFavourite} onSaveFavourite={handleSaveFavourite} 
                       onSortProducts={handleSortProducts} />
                </Loader>
            </div>
        );
    };

    return renderPage();
};
export default MachineCollectionViewManager;
