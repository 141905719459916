import React, { ReactNode } from 'react';

interface StickyProps {
    anchor: 'bottom' | 'left' | 'right' | 'top';
    children: ReactNode
    distance?: number;
}

const Sticky = ({ anchor, children, distance }: StickyProps) => (
    <div className='sticky' style={{ [anchor]: distance ?? 0 }}>
        {children}
    </div>
);

export default Sticky;
