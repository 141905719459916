import { Company } from '../models';
import { EMPTY_GUID } from './EmptyGuid';

export const EMPTY_COMPANY: Company = {
    id: EMPTY_GUID,
    name: '',
    subtitle: '',
    street1: '',
    street2: '',
    zip: '',
    city: '',
    country: '',
    web: '',
    email: '',
    phoneNumber: '',
    fax: '',
    siemensRepresentativeEmails: []
};
