import React, { DragEvent, KeyboardEvent, RefObject, createRef, ChangeEvent, useState } from 'react';
import { TRANSLATIONS } from '../../constants';
import { useTranslate } from '../../hooks/common';
import { convertMegabytesToBytes } from '../../services';
import Icon, { IconType } from './Icon';

interface ImageUploadProps {
    label: string;
    validTypes: string[]
    onFileReceived: (file: File) => void;
    dark?: boolean;
    disabled?: boolean;
    imageUrl?: string;
    additonalText?: string;
    highlighted?: boolean;
    maxWidth?: number;
    minWidth?: number;
    maxHeight?: number;
    minHeight?: number;
    maxSizeInMegabytes?: number;
    onMaxResExceeded?: () => void;
}

const ImageUpload = ({ dark, label, validTypes, onFileReceived, disabled, imageUrl, additonalText, highlighted, maxHeight, maxWidth, minHeight, minWidth,
    maxSizeInMegabytes, onMaxResExceeded }: ImageUploadProps) => {
    const fileInputRef: RefObject<HTMLInputElement> = createRef();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [errorMessage, setErrorMessage] = useState('');
    const [imageErrorOccured, setImageErrorOccured] = useState(false);

    const isFileValid = (file: File) => {
        return validTypes.some(x => x === file.type);
    };

    const handleDropZoneClick = () => {
        fileInputRef.current.click();
    };

    const handleDropZoneKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            fileInputRef.current.click();
        }
    };
    
    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        handleFiles(event.dataTransfer.files);
    };

    const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleFiles(event.currentTarget.files);
    };
    
    const preventDefault = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleFiles = async (files: FileList) => {
        if (files.length) {
            const newFile = files[0];
            const isValidSize = maxSizeInMegabytes ? newFile.size < convertMegabytesToBytes(maxSizeInMegabytes) : true;

            if (isValidSize) {
                if (await isResolutionValid(newFile)) {
                    if (isFileValid(newFile)) {
                        setErrorMessage('');
                        setImageErrorOccured(false);
                        onFileReceived(newFile);
                    } else {
                        setErrorMessage(translate(translations.error.fileType));
                    }
                } else {
                    setErrorMessage(translate(translations.error.fileLowResolution));
                }
            } else {
                    setErrorMessage(translate(translations.error.fileBig));
            }
        }
    };

    const handleImageError = () => {
        setImageErrorOccured(true);
    };

    const isResolutionValid = (file: File) => {
        return new Promise<boolean>((resolve, reject) => {
            if (minWidth && minHeight) {
                const img = document.createElement('img');

                img.onload = () => {
                    const isValid = img.width >= minWidth && img.height >= minHeight;

                    if (maxWidth && maxHeight && (img.width > maxWidth || img.height > maxHeight) && onMaxResExceeded) {
                        onMaxResExceeded();
                    }

                    resolve(isValid);
                };
                img.onerror = reject;
                img.src = URL.createObjectURL(file);
            } else {
                resolve(true);
            }
        });
    };

    const renderText = () => {
        return (
            <div className='drop-text-container'>
                <div className='drop-label'>{label}</div>
                <div>{translate(translations.dialog.dropText)}</div>
                {additonalText &&
                    <div>{additonalText}</div>
                }
            </div>
        );
    };

    const renderImage = () => {
        return imageUrl && !imageErrorOccured
            ? <img className='drop-image' src={imageUrl} onError={handleImageError} />
            : <Icon type={IconType.Upload} />;
    };

    const renderDropZone = () => {
        const dropZoneInnerContainerClassName = `drop-zone-inner-container ${highlighted ? 'highlighted' : ''}`;

        return (
            <div className='drop-zone' tabIndex={0} onDragOver={preventDefault} onDragEnter={preventDefault} onDragLeave={preventDefault} onDrop={handleDrop}
                onClick={handleDropZoneClick} onKeyPress={handleDropZoneKeyPress}>
                <div className={dropZoneInnerContainerClassName} tabIndex={-1}>
                    {renderText()}
                    {renderImage()}
                </div>
                <input ref={fileInputRef} className='file-input' type='file' onChange={handleFileInputChange} disabled={disabled} />
            </div>
        );
    };

    const renderErrorMessage = () => {
        return (
            <>
                {errorMessage &&
                    <div className='error-message'>
                        {errorMessage}
                    </div>
                }
            </>
        );
    };

    const renderImageUpload = () => {
        return (
            <div className={`image-upload-container ${dark ? 'dark' : ''}`}>
                {renderDropZone()}
                {renderErrorMessage()}
            </div>
        );
    };

    return renderImageUpload();
};
export default ImageUpload;
