import React from 'react';
import { DateService } from '../../../services';
import { MachineCollectionCreation } from '../../../models';
import { MachineCollectionType } from '../../../enums';
import DateInput from '../../common/DateInput';
import ImageUpload from '../../common/ImageUpload';
import SpacingContainer from '../../common/SpacingContainer';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { TRANSLATIONS } from '../../../constants';
import TextInput from '../../common/TextInput';

interface MachineCollectionTradeShowFormProps {
    image: File;
    imageUrl: string;
    machineCollection: MachineCollectionCreation;
    onChange: (machineCollection: Partial<MachineCollectionCreation>) => Promise<void>;
    onFileReceived: (file: File) => void;
}

const MachineCollectionTradeShowForm = ({ image, imageUrl, machineCollection, onChange, onFileReceived }: MachineCollectionTradeShowFormProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;


    const handleChange = (value: string, name: string) => {
        return onChange({ [name]: value });
    };

    const handleStartDateChange = (date: string) => {
        return onChange({ fairStartDate: new Date(date) });
    };

    const handlePreStartDateChange = (date: string) => {
        return onChange({ fairPreStartDate: new Date(date) });
    };

    const handleEndDateChange = (date: string) => {
        return onChange({ fairEndDate: new Date(date) });
    };

    const getStartDateError = (date: string) => {
        return DateService.getDayBeginning(new Date(date)) < DateService.getDayBeginning(machineCollection.fairPreStartDate)
            ? translate(translations.error.fairDateStartAfterFirstPublish)
            : '';
    };

    const getPreStartDateError = (date: string) => {
        return DateService.getDayBeginning(new Date(date)) <= DateService.getDayBeginning(machineCollection.exhibitorsEndDate)
            ? translate(translations.error.fairDateFirstPublishAfterEndDate)
            : '';
    };

    const getEndDateError = (date: string) => {
        return DateService.getDayBeginning(new Date(date)) <= DateService.getDayBeginning(machineCollection.fairStartDate)
            ? translate(translations.error.fairDateEndAfterStart)
            : '';
    };

    const isImageValid = () => {
        return machineCollection && Boolean(machineCollection.imageId || image);
    };

    const renderForm = () => {
        return machineCollection && (
            <SpacingContainer>
                <ImageUpload dark={dark} label={translate(translations.common.picture)} additonalText={translate(translations.dialog.imageFormat)} imageUrl={imageUrl} 
                    validTypes={['image/jpeg', 'image/jpg', 'image/png']} onFileReceived={onFileReceived} highlighted={!isImageValid()} minWidth={800} 
                    minHeight={600} maxSizeInMegabytes={8} />
                {machineCollection.type === MachineCollectionType.EBooklet &&
                    <>
                    <TextInput dark={dark} label={translate(translations.common.location)} value={machineCollection.location} name='location' onChange={handleChange} required />
                    <DateInput dark={dark} label={translate(translations.collection.startDateOfFair)} 
                               error={getStartDateError(DateService.toDateString(machineCollection.fairStartDate)) !== ''} 
                               value={DateService.toDateString(machineCollection.fairStartDate)} onChange={handleStartDateChange} getMessage={getStartDateError} />
                    <DateInput dark={dark} label={translate(translations.collection.dateOfFirstPublish)} 
                               error={getPreStartDateError(DateService.toDateString(machineCollection.fairPreStartDate)) !== ''} 
                               value={DateService.toDateString(machineCollection.fairPreStartDate)} onChange={handlePreStartDateChange} getMessage={getPreStartDateError} />
                    <DateInput dark={dark} label={translate(translations.collection.endDateOfFair)} 
                               error={getEndDateError(DateService.toDateString(machineCollection.fairEndDate)) !== ''} 
                               value={DateService.toDateString(machineCollection.fairEndDate)} onChange={handleEndDateChange} getMessage={getEndDateError} />
                    </>
                }
            </SpacingContainer>
        );
    };

    return renderForm();
};
export default MachineCollectionTradeShowForm;
