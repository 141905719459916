import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { hooks } from './ts/hooks';
import { HookProvider } from './ts/providers';
import routes from './routes';
import 'rc-slider/assets/index.css';
import './css/styles.css';
import { store } from './ts/store/store';

const container = document.getElementById('root') ?? new HTMLElement;
const root = createRoot(container);

root.render(
    <HookProvider hooks={hooks}>
        <Provider store={store}>
            <BrowserRouter>
                {routes}
            </BrowserRouter>
        </Provider>
    </HookProvider>
);
