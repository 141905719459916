import React, { ReactNode } from 'react';

interface JumbotronProps {
    children?: ReactNode;
    dark?: boolean;
}

const Jumbotron = ({ children, dark }: JumbotronProps) => (
    <div className={`jumbotron ${dark ? 'dark' : ''}`} >
        {children}
    </div>
);

export default Jumbotron;
