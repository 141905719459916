import React, { ReactNode } from 'react';
import { Tooltip as ReactTooltip }from 'react-tooltip';

interface TooltipProps {
    children: ReactNode;
    id: string;
    text: string;
    delayShow?: number;
    hidden?: boolean;
    place?: 'top' | 'right' | 'bottom' | 'left'
}

const Tooltip = ({ children, delayShow, hidden, id, place, text }: TooltipProps) => (
    <div className='tooltip' data-tooltip-id={id} data-tooltip-content={text}>
        {children}
        {!hidden &&
            <ReactTooltip id={id} place={place} delayShow={delayShow ?? 200} delayHide={200} />
        }
    </div>
);

export default Tooltip;
