export enum HeaderTabType {
    Categories,
    MachineCollection,
    MachineCollections,
    MyMachineCollections,
    MyProducts,
    Tags,
    Users,
    Export,
    Maintenance
}
