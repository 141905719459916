import React, { ReactText, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ContentService } from '../../../services';
import { Product, ProductCategory, ProductCategoryGroup } from '../../../models';
import { SnackbarType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import ConsentModal from './ConsentModal';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification, FlexWrap } from '../../common/Flex';
import Icon, { IconType } from '../../common/Icon';
import Modal from '../../common/Modal';
import Section, { SectionColor } from '../../common/Section';
import FairTag from '../../common/FairTag';
import Title from '../../common/Title';
import { useAppSelector, useResized, useTranslate } from '../../../hooks/common';
import TextInput from '../../common/TextInput';
import { addSnackbar } from '../../../store';

interface ProductViewProps {
    product: Product;
    allowSharing?: boolean;
    approvalMode?: boolean;
    releaseComment?: string;
    onApprove?: () => Promise<void>;
    onReject?: () => Promise<void>;
    onCommentChange?: (comment: string) => void;
}

const ProductView = ({ product, allowSharing, approvalMode, releaseComment, onApprove, onReject, onCommentChange }: ProductViewProps) => {
    const SIDE_SHEET_TRESHOLD = 1400;
    const activeLanguage = useAppSelector(state => state.translation.language);
    const dark = useAppSelector(state => state.layout.dark);
    const dispatch = useDispatch();
    const mobileView = useResized(x => x <= SIDE_SHEET_TRESHOLD);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [shouldDisplayReleaseModal, setShouldDisplayReleaseModal] = useState(false);
    const [shouldDisplayRejectionModal, setShouldDisplayRejectionModal] = useState(false);
    
    const cancelRejectionModal = async () => setShouldDisplayRejectionModal(false);

    const cancelReleaseModal = async () => setShouldDisplayReleaseModal(false);

    const closeReleaseModal = async () => {
        setShouldDisplayReleaseModal(false);
        onApprove();
    };

    const closeRejectionModal = async () => {
        setShouldDisplayRejectionModal(false);
        onReject();
    };

    const openRejectionModal = async () => setShouldDisplayRejectionModal(true);

    const openReleaseModal = async () => setShouldDisplayReleaseModal(true);

    const renderHeader = () => {
        return (
            <div className='header-container'>
                <Flex direction={FlexDirection.Row} justification={FlexJustification.SpaceBetween}>
                    <div className='title'>
                        {product.title}
                    </div>
                </Flex>
                {product.company &&
                    <div className='company'>
                        {product.company.name}
                    </div>
                }
                <Flex direction={FlexDirection.Row} style={{ marginTop: 10 }} justification={FlexJustification.FlexStart}>
                    {product.isDigitalTwinAvailable &&
                        <Button dark={dark} type={ButtonType.Primary} 
                                onClick={() => dispatch(addSnackbar({ text: 'To be implemented', type: SnackbarType.Info, dark: dark }))}>
                            {translate(translations.action.tryDigitalTwin)}
                        </Button>
                    }
                    {allowSharing &&
                        <Button dark={dark} type={ButtonType.Tertiary} onClick={() => window.open(`mailto:?body=${window.location.href}`)}>
                            <Icon type={IconType.Share} />
                            {translate(translations.action.share)}
                        </Button>
                    }
                </Flex>
                {product.hall &&
                    <div className='hall-booth'>
                        {product.hall.imageUrl
                            ? <a href={product.hall.imageUrl} target='_blank' rel='noreferrer'>
                                {`${translate(translations.hall.hall)} ${product.hall.name}`}
                            </a>
                            : `${translate(translations.hall.hall)} ${product.hall.name}`
                        }
                        {`${product.hall && product.booth ? ' / ' : ''}${product.booth ? product.booth : ''}`}
                    </div>
                }
            </div>
        );
    };

    const renderImage = () => {
        return (
            <div className='image-container'>
                <img src={product.imageUrl ? product.imageUrl : '/images/fallback-product.png'} onError={e => {
                    e.target['onerror' as keyof typeof e.target] = null;
                }} />
            </div>
        );
    };

    const renderDescription = () => {
        return (
            <div className={`description-container ${dark ? 'dark' : ''}`}>
                    <>
                        <Title className={'title'} text={translate(translations.common.descriptionMachine)} bold />
                        {ContentService.translateWithParsing(product.description, activeLanguage)}
                    </>
                <br />
                    <>
                        <Title className={'title'} text={translate(translations.common.descriptionCNC)} bold />
                        {ContentService.translateWithParsing(product.secondDescription, activeLanguage)}
                    </>
            </div>
        );
    };

    const renderInfoRow = (content: ReactText | JSX.Element, iconType: IconType = null, link?: string) => {
        return (
            <div className='info'>
                {iconType &&
                    <Icon type={iconType} />
                }
                {link ? <a href={link} target='_blank' rel='noreferrer'>{content}</a> : content}
            </div>
        );
    };

    const renderCategoryGroup = (categoryGroup: ProductCategoryGroup) => {
        const hasPrimaryAndSecondary = categoryGroup.categories.some(x => x.isPrimary) && categoryGroup.categories.some(x => !x.isPrimary);

        const renderCategoryGroupSection = (categories: ProductCategory[], prefix?: string) => (
            <div className='info-section'>
                <div className='title'>
                    {prefix && `${prefix} `}
                    {ContentService.translate(categoryGroup.groupContent, activeLanguage)}
                </div>
                {
                    categories.slice().sort((a, b) => a.priorityOnUI > b.priorityOnUI ? -1 : b.priorityOnUI > a.priorityOnUI ? 1 : 0)
                        .map(x => renderInfoRow(ContentService.translate(x.content, activeLanguage), IconType.CheckSmall, x.link))
                }
            </div>
        );

        return !hasPrimaryAndSecondary
            ? renderCategoryGroupSection(categoryGroup.categories)
            : <>
                {renderCategoryGroupSection(categoryGroup.categories.filter(x => x.isPrimary), translate(translations.category.primary))}
                {renderCategoryGroupSection(categoryGroup.categories.filter(x => !x.isPrimary), translate(translations.category.secondary))}
            </>;
    };

    const renderCategoryInfos = () => {
        const categoryThreshold = 2;
        
        return (
            <>
                <div className='info-block'>
                    {product.categoryGroups.slice(0, categoryThreshold).map(renderCategoryGroup)}
                </div>
                <div className='info-block'>
                    {product.categoryGroups.slice(categoryThreshold).map(renderCategoryGroup)}
                </div>
            </>
        );
    };

    const renderConsentModal = () => {
        return shouldDisplayReleaseModal && (
            <ConsentModal approvalMode product={product} releaseComment={releaseComment} onCancelModal={cancelReleaseModal} onCloseModal={closeReleaseModal}
                onCommentChange={x => onCommentChange(x)} />
        );
    };

    const renderRejectionModal = () => {
        return shouldDisplayRejectionModal && (
            <Modal dark={dark} open={true}>
                <Title style={{ marginBottom: 10 }} text={translate(translations.dialog.confirmReject)} bold />
                <TextInput dark={dark} label={translate(translations.common.comment)} name={translate(translations.common.comment)} value={releaseComment} 
                           onChange={x => onCommentChange(x)} />
                <Flex direction={FlexDirection.Row} style={{ marginTop: 30 }} justification={FlexJustification.FlexEnd}>
                    <Button dark={dark} type={ButtonType.Secondary} onClick={cancelRejectionModal} >
                        <Icon type={IconType.Close} />
                        {translate(translations.action.close)}
                    </Button>
                    <Button dark={dark} type={ButtonType.Primary} onClick={closeRejectionModal} >
                        <Icon type={IconType.Check} />
                        {translate(translations.common.reject)}
                    </Button>
                </Flex>
            </Modal>
        );
    };

    const renderCompanyInfos = () => {
        const { company } = product;

        const isValidURL = company.web && (company.web.startsWith('https://') || company.web.startsWith('http://'));

        return company && (
            <div className='info-block'>
                <div className='info-section'>
                    <div className='title'>
                        {translate(translations.common.address)}
                    </div>
                    {renderInfoRow(company.name)}
                    {company.subtitle && renderInfoRow(company.subtitle)}
                </div>
                <div className='info-section'>
                    {renderInfoRow(company.street1)}
                    {company.street2 && renderInfoRow(company.street2)}
                    {renderInfoRow(`${company.zip} ${company.city}, 
                    ${translate(translations.countries[company.country.toLowerCase() as keyof typeof translations.countries])}`)}
                </div>
                <div className='info-section'>
                    {company.web && renderInfoRow(
                        <a href={isValidURL ? company.web : `http://${company.web}`} target='_blank' rel='noreferrer'>
                            {company.web}
                        </a>,
                        IconType.Location
                    )}
                    {company.email && renderInfoRow(
                        <a href={`mailto:${company.email}`}>
                            {company.email}
                        </a>,
                        IconType.Email
                    )}
                    {company.phoneNumber && renderInfoRow(company.phoneNumber, IconType.Phone)}
                    {company.fax && renderInfoRow(company.fax, IconType.Print)}
                </div>
            </div>
        );
    };

    const renderMainInfos = () => {
        return (
            <div className={'machine-container'}>
                {renderHeader()}
                {renderImage()}
                {mobileView && renderTags()}
                {renderDescription()}
            </div>
        );
    };

    const renderTags = () => {
        return Boolean(product.tags && product.tags.length) && (
            <>
                <div className={'tag-container'}>
                    <Title text={translate(translations.dialog.fairExhibited)} />
                    <Flex justification={FlexJustification.FlexStart} direction={mobileView ? FlexDirection.Row : FlexDirection.Column} wrap={FlexWrap.Wrap}>
                        {product.tags.map(x => <FairTag key={x.id} id={x.id} text={x.name} color={x.color} noClose />)}
                    </Flex>
                </div>
            </>
        );
    };

    const renderAdditionalInfos = () => {
        return (
            <div className='additional-info-section'>
                {renderCategoryInfos()}
                {renderCompanyInfos()}
            </div>
        );
    };

    const renderProductView = () => {
        return product && (
            <div id='section-to-print' className='product-view-container'>
                <ContentContainer>
                    <div className='main-section'>
                        {renderMainInfos()}
                        {!mobileView && renderTags()}
                        <div className={`side-box ${dark ? 'dark' : ''}`}>
                            {renderAdditionalInfos()}
                        </div>
                    </div>
                    {`${translate(translations.product.releaseDate)}: ${new Date(product.releaseDate).toLocaleDateString()}`}
                    {approvalMode &&
                        <Flex direction={FlexDirection.Row} style={{ marginBottom: 10 }} justification={FlexJustification.FlexEnd}>
                            <Button dark={dark} type={ButtonType.Tertiary} onClick={openRejectionModal} >
                                <Icon type={IconType.Close} />
                                {translate(translations.common.reject)}
                            </Button>
                            <Button dark={dark} type={ButtonType.Primary} onClick={openReleaseModal} >
                                <Icon type={IconType.CheckSmall} />
                                {translate(translations.common.approve)}
                            </Button>
                        </Flex>
                    }
                </ContentContainer>
                <Section color={SectionColor.DeepBlue} style={{ marginTop: 50, paddingBottom: 50 }}>
                    {renderAdditionalInfos()}
                </Section>
                {approvalMode && renderConsentModal()}
                {approvalMode && renderRejectionModal()}
            </div>
        );
    };

    return renderProductView();
};
export default ProductView;
