import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Api, apiCall, AuthService, ContentService, DateService } from '../../../services';
import { addSnackbar, setSharedMachinesLoading } from '../../../store';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { PaginatedList, Product, ReleasedMachineCollection, RowValues, Tag } from '../../../models';
import { CommentType, ProductStatus, ProductTableName } from '../../../enums';
import { DEFAULT_MACHINE_COLLECTION_ID, ROLES, TRANSLATIONS } from '../../../constants';
import fallbackImage from '../../../../assets/images/fallback-product.png';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import FairTag from '../../common/FairTag';
import Flex, { FlexDirection, FlexJustification, FlexWrap } from '../../common/Flex';
import Highlight, { HighlightType } from '../../common/Highlight';
import Icon, { IconType } from '../../common/Icon';
import Link from '../../common/Link';
import List, { ListItem } from '../../common/List';
import Loader from '../../common/Loader';
import Modal from '../../common/Modal';
import Pagination from '../../common/Pagination';
import RadioButton from '../../common/RadioButton';
import Section, { SectionColor } from '../../common/Section';
import Select from '../../common/Select';
import { SnackbarType } from '../../common/Snackbar';
import Table, { Row } from '../../common/Table';
import Title from '../../common/Title';
import Tooltip from '../../common/Tooltip';

interface ProductTableProps {
    chiefEditorProducts: PaginatedList<Product>;
    incompleteProducts: PaginatedList<Product>;
    inReviewProducts: PaginatedList<Product>;
    machineCollections: ReleasedMachineCollection[];
    productRequests: PaginatedList<Product>;
    products: PaginatedList<Product>;
    sharedMachines: PaginatedList<Product>;
    rowValues: RowValues;
    tags: Tag[];
    reviewedProducts: PaginatedList<Product>;
    onAddTagToProduct: (productId: string, tagId: string) => Promise<void>;
    onGetProducts: (offset: number, limit: number, orderBy: string, orderType: string, name?: ProductTableName) => Promise<void>;
    onMachineCollectionsLoad: () => Promise<ReleasedMachineCollection[]>;
    onProductCopy: (productId: string) => Promise<void>;
    onProductDelete: (id: string) => Promise<void>;
    onRemoveTagFromProduct: (productId: string, tagId: string) => Promise<void>;
    onToggleDigitalTwinProduct: (productId: string, enableDigitalTwin: boolean) => Promise<void>;
}

const ProductTable = ({ chiefEditorProducts, incompleteProducts, inReviewProducts, machineCollections, productRequests, products, sharedMachines, rowValues, tags, reviewedProducts,
    onAddTagToProduct, onGetProducts, onMachineCollectionsLoad, onProductCopy, onProductDelete, onToggleDigitalTwinProduct, onRemoveTagFromProduct }: ProductTableProps) => {
    const DEFAULT_INDEX = 4;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const dark = useAppSelector(state => state.layout.dark);
    const productsLoading = useAppSelector(state => state.loader.productsLoading);
    const sharedMachinesLoading = useAppSelector(state => state.loader.sharedMachinesLoading);
    const chiefEditorProductsLoading = useAppSelector(state => state.loader.chiefEditorProductsLoading);
    const incompleteProductsLoading = useAppSelector(state => state.loader.incompleteProductsLoading);
    const inReviewProductsLoading = useAppSelector(state => state.loader.inReviewProductsLoading);
    const productRequestsLoading = useAppSelector(state => state.loader.productRequestsLoading);
    const reviewedProductsLoading = useAppSelector(state => state.loader.reviewedProductsLoading);

    const [myMachinesSortedByIndex, setMyMachinesSortedByIndex] = useState(DEFAULT_INDEX);
    const [myMachinesReverse, setMyMachinesReverse] = useState(true);    
    const [sharedMachinesSortedByIndex, setSharedMachinesSortedByIndex] = useState(DEFAULT_INDEX);
    const [sharedMachinesReverse, setSharedMachinesReverse] = useState(true);
    const [requestsSortedByIndex, setRequestsSortedByIndex] = useState(DEFAULT_INDEX);
    const [requestsReverse, setRequestsReverse] = useState(true);
    const [chiefEditorSortedByIndex, setChiefEditorSortedByIndex] = useState(DEFAULT_INDEX);
    const [chiefEditorReverse, setChiefEditorReverse] = useState(true);
    const [incompleteSortedByIndex, setIncompleteSortedByIndex] = useState(DEFAULT_INDEX);
    const [inReviewSortedByIndex, setInReviewSortedByIndex] = useState(DEFAULT_INDEX);
    const [inReviewReverse, setInReviewReverse] = useState(true);
    const [incompleteReverse, setIncompleteReverse] = useState(true);
    const [reviewedSortedByIndex, setReviewedSortedByIndex] = useState(DEFAULT_INDEX);
    const [reviewedReverse, setReviewedReverse] = useState(true);
    const [machineCollectionId, setMachineCollectionId] = useState(DEFAULT_MACHINE_COLLECTION_ID);
    const [productId, setProductId] = useState(null);
    const [shouldDisplayDeleteDialog, setShouldDisplayDeleteDialog] = useState(false);
    const [shouldDisplayEditDialog, setShouldDisplayEditDialog] = useState(false);
    const [shouldDisplayTargetModal, setShouldDisplayTargetModal] = useState(false);
    const [shouldDisplayTagPicker, setShouldDisplayTagPicker] = useState(false);

    /*const openTargetModal = async (product: Product = null) => {
        await onMachineCollectionsLoad();

        if (machineCollections.some(x => x.id === DEFAULT_MACHINE_COLLECTION_ID)) {
            setMachineCollectionId(DEFAULT_MACHINE_COLLECTION_ID);
        }

        setProductId(product ? product.id : null);
        setShouldDisplayTargetModal(true);
        await confirmTargetModal();
    };*/

    const closeTargetModal = async () => {
        setMachineCollectionId(null);
        setProductId(null);
        setShouldDisplayTargetModal(false);
    };

    const confirmTargetModal = async () => {
        setShouldDisplayTargetModal(false);
        navigate(productId
            ? `/productreleasewizard/${productId}?collectionid=${machineCollectionId}`
            : machineCollectionId
                ? `/productreleasewizard?collectionid=${machineCollectionId}`
                : '/productwizard'
        );
    };

    const openReleaseProductWizard = async (productId: string) => {
        navigate(productId
            ? `/productreleasewizard/${productId}?collectionid=${DEFAULT_MACHINE_COLLECTION_ID}`
            : `/productreleasewizard?collectionid=${DEFAULT_MACHINE_COLLECTION_ID}`
        );
    };

    const openDeleteDialog = async (products: Product) => {
        setProductId(products.id);
        setShouldDisplayDeleteDialog(true);
    };

    const closeDeleteDialog = () => {
        setProductId(null);
        setShouldDisplayDeleteDialog(false);
    };

    const confirmDeleteDialog = async () => {
        setShouldDisplayDeleteDialog(false);
        onProductDelete(productId);
        setProductId(null);
    };

    const openEditDialog = async (productId: string) => {
        setProductId(productId);
        setShouldDisplayEditDialog(true);
    };

    const closeEditDialog = () => {
        setProductId(null);
        setShouldDisplayEditDialog(false);
    };

    const confirmEditDialog = async () => {
        setShouldDisplayEditDialog(false);

        await apiCall(Api.resetProduct(productId),
            async () => {
                navigate(`/productreleasewizard/${productId}`);
            }, 
            async () => {
                dispatch(addSnackbar({
                    text: translate(translations.error.productUpdate),
                    type: SnackbarType.Error,
                    dark: dark }));
            }
        );

        setProductId(null);
    };

    const openTagPickerModal = async (productId: string) => {
        setProductId(productId);
        setShouldDisplayTagPicker(true);
    };

    const closeTagPickerModal = async () => {
        setProductId(null);
        setShouldDisplayTagPicker(false);
    };

    const handleAddTagToProduct = (productId: string, tagId: string, filteredTagsLength: number) => {
        if (filteredTagsLength === 1) {
            closeTagPickerModal();
        }

        onAddTagToProduct(productId, tagId);
    };
    
    const handleApprovalRedirect = async (productId: string) => {
        await apiCall(Api.getProductToken(productId), async x => {
            navigate(`/approval/${x.data}`);
        }, async () => {
            dispatch(addSnackbar({
                text: translate(translations.error.productsLoad),
                type: SnackbarType.Error,
                dark: dark
            }));
        });
    };
    
    const handleResendEmailForProduct = async (productId: string) => {
        await apiCall(Api.resendProductEmails(productId), async x => {
            dispatch(addSnackbar({
                text: translate(translations.dialog.successfulEmail),
                type: SnackbarType.Success,
                dark: dark }));
        }, async () => {
            dispatch(addSnackbar({
                text: translate(translations.error.emailSend),
                type: SnackbarType.Error,
                dark: dark }));
        });
    };

    const handleRemoveTagFromProduct = (productId: string, tagId: string) => {
        onRemoveTagFromProduct(productId, tagId);
    };

    const getOrderType = (currentOrderBy: string, nextOrderBy: string, orderType: string, setReverse: (x: boolean) => void) => {
        let currentOrderType;

        if (currentOrderBy === nextOrderBy) {
            if (orderType === 'DESC') {
                currentOrderType = 'ASC';
                setReverse(false);
            } else {
                currentOrderType = 'DESC';
                setReverse(true);
            }
        } else {
            currentOrderType = 'DESC';
            setReverse(true);
        }

        return currentOrderType;
    };

    const getRelevantDate = (product: Product) => {
        let relevantDate: Date;
        let dateTypeText: string;

        if (product.status === ProductStatus.ApprovedByChiefEditor) {
            relevantDate = product.lastChangeDate;
            dateTypeText = translate(translations.product.releaseDate);
        } else {
            const wasEdited = product.lastChangeDate > product.createdDate;

            relevantDate = wasEdited ? product.lastChangeDate : product.createdDate;
            dateTypeText = wasEdited ? translate(translations.product.lastChangeDate) : translate(translations.product.creationDate);
        }

        return { relevantDate, dateTypeText };
    };

    const renderTargetModal = () => {        
        return (
            <Modal dark={dark} open={shouldDisplayTargetModal}>
                <Title style={{ marginBottom: 30 }} text={translate(productId ? translations.dialog.releaseTarget : translations.dialog.createTarget)} bold />
                <List items={[
                    ...machineCollections.map<ListItem>(x => ({
                        key: x.id,
                        content: <RadioButton selected={x.id === machineCollectionId} label={x.title} onChange={() => setMachineCollectionId(x.id)} />
                    })),
                    ...productId ? [] : [{
                        key: 'draft',
                        content: <RadioButton selected={!machineCollectionId} label={translate(translations.dialog.draft)} onChange={() => setMachineCollectionId(null)} />
                    }]
                ]} />
                <Flex direction={FlexDirection.Row} style={{ marginTop: 30 }} justification={FlexJustification.FlexEnd}>
                    <Button dark={dark} type={ButtonType.Secondary} onClick={closeTargetModal}>
                        <Icon type={IconType.Close} />
                        {translate(translations.action.cancel)}
                    </Button>
                    <Button dark={dark} type={ButtonType.Primary} disabled={productId && !machineCollectionId} onClick={confirmTargetModal}>
                        <Icon type={productId ? IconType.Check : IconType.Plus} />
                        {translate(productId ? translations.action.release : translations.action.create)}
                    </Button>
                </Flex>
            </Modal>
        );
    };

    const renderTagPickerModal = () => {
        let filteredTags: Tag[];

        if (shouldDisplayTagPicker) {
            const product = products.items.find(x => x.id === productId);

            filteredTags = tags ? tags.filter(x => !x.outdated && product.tags.every(y => x.id !== y.id)) : [];
        }

        return (
            <Modal dark={dark} open={shouldDisplayTagPicker}>
                <Title style={{ marginBottom: 30 }} text={translate(translations.dialog.fairSelect)} bold uppercase />
                {filteredTags && filteredTags.map(x => <FairTag key={x.id} id={x.id} text={x.name} color={x.color} handleOnClick={() => handleAddTagToProduct(productId, x.id, filteredTags.length)}
                                                noClose />)}
                <Flex direction={FlexDirection.Row} style={{ marginTop: 30 }} justification={FlexJustification.FlexEnd}>
                    <Button type={ButtonType.Secondary} onClick={closeTagPickerModal}>
                        <Icon type={IconType.Close} />
                        {translate(translations.action.close)}
                    </Button>
                </Flex>
            </Modal>
        );
     };

    const renderDeleteDialog = () => {
        return (
            <Modal dark={dark} open={shouldDisplayDeleteDialog}>
                <Title text={translate(translations.dialog.confirmDelete)} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    <Link dark={dark} text={translate(translations.action.yes)} onClick={confirmDeleteDialog} />
                    <Link dark={dark} text={translate(translations.action.no)} onClick={closeDeleteDialog} />
                </Flex>
            </Modal>
        );
    };

    const renderEditDialog = () => {
        return (
            <Modal dark={dark} open={shouldDisplayEditDialog}>
                <Title text={translate(translations.dialog.confirmEdit)} style={{ marginBottom: '10px' }} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    <Button type={ButtonType.Secondary} dark={dark} onClick={confirmEditDialog}>
                        <Icon type={IconType.Check} />
                        {translate(translations.action.yes)}
                    </Button>
                    <Button type={ButtonType.Secondary} dark={dark} onClick={closeEditDialog}>
                        <Icon type={IconType.Close} />
                        {translate(translations.action.no)}
                    </Button>
                </Flex>
            </Modal>
        );
    };

    const renderImage = (product: Product) => {
        return (
            <div className='table-image-container'>
                <img alt={'thumbnail'} src={product.thumbnailImageUrl} style={{ maxWidth: 80, maxHeight: 60 }} onError={x => {
                    x.currentTarget.src = fallbackImage;
                    x.currentTarget.onerror = null;
                }} />
            </div>
        );
    };

    const renderTitle = (product: Product, tableName: ProductTableName) => {
        const tooltipId = 'title-tooltip';
        const isReviewRequestTable = [ProductTableName.ProductRequests, ProductTableName.ChiefEditorProducts].some(x => x === tableName);
        
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexStart} wrap={FlexWrap.Wrap}>
                {!isReviewRequestTable && (
                    <>
                        {product.status === ProductStatus.Incomplete &&
                            <Tooltip id={tooltipId} text={product.title}>
                                <Link dark={dark} text={product.title} onClick={() => navigate(`/productwizard/${product.id}`)} />
                            </Tooltip>
                        }
                        {product.status === ProductStatus.Draft &&
                            <Tooltip id={tooltipId} text={product.title}>
                                <Link dark={dark} text={product.title} onClick={() => openReleaseProductWizard(product.id)} />
                            </Tooltip>
                        }
                        {[ProductStatus.CancelledRelease, ProductStatus.Rejected, ProductStatus.RejectedByCustomer].some(x => product.status === x) &&
                            <Tooltip id={tooltipId} text={product.title}>
                                <Link dark={dark} text={product.title} onClick={() => navigate(`/productreleasewizard/${product.id}`)} />
                            </Tooltip>
                        }
                        {[ProductStatus.Released, ProductStatus.InApproval, ProductStatus.Approved, ProductStatus.RejectedByChiefEditor].some(x => product.status === x) &&
                            <Tooltip id={tooltipId} text={product.title}>
                                <Link dark={dark} text={product.title} onClick={() => navigate(`/product/${product.id}${isReviewRequestTable ? '/review' : ''}`)} />
                            </Tooltip>
                        }
                        {product.status === ProductStatus.ApprovedByChiefEditor &&
                            <Tooltip id={tooltipId} text={product.title}>
                                <Link dark={dark} text={product.title} onClick={() => navigate(`/product/${product.id}`)} />
                            </Tooltip>
                        }
                    </>
                )}
                {isReviewRequestTable && (
                    <Tooltip id={tooltipId} text={product.title}>
                        <Link dark={dark} text={product.title} onClick={() => navigate(`/product/${product.id}`)} />
                    </Tooltip>
                )}
            </Flex>
        );
    };

    const renderCompany = (product: Product) => {
        return (
            <>
                <Tooltip id={'company-tooltip'} text={product.company.name}>
                    {product.company.name}
                </Tooltip>
            </>
            );
    };

    const renderStatus = (product: Product) => {
        let highlightType: HighlightType;
        let iconType: IconType;
        let comment: string;

        const hasReviewComment = product && product.comments && product.comments.some(x => x.type === CommentType.ReviewComment);
        const hasRejectComment = product && product.comments && product.comments.some(x => x.type === CommentType.RejectedReleaseComment);

        switch (product.status) {
            case ProductStatus.Draft:
            case ProductStatus.Incomplete:
            case ProductStatus.CancelledRelease:
                highlightType = HighlightType.Blue;
                iconType = IconType.Warning;
                break;
            case ProductStatus.Released:
            case ProductStatus.InApproval:
            case ProductStatus.Approved:
            case ProductStatus.ApprovedByChiefEditor:
                highlightType = HighlightType.Green;
                iconType = IconType.Check;
                break;
            case ProductStatus.Rejected:
            case ProductStatus.RejectedByChiefEditor:
                highlightType = HighlightType.Red;
                iconType = IconType.Close;
                comment = hasReviewComment
                    ? product.comments.find(x => x.type === CommentType.ReviewComment).comment
                    : null;
                break;
            case ProductStatus.RejectedByCustomer:
                highlightType = HighlightType.Red;
                iconType = IconType.Close;
                comment = hasRejectComment
                    ? product.comments.find(x => x.type === CommentType.RejectedReleaseComment).comment
                    : null;
                break;
            default:
                break;
        }

        return (
            <>
                <Highlight type={highlightType}>
                    <Icon type={iconType} />
                    {translate(translations.product.status[product.status.toString() as keyof typeof TRANSLATIONS.product.status])}
                </Highlight>
                {comment &&
                    <>
                        <span data-tip={ContentService.removeTags(comment)} data-for='comment-tooltip'>
                            {ContentService.removeTags(comment)}
                        </span>
                        <ReactTooltip id='comment-tooltip' className='react-tooltip' delayHide={200} />
                    </>
                }
            </>
        );
    };

    const renderDate = (product: Product) => {
        const { relevantDate, dateTypeText } = getRelevantDate(product);
        
        return relevantDate && (
            <>
                <div>{DateService.toString(relevantDate)}</div>
                <i>({dateTypeText})</i>
            </>
        );
    };

    /*const renderAvailableLanguages = (product: Product) => {
        const languagesToShow = product.machineCollectionLanguages ? product.machineCollectionLanguages : LANGUAGES.filter(x => product.description[x]);
        const highlighted = (language: string) => product.machineCollectionLanguages ? !product.description[language] : false;
        
        return (
            <Flex>
                {languagesToShow.map(x => <Chip key={x} id={x} name={x.toUpperCase()} highlighted={highlighted(x)} translate={translate} noClose isDisabled />)}
            </Flex>
        );
    }*/

    const renderRowActions = (product: Product, tableName: ProductTableName) => {
        const copyTooltip = 'copy-tooltip';
        const deleteTooltip = 'delete-tooltip';
        const editTooltip = 'edit-tooltip';
        const emailTooltip = 'email-tooltip';
        const previewTooltip = 'preview-tooltip';
        const releaseTooltip = 'release-tooltip';
        const reviewTooltip = 'review-tooltip';

        const isReviewRequestTable = [ProductTableName.ProductRequests, ProductTableName.ChiefEditorProducts].some(x => x === tableName);
        const isSiemensRepresentativeTable = [ProductTableName.InReviewProducts, ProductTableName.ReviewedProducts, ProductTableName.IncompleteProducts].some(x => x == tableName);
        
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} wrap={FlexWrap.Wrap} gap={5}>
                {!isSiemensRepresentativeTable && (
                    <>
                        {!isReviewRequestTable &&
                            <>
                                {tableName === ProductTableName.MyMachines && (
                                    <Tooltip id={deleteTooltip} text={translate(translations.action.delete)}>
                                        <Button dark={dark} type={ButtonType.Secondary} onClick={() => openDeleteDialog(product)}>
                                            <Icon type={IconType.Trash} />
                                        </Button>
                                    </Tooltip>
                                )}
                                <Tooltip id={copyTooltip} text={translate(translations.action.copy)}>
                                    <Button dark={dark} type={ButtonType.Secondary} onClick={() => onProductCopy(product.id)}>
                                        <Icon type={IconType.Copy} />
                                    </Button>
                                </Tooltip>
                            </>
                        }
                        {product.status === ProductStatus.Incomplete &&
                            <>
                                <Tooltip id={editTooltip} text={translate(translations.action.edit)}>
                                    <Button dark={dark} type={ButtonType.Primary} onClick={() => navigate(`/productwizard/${product.id}`)}>
                                        <Icon type={IconType.Edit} />
                                    </Button>
                                </Tooltip>
                            </>
                        }
                        {product.status === ProductStatus.Draft &&
                            <>
                                <Tooltip id={editTooltip} text={translate(translations.action.edit)}>
                                    <Button dark={dark} type={ButtonType.Secondary} onClick={() => navigate(`/productwizard/${product.id}`)}>
                                        <Icon type={IconType.Edit} />
                                    </Button>
                                </Tooltip>
                                <Tooltip id={releaseTooltip} text={translate(translations.action.release)}>
                                    <Button dark={dark} type={ButtonType.Primary} onClick={() => openReleaseProductWizard(product.id)}>
                                        <Icon type={IconType.Check} />
                                    </Button>
                                </Tooltip>
                            </>
                        }
                        {product.status === ProductStatus.CancelledRelease &&
                            <>
                                <Tooltip id={releaseTooltip} text={translate(tableName !== ProductTableName.SharedMachines ? translations.action.release : translations.action.edit)}>
                                    <Button dark={dark} type={ButtonType.Primary} onClick={() => navigate(`/productreleasewizard/${product.id}${tableName !== ProductTableName.SharedMachines ? '' : '?editing=true'}`)}>
                                    <Icon type={tableName !== ProductTableName.SharedMachines ? IconType.Check : IconType.Edit} />
                                    </Button>
                                </Tooltip>
                            </>
                        }
                        {[ProductStatus.Released, ProductStatus.InApproval, ProductStatus.Approved].some(x => product.status === x) &&
                            <>
                                {!isReviewRequestTable &&
                                    <Tooltip id={emailTooltip} text={translate(translations.action.resendNotification)}>
                                        <Button dark={dark} type={ButtonType.Secondary} onClick={() => handleResendEmailForProduct(product.id)}>
                                            <Icon type={IconType.Email} />
                                        </Button>
                                    </Tooltip>
                                }
                                {ProductStatus.InApproval && tableName == ProductTableName.SharedMachines &&
                                    <Tooltip id={reviewTooltip} text={translate(translations.action.review)}>
                                        <Button dark={dark} type={ButtonType.Primary} onClick={() => handleApprovalRedirect(product.id)}>
                                            <Icon type={IconType.Check} />
                                        </Button>
                                    </Tooltip>
                                }
                                {isReviewRequestTable &&
                                    <Tooltip id={reviewTooltip} text={translate(translations.action.review)}>
                                        <Button dark={dark} type={ButtonType.Primary} onClick={() => navigate(`/product/${product.id}/review`)}>
                                            <Icon type={IconType.Chat} />
                                        </Button>
                                    </Tooltip>
                                }
                            </>
                        }
                        {[ProductStatus.Rejected, ProductStatus.RejectedByChiefEditor, ProductStatus.RejectedByCustomer].some(x => product.status === x) &&
                            <>
                                {!isReviewRequestTable && 
                                    <Tooltip id={releaseTooltip} text={translate(translations.action.release)}>
                                        <Button dark={dark} type={ButtonType.Primary} onClick={() => navigate(`/productreleasewizard/${product.id}`)}>
                                            <Icon type={IconType.Edit} />
                                        </Button>
                                    </Tooltip>
                                }
                                {isReviewRequestTable && !product.customerApproverEmail &&
                                    <Tooltip id={reviewTooltip} text={translate(translations.action.review)}>
                                        <Button dark={dark} type={ButtonType.Primary} onClick={() => navigate(`/product/${product.id}/review`)}>
                                            <Icon type={IconType.Chat} />
                                        </Button>
                                    </Tooltip>
                                }
                            </>
                        }
                        {product.status === ProductStatus.ApprovedByChiefEditor &&
                            <>
                                {tableName == ProductTableName.MyMachines &&
                                    <Tooltip id={editTooltip} text={translate(translations.action.edit)}>
                                        <Button dark={dark} type={ButtonType.Primary} onClick={() => openEditDialog(product.id)}>
                                            <Icon type={IconType.Edit} />
                                        </Button>
                                    </Tooltip>
                                }
                            </>
                        }
                        <Tooltip id={previewTooltip} text={translate(translations.action.preview)}>
                            <Button dark={dark} type={ButtonType.Primary} onClick={() => navigate(`/product/${product.id}`)}>
                                <Icon type={IconType.Search} />
                            </Button>
                        </Tooltip>
                    </>
                )}
                {isSiemensRepresentativeTable && (
                    <>
                        {product.status === ProductStatus.ApprovedByChiefEditor && chiefEditorProducts && chiefEditorProducts.items &&
                            <Tooltip id={editTooltip} text={translate(translations.action.edit)}>
                                <Button dark={dark} type={ButtonType.Primary} onClick={() => navigate(`/productreleasewizard/${product.id}`)}>
                                    <Icon type={IconType.Edit} />
                                </Button>
                            </Tooltip>
                        }
                        {product.status === ProductStatus.CancelledRelease && product.company.siemensRepresentativeEmails.some(x => x.toLowerCase() === AuthService.getUserEmail().toLowerCase()) &&
                            <>
                                <Tooltip id={editTooltip} text={translate(translations.action.edit)}>
                                    <Button dark={dark} type={ButtonType.Primary} onClick={() => navigate(`/productreleasewizard/${product.id}${tableName === ProductTableName.IncompleteProducts ? '?editing=true' : ''}`)}>
                                        <Icon type={IconType.Edit} />
                                    </Button>
                                </Tooltip>
                            </>
                        }
                        {[ProductTableName.IncompleteProducts, ProductTableName.InReviewProducts].some(x => tableName === x) &&
                            <Tooltip id={copyTooltip} text={translate(translations.action.copy)}>
                                <Button dark={dark} type={ButtonType.Secondary} onClick={() => onProductCopy(product.id)}>
                                    <Icon type={IconType.Copy} />
                                </Button>
                            </Tooltip>
                        }
                        <Tooltip id={previewTooltip} text={translate(translations.action.preview)}>
                            <Button dark={dark} type={ButtonType.Primary} onClick={() => navigate(`/product/${product.id}`)}>
                                <Icon type={IconType.Search} />
                            </Button>
                        </Tooltip>
                        {[ProductStatus.Released, ProductStatus.InApproval, ProductStatus.Approved].some(x => product.status === x) &&
                            <Tooltip id={emailTooltip} text={translate(translations.action.resendNotification)}>
                                <Button dark={dark} type={ButtonType.Secondary} onClick={() => handleResendEmailForProduct(product.id)}>
                                    <Icon type={IconType.Email} />
                                </Button>
                            </Tooltip>
                        }
                    </>
                )}
            </Flex>
        );
    };

    /*const renderTwinCheckbox = (product: Product) => {
        return product.status === ProductStatus.ApprovedByChiefEditor &&
            <Flex direction={FlexDirection.Row} justification={FlexJustification.Center}>
                <Checkbox dark={dark} checked={product.isDigitalTwinAvailable} label={''} onChange={() =>
                    onToggleDigitalTwinProduct(product.id, !product.isDigitalTwinAvailable)} />
            </Flex>;
        
    };*/
    
    const renderFairs = (product: Product, readOnly: boolean) => {
        const hasAllTags = tags.every(x => product.tags && product.tags.some(y => y.id === x.id));

        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexStart} wrap={FlexWrap.Wrap}>
                {product.tags &&
                    product.tags.map(x => <FairTag key={x.id} id={x.id} text={x.name} color={x.color} handleOnClick={!readOnly ? () => handleRemoveTagFromProduct(product.id, x.id) : null}
                                                   noClose={readOnly} />)
                }
                {!readOnly && !hasAllTags &&
                    <Button dark={dark} type={ButtonType.Secondary} onClick={() => openTagPickerModal(product.id)}>
                        <Icon type={IconType.Plus} />
                    </Button>
                }
            </Flex>
        );
    };

    const renderButton = () => {
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexStart}>
                <Button dark={dark} type={ButtonType.Primary} onClick={() => openReleaseProductWizard(null)}>
                    <Icon type={IconType.Plus} />
                    {translate(translations.action.createMachine)}
                </Button>
            </Flex>
        );
    };

    const renderTable = (products: PaginatedList<Product>, sortedIndex: number, reverse: boolean, 
                         setIndex: (x: number) => void, setReverse: (x: boolean) => void, tableName: ProductTableName, initialSize?: number) => {
        const currentOrderBy = products && products.orderBy ? products.orderBy : 'LastChangeDate';
        let currentOrderType = products && products.orderType ? products.orderType : 'DESC';
        const isChiefEditor = machineCollections.length > 0 && machineCollections.find(x => x.id === machineCollectionId).chiefEditorEmails.includes(AuthService.getUserEmail());
        
        const columns = tableName === ProductTableName.ReviewedProducts && isChiefEditor
            ? [
            { label: '', min: '100px', max: '100px', notClickable: true },
            { label: translate(translations.product.machineName), min: '150px', max: '3fr' },
            { label: translate(translations.company.company), min: '150px', max: '3fr' },
            { label: translate(translations.common.status), min: '150px', max: '3fr' },
            { label: translate(translations.common.date), min: '175px', max: '3fr' },
            { label: translate(translations.common.fair), min: '200px', max: '3fr', notClickable: true },
            //{ label: translate(translations.common.enableDigitalTwin), min: '90px', max: '1fr', notClickable: true },
            { label: '', min: '280px', max: '5fr', notClickable: true }
            ] 
            : [
                { label: '', min: '100px', max: '100px', notClickable: true },
                { label: translate(translations.product.machineName), min: '150px', max: '3fr' },
                { label: translate(translations.company.company), min: '150px', max: '3fr' },
                { label: translate(translations.common.status), min: '150px', max: '3fr' },
                { label: translate(translations.common.date), min: '175px', max: '3fr' },
                { label: translate(translations.common.fair), min: '200px', max: '3fr', notClickable: true },
                { label: '', min: '280px', max: '5fr', notClickable: true }
            ];
        
        const rows = products && products.items ?
            tableName === ProductTableName.ReviewedProducts && isChiefEditor
                ? [
                    ...products.items.map<Row>(x => (
                        {
                            key: x.id,
                            cells: [
                                { content: renderImage(x) },
                                { content: renderTitle(x, tableName) },
                                { content: renderCompany(x) },
                                { content: renderStatus(x) },
                                { content: renderDate(x) },
                                { content: renderFairs(x, true) },
                                //{ content: renderTwinCheckbox(x) },
                                { content: renderRowActions(x, tableName) }]
                        }))
                ]
                : [
                    ...products.items.map<Row>(x => (
                        {
                            key: x.id,
                            cells: [
                                { content: renderImage(x) },
                                { content: renderTitle(x, tableName) },
                                { content: renderCompany(x) },
                                { content: renderStatus(x) },
                                { content: renderDate(x) },
                                { content: renderFairs(x, tableName !== ProductTableName.MyMachines) },
                                { content: renderRowActions(x, tableName) }]
                        }))
                ]
            : [];

        const handleHeaderClick = (index: number) => {
            setIndex(index);

            switch (index) {
                case 1:
                    currentOrderType = getOrderType(currentOrderBy, 'Title', currentOrderType, setReverse);
                    onGetProducts(0, 0, 'Title', currentOrderType, tableName);
                    break;
                case 2:
                    currentOrderType = getOrderType(currentOrderBy, 'CompanyName', currentOrderType, setReverse);
                    onGetProducts(0, 0, 'CompanyName', currentOrderType, tableName);
                    break;
                case 3:
                    currentOrderType = getOrderType(currentOrderBy, 'Status', currentOrderType, setReverse);
                    onGetProducts(0, 0, 'Status', currentOrderType, tableName);
                    break;
                case 4:
                    currentOrderType = getOrderType(currentOrderBy, 'LastChangeDate', currentOrderType, setReverse);
                    onGetProducts(0, 0, 'LastChangeDate', currentOrderType, tableName);
                    break;
                default:
                    break;
            }
        };

        return products && (
            <>
                <Table
                    dark={dark}
                    columns={columns}
                    rows={rows}
                    indexSortedBy={sortedIndex}
                    reverse={reverse}
                    onHeaderClick={handleHeaderClick}
                />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.Center} style={{ marginTop: 10, marginBottom: 10 }}>
                    <div>
                        {translate(translations.product.rowsPerPage)}:
                        <Select dark={dark} value={initialSize} values={[5, 10, 20, 50]} mapToString={x => x.toString()} 
                                onSelect={x => onGetProducts(0, x, products.orderBy, products.orderType, tableName)} label={''} />
                    </div>
                </Flex>
                <Flex direction={FlexDirection.Row} justification={FlexJustification.Center} style={{ marginTop: 10, marginBottom: 40 }}>
                    <Pagination dark={dark} activePage={(products.offset / products.limit)} count={Math.ceil(products.count / products.limit)}
                        onChange={x => onGetProducts(x * products.limit, products.limit, products.orderBy, products.orderType, tableName)} />
                </Flex>
            </>
        );
    };

    const renderPage = () => {
        const isRepresentativeOrAdmin = AuthService.hasRole([ROLES.SIEMENS_REPRESENTATIVE, ROLES.ADMIN]);
        const shouldShowChiefEditorTables = machineCollections.length > 0 
            && machineCollections.find(x => x.id === machineCollectionId).chiefEditorEmails.includes(AuthService.getUserEmail());

        return (
            <>
                <ContentContainer style={{ marginTop: 30 }}>
                    {renderButton()}
                </ContentContainer>
                <ContentContainer>
                    {isRepresentativeOrAdmin &&
                        <>
                            <Title style={{ marginLeft: 20, marginTop: 30 }} text={translate(translations.common.requests)} bold />
                            <Loader dark={dark} loading={productRequestsLoading}>
                            <Section color={dark ? SectionColor.DeepBlue : SectionColor.White} style={{ paddingBottom: 30 }}>
                                {renderTable(productRequests, requestsSortedByIndex, requestsReverse, setRequestsSortedByIndex,
                                    setRequestsReverse, ProductTableName.ProductRequests, rowValues.requestTable)}
                                </Section>
                            </Loader>
                        </>
                    }
                    {shouldShowChiefEditorTables &&
                        <>
                            <Title style={{ marginLeft: 20, marginTop: 30 }} text={translate(translations.common.chiefEditorRequests)} bold />
                            <Loader dark={dark} loading={chiefEditorProductsLoading}>
                                <Section color={dark ? SectionColor.DeepBlue : SectionColor.White} style={{ paddingBottom: 30 }}>
                                {renderTable(chiefEditorProducts, chiefEditorSortedByIndex, chiefEditorReverse, 
                                    setChiefEditorSortedByIndex, setChiefEditorReverse, ProductTableName.ChiefEditorProducts, rowValues.chiefEditorTable)}
                                </Section>
                            </Loader>
                        </>
                    }
                    <>
                        <Title style={{ marginLeft: 20, marginTop: 30 }} text={translate(translations.product.myMachines)} bold />
                        <Loader dark={dark} loading={productsLoading}>
                            <Section color={dark ? SectionColor.DeepBlue : SectionColor.White} style={{ paddingBottom: `${isRepresentativeOrAdmin ? 30 : 50}px` }}>
                                {renderTable(products, myMachinesSortedByIndex, myMachinesReverse, setMyMachinesSortedByIndex,
                                    setMyMachinesReverse, ProductTableName.MyMachines, rowValues.myMachinesTable)}
                            </Section>
                         </Loader>
                    </>
                    {!isRepresentativeOrAdmin && sharedMachines && sharedMachines.items.length > 0 && (
                        <>
                            <Title style={{ marginLeft: 20, marginTop: 30 }} text={'Machines shared with me'} bold />
                            <Loader dark={dark} loading={sharedMachinesLoading}>
                                <Section color={dark ? SectionColor.DeepBlue : SectionColor.White} style={{ paddingBottom: `${isRepresentativeOrAdmin ? 30 : 50}px` }}>
                                    {renderTable(sharedMachines, sharedMachinesSortedByIndex, sharedMachinesReverse, setSharedMachinesSortedByIndex,
                                        setSharedMachinesLoading, ProductTableName.SharedMachines, rowValues.sharedMachines)}
                                </Section>
                            </Loader>
                        </>
                    )}
                    {isRepresentativeOrAdmin && (
                        <>
                            <Title style={{ marginLeft: 20, marginTop: 30 }} text={translate(translations.product.customerMachinesInCreation)} bold />
                            <Loader dark={dark} loading={incompleteProductsLoading}>
                                <Section color={dark ? SectionColor.DeepBlue : SectionColor.White} style={{ paddingBottom: 30 }}>
                                    {renderTable(incompleteProducts, incompleteSortedByIndex, incompleteReverse, 
                                        setIncompleteSortedByIndex, setIncompleteReverse, ProductTableName.IncompleteProducts, rowValues.incompleteTable)}
                                </Section>
                            </Loader>
                            {shouldShowChiefEditorTables && (
                                <>
                                    <Title style={{ marginLeft: 20, marginTop: 30 }} text={translate(translations.product.customerMachinesInReview)} bold />
                                    <Loader dark={dark} loading={inReviewProductsLoading}>
                                        <Section color={dark ? SectionColor.DeepBlue : SectionColor.White} style={{ paddingBottom: 30 }}>
                                            {renderTable(inReviewProducts, inReviewSortedByIndex, inReviewReverse, 
                                                setInReviewSortedByIndex, setInReviewReverse, ProductTableName.InReviewProducts, rowValues.inReviewTable)}
                                        </Section>
                                    </Loader>
                                </>
                            )
                            }
                            <Title style={{ marginLeft: 20, marginTop: 30 }} text={translate(translations.product.reviewedMachines)} bold />
                            <Loader dark={dark} loading={reviewedProductsLoading}>
                                <Section color={dark ? SectionColor.DeepBlue : SectionColor.White} style={{ paddingBottom: 50 }}>
                                    {renderTable(reviewedProducts, reviewedSortedByIndex, reviewedReverse, setReviewedSortedByIndex,
                                        setReviewedReverse, ProductTableName.ReviewedProducts, rowValues.reviewedTable)}
                                </Section>
                            </Loader>
                        </>
                    )
                    }
                </ContentContainer>

                {renderTargetModal()}
                {renderTagPickerModal()}
                {renderDeleteDialog()}
                {renderEditDialog()}
            </>
        );
    };

    return renderPage();
};
export default ProductTable;
