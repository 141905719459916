import React, { KeyboardEvent } from 'react';

interface RadioButtonProps {
    label: string;
    selected: boolean;
    onChange: (value: boolean) => void;
    dark?: boolean;
    disabled?: boolean;
}

const RadioButton = ({ dark, disabled, label, selected, onChange }: RadioButtonProps) => {
    const handleChange = () => {
        if (!disabled) {
            onChange(!selected);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === ' ') {
            event.preventDefault();
            handleChange();
        }
    };

    return (
        <div className={`radio-button-container ${disabled ? 'disabled' : ''} ${dark ? 'dark' : ''}`} tabIndex={disabled ? undefined : 0} onClick={handleChange} onKeyDown={handleKeyDown}>
            <div className={`radio-button ${selected ? 'selected' : ''}`} />
            <div className='radio-button-label'>
                {label}
            </div>
        </div>
    );
};

export default RadioButton;
