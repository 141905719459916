import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Api, apiCall, apiCalls, AuthService, CategoryService, CollectionService, getFormData } from '../../../services';
import { Categories, Hall, MachineCollectionCreation } from '../../../models';
import { MachineCollectionType, HeaderTabType } from '../../../enums';
import { EMPTY_GUID, EMPTY_MACHINE_COLLECTION, HALL_FOLDER, MACHINE_COLLECTION_FOLDER, TRANSLATIONS } from '../../../constants';
import MachineCollectionWizard from './MachineCollectionWizard';
import Loader from '../../common/Loader';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const MachineCollectionWizardManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const dispatch = useDispatch();
    const location = useLocation();
    const match = useMatch('/collections/collectionwizard/:id');
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [accordionsOpen, setAccordionsOpen] = useState(null);
    const [categories, setCategories] = useState(null);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [machineCollection, setMachineCollection] = useState(null);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        dispatch(setActiveTabType(HeaderTabType.MyMachineCollections));
        window.scrollTo(0, 0);

        (async () => {
            const startingUrl = window.location.pathname;

            dispatch(setLoading(true));
            let resultCategories: Categories;

            const categoryPromise = apiCall(
                Api.getCategories(),
                async x => {
                    setAccordionsOpen(Array<boolean>(x.data.groups.length).fill(true));
                    setCategories(x.data);
                    resultCategories = x.data;
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.categoryLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );

            const id = match?.params?.id;

            const machineCollectionPromise = id
                ? apiCall(
                    Api.getEditableMachineCollection(id),
                    async x => {
                        await categoryPromise;

                        const machineCollection = x.data;

                        setMachineCollection({
                                ...CollectionService.convertDates(machineCollection),
                                categoryGroups: [
                                    ...machineCollection.categoryGroups,
                                    ...CategoryService.getCollectionCategoryGroups(resultCategories)
                                        .filter(y => machineCollection.categoryGroups.every(z => z.groupId !== y.groupId))
                                ]
                            }
                        );
                        setImageUrl(machineCollection.imageUrl);
                    },
                    async () => {
                        setMachineCollection(EMPTY_MACHINE_COLLECTION);
                        dispatch(addSnackbar({ text: translate(translations.error.collectionLoad).toString(), type: SnackbarType.Error, dark: dark }));
                    }
                )
                : (async () => {
                    await categoryPromise;

                    setMachineCollection({
                            ...EMPTY_MACHINE_COLLECTION,
                            type: getMachineCollectionType(),
                            categoryGroups: CategoryService.getCollectionCategoryGroups(resultCategories)
                        }
                    );
                })();

            await categoryPromise;
            await machineCollectionPromise;

            if (startingUrl === window.location.pathname) {
                dispatch(setLoading(false));
            }
        })();
    }, []);

    const handleCollectionChange = async (newMachineCollection: Partial<MachineCollectionCreation>) => {
        return setMachineCollection({ ...machineCollection, ...newMachineCollection });
    };

    const handleAccordionToggle = (index: number) => setAccordionsOpen(accordionsOpen.map((x: boolean, i: number) => i === index ? !x : x));

    const handleFileReceived = (file: File) => {
        if (!image || image.name !== file.name) {
            setImage(file);

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = x => setImageUrl(x.target['result'] as string);
        }
    };

    const handleHallAdd = async (hall: Hall) => {
        return setMachineCollection({ ...machineCollection, halls: [...machineCollection.halls, hall] });
    };

    const handleHallChange = async (index: number, hall: Hall) => {
        return setMachineCollection({ ...machineCollection, halls: machineCollection.halls.map((x: Hall, i: number) => i !== index ? x : hall) });
    };

    const handleHallDelete = async (index: number) => {
        return setMachineCollection({ ...machineCollection, halls: machineCollection.halls.filter((_: unknown, i: number) => i !== index) });
    };

    const handleWizardSubmit = async () => {
        dispatch(setLoading(true));

        let imageId = machineCollection.imageId;
        let hallImageIds: string[] = [];

        if (image) {
            await apiCall(
                Api.saveImage(MACHINE_COLLECTION_FOLDER, getFormData(image)),
                async x => {
                    imageId = x.data;
                },
                async () => {
                    imageId = null;
                    dispatch(addSnackbar({ text: translate(translations.error.imageSave).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );
        }

        if (machineCollection.halls.some((x: { image: File; }) => Boolean(x.image))) {
            await apiCalls(
                Promise.all(machineCollection.halls.filter((x: { image: File; }) => x.image).map((x: { image: File; }) => Api.saveImage(HALL_FOLDER, getFormData(x.image)))),
                async x => {
                    hallImageIds = x.map(y => y.data);
                },
                async () => {
                    hallImageIds = null;
                    dispatch(addSnackbar({ text: translate(translations.error.imageSave).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );
        }

        if (imageId && hallImageIds) {
            const machineCollectionToSave: MachineCollectionCreation = {
                ...machineCollection,
                imageId,
                halls: machineCollection.halls.map((x: Hall) => ({ ...x, imageUrl: '', image: null as File, imageId: x.image ? hallImageIds.shift() : x.imageId })),
                creatorId: AuthService.getUserId()
            };

            await apiCall(
                machineCollection.id === EMPTY_GUID ? Api.saveMachineCollection(machineCollectionToSave) : Api.updateMachineCollection(machineCollectionToSave),
                async () => {
                    setShouldRedirect(true);
                    dispatch(addSnackbar({ text: translate(translations.dialog.successfulSave).toString(), type: SnackbarType.Success, dark: dark }));
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.collectionSave).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );
        }

        dispatch(setLoading(false));
    };

    const getMachineCollectionType = () => {
        const type = Number(queryString.parse(location.search)['type']);

        return [MachineCollectionType.EBooklet, MachineCollectionType.MachineCompass].some(x => x === type) ? type as MachineCollectionType : MachineCollectionType.EBooklet;
    };

    const renderWizard = () => {
        return (
            <>
                <div className={`compass-section ${dark ? 'dark' : ''}`}>
                    <Loader dark={dark} loading={isLoading}>
                        <MachineCollectionWizard accordionsOpen={accordionsOpen} categories={categories} image={image} imageUrl={imageUrl} machineCollection={machineCollection}
                            onAccordionToggle={handleAccordionToggle} onCollectionChange={handleCollectionChange} onFileReceived={handleFileReceived} onHallAdd={handleHallAdd}
                            onHallChange={handleHallChange} onHallDelete={handleHallDelete} onWizardSubmit={handleWizardSubmit}
                        />
                        {shouldRedirect && <Navigate to='/collections' />}
                    </Loader>
                </div>
            </>
        );
    };

    return renderWizard();
};
export default MachineCollectionWizardManager;
