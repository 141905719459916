import Andorra from '../../assets/images/flags/ad.png';
import Afghanistan from '../../assets/images/flags/af.png';
import AlandIslands from '../../assets/images/flags/ax.png';
import Albania from '../../assets/images/flags/al.png';
import Algeria from '../../assets/images/flags/dz.png';
import AmericanSamoa from '../../assets/images/flags/as.png';
import Angola from '../../assets/images/flags/ao.png';
import Anguilla from '../../assets/images/flags/ai.png';
import AntiguaBarbuda from '../../assets/images/flags/ag.png';
import Argentina from '../../assets/images/flags/ar.png';
import Armenia from '../../assets/images/flags/am.png';
import Aruba from '../../assets/images/flags/aw.png';
import Australia from '../../assets/images/flags/au.png';
import Austria from '../../assets/images/flags/at.png';
import Azerbaijan from '../../assets/images/flags/az.png';
import Bahamas from '../../assets/images/flags/bs.png';
import Bahrain from '../../assets/images/flags/bh.png';
import Bangladesh from '../../assets/images/flags/bd.png';
import Barbados from '../../assets/images/flags/bb.png';
import Belarus from '../../assets/images/flags/by.png';
import Belgium from '../../assets/images/flags/be.png';
import Belize from '../../assets/images/flags/bz.png';
import Benin from '../../assets/images/flags/bj.png';
import Bermuda from '../../assets/images/flags/bm.png';
import Bhutan from '../../assets/images/flags/bt.png';
import Bolivia from '../../assets/images/flags/bo.png';
import Bosnia from '../../assets/images/flags/ba.png';
import Botswana from '../../assets/images/flags/bw.png';
import Brazil from '../../assets/images/flags/br.png';
import BritishIndia from '../../assets/images/flags/io.png';
import Brunei from '../../assets/images/flags/bn.png';
import Bulgaria from '../../assets/images/flags/bg.png';
import Burkina from '../../assets/images/flags/bf.png';
import Burundi from '../../assets/images/flags/bi.png';
import Cambodia from '../../assets/images/flags/kh.png';
import Cameroon from '../../assets/images/flags/cm.png';
import Canada from '../../assets/images/flags/ca.png';
import CapeVerde from '../../assets/images/flags/cv.png';
import CaymanIslands from '../../assets/images/flags/ky.png';
import CentralAfricanRepublic from '../../assets/images/flags/cf.png';
import Chad from '../../assets/images/flags/td.png';
import Chile from '../../assets/images/flags/cl.png';
import China from '../../assets/images/flags/cn.png';
import ChristmasIsland from '../../assets/images/flags/cx.png';
import CocosIslands from '../../assets/images/flags/cc.png';
import Colombia from '../../assets/images/flags/co.png';
import Comoros from '../../assets/images/flags/km.png';
import Congo from '../../assets/images/flags/cg.png';
import CookIslands from '../../assets/images/flags/ck.png';
import CostaRica from '../../assets/images/flags/cr.png';
import CoteDivoire from '../../assets/images/flags/ci.png';
import Croatia from '../../assets/images/flags/hr.png';
import Cuba from '../../assets/images/flags/cu.png';
import Cyprus from '../../assets/images/flags/cy.png';
import Czechia from '../../assets/images/flags/cz.png';
import Denmark from '../../assets/images/flags/dk.png';
import Djibouti from '../../assets/images/flags/dj.png';
import Dominica from '../../assets/images/flags/dm.png';
import DominicanRepublic from '../../assets/images/flags/do.png';
import Ecuador from '../../assets/images/flags/ec.png';
import Egypt from '../../assets/images/flags/eg.png';
import ElSalvador from '../../assets/images/flags/sv.png';
import EquatorialGuinea from '../../assets/images/flags/gq.png';
import Eritrea from '../../assets/images/flags/er.png';
import Estonia from '../../assets/images/flags/ee.png';
import Ethiopia from '../../assets/images/flags/et.png';
import FalklandIslands from '../../assets/images/flags/fk.png';
import FaroeIslands from '../../assets/images/flags/fo.png';
import Fiji from '../../assets/images/flags/fj.png';
import Finland from '../../assets/images/flags/fi.png';
import France from '../../assets/images/flags/fr.png';
import FrenchPolynesia from '../../assets/images/flags/pf.png';
import FrenchSouthernTerritories from '../../assets/images/flags/tf.png';
import Gabon from '../../assets/images/flags/ga.png';
import Gambia from '../../assets/images/flags/gm.png';
import Georgia from '../../assets/images/flags/ge.png';
import Germany from '../../assets/images/flags/de.png';
import Ghana from '../../assets/images/flags/gh.png';
import Gibraltar from '../../assets/images/flags/gi.png';
import Greece from '../../assets/images/flags/gr.png';
import Greenland from '../../assets/images/flags/gl.png';
import Grenada from '../../assets/images/flags/gd.png';
import Guadeloupe from '../../assets/images/flags/gp.png';
import Guam from '../../assets/images/flags/gu.png';
import Guatemala from '../../assets/images/flags/gt.png';
import Guinea from '../../assets/images/flags/gn.png';
import GuineaBissau from '../../assets/images/flags/gw.png';
import Guyana from '../../assets/images/flags/gy.png';
import Haiti from '../../assets/images/flags/ht.png';
import HolySee from '../../assets/images/flags/va.png';
import Honduras from '../../assets/images/flags/hn.png';
import HongKong from '../../assets/images/flags/hk.png';
import Hungary from '../../assets/images/flags/hu.png';
import Iceland from '../../assets/images/flags/is.png';
import India from '../../assets/images/flags/in.png';
import Indonesia from '../../assets/images/flags/id.png';
import Iran from '../../assets/images/flags/ir.png';
import Iraq from '../../assets/images/flags/iq.png';
import Ireland from '../../assets/images/flags/ie.png';
import Israel from '../../assets/images/flags/il.png';
import Italy from '../../assets/images/flags/it.png';
import Jamaica from '../../assets/images/flags/jm.png';
import Japan from '../../assets/images/flags/jp.png';
import Jordan from '../../assets/images/flags/jo.png';
import Kazakhstan from '../../assets/images/flags/kz.png';
import Kenya from '../../assets/images/flags/ke.png';
import Kiribati from '../../assets/images/flags/ki.png';
import Kuwait from '../../assets/images/flags/kw.png';
import Kyrgyzstan from '../../assets/images/flags/kg.png';
import Laos from '../../assets/images/flags/la.png';
import Latvia from '../../assets/images/flags/lv.png';
import Lebanon from '../../assets/images/flags/lb.png';
import Lesotho from '../../assets/images/flags/ls.png';
import Liberia from '../../assets/images/flags/lr.png';
import LibyanJamahiriya from '../../assets/images/flags/ly.png';
import Liechtenstein from '../../assets/images/flags/li.png';
import Lithuania from '../../assets/images/flags/lt.png';
import Luxembourg from '../../assets/images/flags/lu.png';
import Macao from '../../assets/images/flags/mo.png';
import Macedonia from '../../assets/images/flags/mk.png';
import Madagascar from '../../assets/images/flags/mg.png';
import Malawi from '../../assets/images/flags/mw.png';
import Malaysia from '../../assets/images/flags/my.png';
import Maldives from '../../assets/images/flags/mv.png';
import Mali from '../../assets/images/flags/ml.png';
import Malta from '../../assets/images/flags/mt.png';
import MarshallIslands from '../../assets/images/flags/mh.png';
import Martinique from '../../assets/images/flags/mq.png';
import Mauritania from '../../assets/images/flags/mr.png';
import Mauritius from '../../assets/images/flags/mu.png';
import Mayotte from '../../assets/images/flags/yt.png';
import Mexico from '../../assets/images/flags/mx.png';
import Micronesia from '../../assets/images/flags/fm.png';
import Moldova from '../../assets/images/flags/md.png';
import Monaco from '../../assets/images/flags/mc.png';
import Mongolia from '../../assets/images/flags/mn.png';
import Montenegro from '../../assets/images/flags/me.png';
import Montserrat from '../../assets/images/flags/ms.png';
import Morocco from '../../assets/images/flags/ma.png';
import Mozambique from '../../assets/images/flags/mz.png';
import Myanmar from '../../assets/images/flags/mm.png';
import Namibia from '../../assets/images/flags/na.png';
import Nauru from '../../assets/images/flags/nr.png';
import Nepal from '../../assets/images/flags/np.png';
import Netherlands from '../../assets/images/flags/nl.png';
import NetherlandsAntilles from '../../assets/images/flags/an.png';
import NewCaldonia from '../../assets/images/flags/nc.png';
import NewZealand from '../../assets/images/flags/nz.png';
import Nicaragua from '../../assets/images/flags/ni.png';
import Niger from '../../assets/images/flags/ne.png';
import Nigeria from '../../assets/images/flags/ng.png';
import Niue from '../../assets/images/flags/nu.png';
import NorfolkIsland from '../../assets/images/flags/nf.png';
import NorthernMariana from '../../assets/images/flags/mp.png';
import Norway from '../../assets/images/flags/no.png';
import Oman from '../../assets/images/flags/om.png';
import Pakistan from '../../assets/images/flags/pk.png';
import Palau from '../../assets/images/flags/pw.png';
import Palestine from '../../assets/images/flags/ps.png';
import Panama from '../../assets/images/flags/pa.png';
import PapuaNewGuinea from '../../assets/images/flags/pg.png';
import Paraguay from '../../assets/images/flags/py.png';
import Peru from '../../assets/images/flags/pe.png';
import Philippines from '../../assets/images/flags/ph.png';
import Pitcairn from '../../assets/images/flags/pn.png';
import Poland from '../../assets/images/flags/pl.png';
import Portugal from '../../assets/images/flags/pt.png';
import PuertoRico from '../../assets/images/flags/pr.png';
import Qatar from '../../assets/images/flags/qa.png';
import Romania from '../../assets/images/flags/ro.png';
import Russia from '../../assets/images/flags/ru.png';
import Rwanda from '../../assets/images/flags/rw.png';
import SaintHelena from '../../assets/images/flags/sh.png';
import SaintKittsNevis from '../../assets/images/flags/kn.png';
import SaintLucia from '../../assets/images/flags/lc.png';
import SaintPierreMiquelon from '../../assets/images/flags/pm.png';
import SaintVincentGrenadines from '../../assets/images/flags/vc.png';
import Samoa from '../../assets/images/flags/ws.png';
import SanMarino from '../../assets/images/flags/sm.png';
import SaoTomePrincipe from '../../assets/images/flags/st.png';
import SaudiArabia from '../../assets/images/flags/sa.png';
import Senegal from '../../assets/images/flags/sn.png';
import Serbia from '../../assets/images/flags/rs.png';
import Seychelles from '../../assets/images/flags/sc.png';
import SierraLeone from '../../assets/images/flags/sl.png';
import Singapore from '../../assets/images/flags/sg.png';
import Slovakia from '../../assets/images/flags/sk.png';
import Slovenia from '../../assets/images/flags/si.png';
import SolomonIslands from '../../assets/images/flags/sb.png';
import Somalia from '../../assets/images/flags/so.png';
import SouthAfrica from '../../assets/images/flags/za.png';
import SouthGeorgia from '../../assets/images/flags/gs.png';
import SouthKorea from '../../assets/images/flags/kr.png';
import Spain from '../../assets/images/flags/es.png';
import SriLanka from '../../assets/images/flags/lk.png';
import Sudan from '../../assets/images/flags/sd.png';
import Suriname from '../../assets/images/flags/sr.png';
import Swaziland from '../../assets/images/flags/sz.png';
import Sweden from '../../assets/images/flags/se.png';
import Switzerland from '../../assets/images/flags/ch.png';
import SyrianRepublic from '../../assets/images/flags/sy.png';
import Taiwan from '../../assets/images/flags/tw.png';
import Tajikistan from '../../assets/images/flags/tj.png';
import Tanzania from '../../assets/images/flags/tz.png';
import Thailand from '../../assets/images/flags/th.png';
import TimorLeste from '../../assets/images/flags/tl.png';
import Togo from '../../assets/images/flags/tg.png';
import Tokelau from '../../assets/images/flags/tk.png';
import Tonga from '../../assets/images/flags/to.png';
import TrinidadTobago from '../../assets/images/flags/tt.png';
import Tunisia from '../../assets/images/flags/tn.png';
import Turkey from '../../assets/images/flags/tr.png';
import Turkmenistan from '../../assets/images/flags/tm.png';
import TurksCaicosIslands from '../../assets/images/flags/tc.png';
import Tuvalu from '../../assets/images/flags/tv.png';
import Uganda from '../../assets/images/flags/ug.png';
import Ukraine from '../../assets/images/flags/ua.png';
import UnitedArabEmirates from '../../assets/images/flags/ae.png';
import UnitedKingdom from '../../assets/images/flags/gb.png';
import UnitedStates from '../../assets/images/flags/us.png';
import UnitedStatesIslands from '../../assets/images/flags/um.png';
import Uruguay from '../../assets/images/flags/uy.png';
import Uzbekistan from '../../assets/images/flags/uz.png';
import Vanuatu from '../../assets/images/flags/vu.png';
import Venezuela from '../../assets/images/flags/ve.png';
import Vietnam from '../../assets/images/flags/vn.png';
import VirginIslandsBritish from '../../assets/images/flags/vg.png';
import VirginIslandsUS from '../../assets/images/flags/vi.png';
import WallisFutuna from '../../assets/images/flags/wf.png';
import WesternSahara from '../../assets/images/flags/eh.png';
import Yemen from '../../assets/images/flags/ye.png';
import Zambia from '../../assets/images/flags/zm.png';
import Zimbabwe from '../../assets/images/flags/zw.png';
import { Dictionary } from '../models';

export const IMAGES: Dictionary<string> = {
    'af': Afghanistan,
    'ax': AlandIslands,
    'al': Albania,
    'dz': Algeria,
    'as': AmericanSamoa,
    'ad': Andorra,
    'ao': Angola,
    'ai': Anguilla,
    'ag': AntiguaBarbuda,
    'ar': Argentina,
    'am': Armenia,
    'aw': Aruba,
    'au': Australia,
    'at': Austria,
    'az': Azerbaijan,
    'bs': Bahamas,
    'bh': Bahrain,
    'bd': Bangladesh,
    'bb': Barbados,
    'by': Belarus,
    'be': Belgium,
    'bz': Belize,
    'bj': Benin,
    'bm': Bermuda,
    'bt': Bhutan,
    'bo': Bolivia,
    'ba': Bosnia,
    'bw': Botswana,
    'br': Brazil,
    'io': BritishIndia,
    'bn': Brunei,
    'bg': Bulgaria,
    'bf': Burkina,
    'bi': Burundi,
    'kh': Cambodia,
    'cm': Cameroon,
    'ca': Canada,
    'cv': CapeVerde,
    'ky': CaymanIslands,
    'cf': CentralAfricanRepublic,
    'td': Chad,
    'cl': Chile,
    'cn': China,
    'cx': ChristmasIsland,
    'cc': CocosIslands,
    'co': Colombia,
    'km': Comoros,
    'cg': Congo,
    'ck': CookIslands,
    'cr': CostaRica,
    'ci': CoteDivoire,
    'hr': Croatia,
    'cu': Cuba,
    'cy': Cyprus,
    'cz': Czechia,
    'dk': Denmark,
    'dj': Djibouti,
    'dm': Dominica,
    'do': DominicanRepublic,
    'ec': Ecuador,
    'eg': Egypt,
    'sv': ElSalvador,
    'gq': EquatorialGuinea,
    'er': Eritrea,
    'ee': Estonia,
    'et': Ethiopia,
    'fk': FalklandIslands,
    'fo': FaroeIslands,
    'fj': Fiji,
    'fi': Finland,
    'fr': France,
    'pf': FrenchPolynesia,
    'tf': FrenchSouthernTerritories,
    'ga': Gabon,
    'gm': Gambia,
    'ge': Georgia,
    'de': Germany,
    'gh': Ghana,
    'gi': Gibraltar,
    'gr': Greece,
    'gl': Greenland,
    'gd': Grenada,
    'gp': Guadeloupe,
    'gu': Guam,
    'gt': Guatemala,
    'gn': Guinea,
    'gw': GuineaBissau,
    'gy': Guyana,
    'ht': Haiti,
    'va': HolySee,
    'hn': Honduras,
    'hk': HongKong,
    'hu': Hungary,
    'is': Iceland,
    'in': India,
    'id': Indonesia,
    'ir': Iran,
    'iq': Iraq,
    'ie': Ireland,
    'il': Israel,
    'it': Italy,
    'jm': Jamaica,
    'jp': Japan,
    'jo': Jordan,
    'kz': Kazakhstan,
    'ke': Kenya,
    'ki': Kiribati,
    'kw': Kuwait,
    'kg': Kyrgyzstan,
    'la': Laos,
    'lv': Latvia,
    'lb': Lebanon,
    'ls': Lesotho,
    'lr': Liberia,
    'ly': LibyanJamahiriya,
    'li': Liechtenstein,
    'lt': Lithuania,
    'lu': Luxembourg,
    'mo': Macao,
    'mk': Macedonia,
    'mg': Madagascar,
    'mw': Malawi,
    'my': Malaysia,
    'mv': Maldives,
    'ml': Mali,
    'mt': Malta,
    'mh': MarshallIslands,
    'mq': Martinique,
    'mr': Mauritania,
    'mu': Mauritius,
    'yt': Mayotte,
    'mx': Mexico,
    'fm': Micronesia,
    'md': Moldova,
    'mc': Monaco,
    'mn': Mongolia,
    'me': Montenegro,
    'ms': Montserrat,
    'ma': Morocco,
    'mz': Mozambique,
    'mm': Myanmar,
    'na': Namibia,
    'nr': Nauru,
    'np': Nepal,
    'nl': Netherlands,
    'an': NetherlandsAntilles,
    'nc': NewCaldonia,
    'nz': NewZealand,
    'ni': Nicaragua,
    'ne': Niger,
    'ng': Nigeria,
    'nu': Niue,
    'nf': NorfolkIsland,
    'mp': NorthernMariana,
    'no': Norway,
    'om': Oman,
    'pk': Pakistan,
    'pw': Palau,
    'ps': Palestine,
    'pa': Panama,
    'pg': PapuaNewGuinea,
    'py': Paraguay,
    'pe': Peru,
    'ph': Philippines,
    'pn': Pitcairn,
    'pl': Poland,
    'pt': Portugal,
    'pr': PuertoRico,
    'qa': Qatar,
    'ro': Romania,
    'ru': Russia,
    'rw': Rwanda,
    'sh': SaintHelena,
    'kn': SaintKittsNevis,
    'lc': SaintLucia,
    'pm': SaintPierreMiquelon,
    'vc': SaintVincentGrenadines,
    'ws': Samoa,
    'sm': SanMarino,
    'st': SaoTomePrincipe,
    'sa': SaudiArabia,
    'sn': Senegal,
    'rs': Serbia,
    'sc': Seychelles,
    'sl': SierraLeone,
    'sg': Singapore,
    'sk': Slovakia,
    'si': Slovenia,
    'sb': SolomonIslands,
    'so': Somalia,
    'za': SouthAfrica,
    'gs': SouthGeorgia,
    'kr': SouthKorea,
    'es': Spain,
    'lk': SriLanka,
    'sd': Sudan,
    'sr': Suriname,
    'sz': Swaziland,
    'se': Sweden,
    'ch': Switzerland,
    'sy': SyrianRepublic,
    'tw': Taiwan,
    'tj': Tajikistan,
    'tz': Tanzania,
    'th': Thailand,
    'tl': TimorLeste,
    'tg': Togo,
    'tk': Tokelau,
    'to': Tonga,
    'tt': TrinidadTobago,
    'tn': Tunisia,
    'tr': Turkey,
    'tm': Turkmenistan,
    'tc': TurksCaicosIslands,
    'tv': Tuvalu,
    'ug': Uganda,
    'ua': Ukraine,
    'ae': UnitedArabEmirates,
    'gb': UnitedKingdom,
    'us': UnitedStates,
    'um': UnitedStatesIslands,
    'uy': Uruguay,
    'uz': Uzbekistan,
    'vu': Vanuatu,
    've': Venezuela,
    'vn': Vietnam,
    'vg': VirginIslandsBritish,
    'vi': VirginIslandsUS,
    'wf': WallisFutuna,
    'eh': WesternSahara,
    'ye': Yemen,
    'zm': Zambia,
    'zw': Zimbabwe
};
