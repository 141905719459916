import React, { Fragment, KeyboardEvent, ReactText } from 'react';
import Icon, { IconType } from './Icon';

interface PaginationProps {
    activePage: number,
    count: number,
    onChange: (page: number) => void;
    dark?: boolean;
    disabled?: boolean;
}

const Pagination = ({ activePage, count, dark, disabled, onChange }: PaginationProps) => {
    const handleSelect = (page: number) => {
        if (page !== activePage) {
            onChange(page);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, page: number) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleSelect(page);
        }
    };

    const renderButton = (key: ReactText, content: ReactText | JSX.Element, page: number, number: boolean, disabled = false) => {
        const range = Math.max(5 - Math.min(activePage, count - activePage - 1), 1);

        return (
            <Fragment key={key}>
                {(!number || (page < 2 || page > count - 3 || (page >= activePage - range && page <= activePage + range))) &&
                    <div className={`pagination-button ${number && page === activePage ? 'active' : ''} ${disabled ? 'disabled' : ''}`} tabIndex={disabled ? undefined : 0}
                        onClick={() => disabled ? null : handleSelect(page)} onKeyDown={e => handleKeyDown(e, page)}>
                        {content}
                    </div>
                }
                {number && ((page === activePage - range - 1 && page > 1) || (page === activePage + range + 1 && page < count - 2)) &&
                    <div className={`pagination-button divider ${disabled ? 'disabled' : ''}`}>
                        <Icon type={IconType.OptionsHorizontal} />
                    </div>
                }
            </Fragment>
        );
    };

    return (
        <div className={`pagination ${dark ? 'dark' : ''}`}>
            {renderButton('first', <Icon type={IconType.ArrowDoubleLeft} />, 0, false, disabled || activePage === 0)}
            {renderButton('previous', <Icon type={IconType.ArrowheadLeft} />, activePage - 1, false, disabled || activePage === 0)}
            {Array.from({ length: count }, (_, i) => i).map(x => renderButton(x, x + 1, x, true, disabled))}
            {renderButton('next', <Icon type={IconType.ArrowheadRight} />, activePage + 1, false, disabled || activePage === count - 1 || count === 0)}
            {renderButton('last', <Icon type={IconType.ArrowDoubleRight} />, count - 1, false, disabled || activePage === count - 1 || count === 0)}
        </div>
    );
};

export default Pagination;
