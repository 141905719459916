import React from 'react';
import { TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Select from '../../common/Select';
import SpacingContainer from '../../common/SpacingContainer';
import TextEditor from '../../common/TextEditor';

interface ReviewFormProps {
    approved: boolean;
    message: string;
    onMessageChange: (message: string) => void;
    onStatusChange: (approve: boolean) => void;
}

const ReviewForm = ({ approved, message, onMessageChange, onStatusChange }: ReviewFormProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const renderForm = () => {
        const getText = (approve: boolean) => approve ? translate(translations.common.approve) : translate(translations.common.reject);

        return (
            <SpacingContainer>
                <Select dark={dark} value={approved} values={[true, false]} mapToString={x => getText(x as boolean)} filled={x => x !== null} 
                        label={translate(translations.common.status)} onSelect={onStatusChange} required />
                {approved === false &&
                    <TextEditor dark={dark} value={message} label={translate(translations.common.message)} onChange={onMessageChange} required />
                }
            </SpacingContainer>
        );
    };

    return renderForm();
};
export default ReviewForm;
