import React from 'react';
import { TRANSLATIONS } from '../../../constants';
import { useTranslate } from '../../../hooks/common';
import ContentContainer from '../../common/ContentContainer';

const AuthorizationError = () => {
    const translate = useTranslate();
    const translations = TRANSLATIONS;

    const renderAuthorizationError = () => {
        return (
            <ContentContainer style={{ marginTop: 30, marginBottom: 50 }}>
                <div style={{ marginBottom: 15, fontSize: 20 }}>
                    {translate(translations.common.noPermission)}
                </div>
                <div>
                    {translate(translations.dialog.noRights)}
                </div>
            </ContentContainer>
        );
    };

    return renderAuthorizationError();
};
export default AuthorizationError;
