import React, { useState } from 'react';
import { Content, MachineCollectionCreation } from '../../../models';
import { TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import SpacingContainer from '../../common/SpacingContainer';
import { Tab, Tabs } from '../../common/Tabs';
import TextEditor from '../../common/TextEditor';

interface MachineCollectionDescriptionFormProps {
    machineCollection: MachineCollectionCreation;
    onChange: (machineCollection: Partial<MachineCollectionCreation>) => Promise<void>;
}

const MachineCollectionDescriptionForm = ({ machineCollection, onChange }: MachineCollectionDescriptionFormProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [activeTab, setActiveTab] = useState(0);

    const handleTabSelect = (index: number) => {
        setActiveTab(index);
    };

    const handleSubtitleChange = (value: string, language: string) => {        
        return onChange({ subtitle: { ...machineCollection.subtitle, [language]: value } });
    };

    const handleDescription1Change = (value: string, language: string) => {
        return onChange({ description1: { ...machineCollection.description1, [language]: value } });
    };

    const handleDescription2Change = (value: string, language: string) => {
        return onChange({ description2: { ...machineCollection.description2, [language]: value } });
    };

    const handleImageDescriptionChange = (value: string, language: string) => {
        return onChange({ imageDescription: { ...machineCollection.imageDescription, [language]: value } });
    };

    const getLanguages = () => {
        type languageType = typeof translations.language;

        return machineCollection.availableLanguages
            .sort((a, b) => translate(translations.language[a as keyof languageType]).localeCompare(translate(translations.language[b as keyof languageType])));
    };

    const renderInputs = (language: string) => {        
        return (
            <SpacingContainer>
                <TextEditor dark={dark} error={!machineCollection?.subtitle[language as keyof Content]?.toString()} 
                            value={machineCollection?.subtitle[language as keyof Content]?.toString()} label={translate(translations.common.subtitle)} 
                            onChange={x => handleSubtitleChange(x, language)} required allowFormating />
                <TextEditor dark={dark} error={!machineCollection?.description1[language as keyof Content]?.toString()} 
                            value={machineCollection?.description1[language as keyof Content]?.toString()} label={translate(translations.collection.initialDescription)} 
                            onChange={x => handleDescription1Change(x, language)} required allowFormating />
                <TextEditor dark={dark} error={!machineCollection?.description2[language as keyof Content]?.toString()} 
                            value={machineCollection?.description2[language as keyof Content]?.toString()} label={translate(translations.collection.secondDescription)} 
                            onChange={x => handleDescription2Change(x, language)} required allowFormating />
                <TextEditor dark={dark} error={!machineCollection?.imageDescription[language as keyof Content]?.toString()} 
                            value={machineCollection?.imageDescription[language as keyof Content]?.toString()} label={translate(translations.collection.imageDescription)} 
                            onChange={x => handleImageDescriptionChange(x, language)} required allowFormating />
            </SpacingContainer>
        );
    };

    const renderForm = () => {
        type languageType = typeof translations.language;

        return machineCollection && (
            <Tabs
                dark={dark}
                selectedTab={activeTab}
                tabs={getLanguages().map<Tab>(x => ({ id: x, label: translate(translations.language[x as keyof languageType]), content: renderInputs(x) }))}
                onSelect={handleTabSelect}
            />
        );
    };

    return renderForm();
};
export default MachineCollectionDescriptionForm;
