import { Filter } from '../models';

export const EMPTY_FILTER: Filter = {
    companyNames: [],
    countries: [],
    favourites: [],
    hallIds: [],
    tags: [],
    shouldFilter: false
};
