import React, { Component } from 'react';
import Icon, { IconType } from './Icon';

export interface TagProps {
    id: string;
    text: string;
    color: string;
    noClose?: boolean;
    handleOnClick?: () => void;
}

const FairTag = ({ id, text, color, noClose, handleOnClick }: TagProps) => {
    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        if (handleOnClick) {
            handleOnClick();
        }
    };

    const renderTag = () => {
        const containerClassName = `machine-tag ${handleOnClick ? 'selectable' : ''}`;

        return (
            <div key={id} style={{ backgroundColor: color }} className={containerClassName} onClick={handleClick}>
                {text}
                {!noClose && <Icon type={IconType.Close} />}
            </div>
        );
    };

    return renderTag();
};
export default FairTag;
