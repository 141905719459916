import React, { KeyboardEvent } from 'react';
import { useNavigate } from 'react-router';
import { useOpenAnimations } from '../../hooks/common';

export interface HeaderMegaMenuNavigationItem {
    title: string;
    to: string;
}

interface HeaderMegaMenuProps {
    open: boolean;
    navigationItems: HeaderMegaMenuNavigationItem[];
    dark?: boolean;
    onClose: () => void;
}

const HeaderMegaMenu = ({ open, navigationItems, dark, onClose }: HeaderMegaMenuProps) => {
    const navigate = useNavigate();
    const [animationClass, handleAnimationEnd] = useOpenAnimations(open);

    const handleNavigationItemSelect = (navigationItem: HeaderMegaMenuNavigationItem) => {
        navigate(navigationItem.to);
        onClose();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, navigationItem: HeaderMegaMenuNavigationItem) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleNavigationItemSelect(navigationItem);
        }
    };

    return (
        <>
            <div className={`header-mega-menu ${animationClass} ${dark ? 'dark' : ''}`}>
                <div className={`header-mega-menu-background ${dark ? ' dark' : ''}`} />
                <div className='header-mega-menu-navigation' onAnimationEnd={handleAnimationEnd}>
                    {navigationItems.map(x =>
                        <div key={x.title} className='header-mega-menu-navigation-item' tabIndex={0} onClick={() => handleNavigationItemSelect(x)} onKeyDown={e => handleKeyDown(e, x)}>
                            {x.title}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default HeaderMegaMenu;
