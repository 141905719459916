import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Language } from '../enums';

export interface TranslationState {
    language: Language;
}

const getInitialLanguage = (): Language => {
    const storedLanguage = window.localStorage.getItem('language');

    return storedLanguage ? Number(storedLanguage) : Language.En;
};

const initialState: TranslationState = {
    language: getInitialLanguage()
};

export const translationSlice = createSlice({
   name: 'translation',
   initialState,
   reducers: {
       setLanguage: (state: TranslationState, action: PayloadAction<Language>) => {
           state.language = action.payload;
       }
   } 
});

export const { setLanguage } = translationSlice.actions;
export const translationReducer = translationSlice.reducer;
