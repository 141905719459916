import React, { Component } from 'react';
import { Link } from 'react-router-dom';

interface MainLinkPropsBase {
    label: string;
}
interface InternalMainLinkProps extends MainLinkPropsBase {
    internalLink: string;
}
interface ExternalMainLinkProps extends MainLinkPropsBase {
    externalLink: string;
}
type MainLinkProps = InternalMainLinkProps | ExternalMainLinkProps;

export default class MainLink extends Component<MainLinkProps> {
    private renderMainLink() {
        const { props } = this;

        return (
            <div className='main-link'>
                {'internalLink' in props
                    ? <Link to={props.internalLink}>{props.label}</Link>
                    : <a href={props.externalLink}>{props.label}</a>
                }
            </div>
        );
    }

    render() {
        return this.renderMainLink();
    }
}
