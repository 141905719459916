import { HeaderTabType } from '../enums';
import { Tab } from '../components/layout/HeaderTabs';
import { AuthService } from '../services';
import { DEFAULT_MACHINE_COLLECTION_ID } from './DefaultMachineCollectionId';

export const TABS: Tab[] = [
    { title: 'eBooklets', to: '/collections/selection', type: HeaderTabType.MachineCollections, available: false },
    { title: 'machineFinder', to: `/collection/${DEFAULT_MACHINE_COLLECTION_ID}`, type: HeaderTabType.MachineCollection, available: true },
    { title: 'myMachines', to: '/products', type: HeaderTabType.MyProducts, available: AuthService.isAuthenticated() },
    { title: 'myEBooklet', to: '/collections', type: HeaderTabType.MyMachineCollections, available: AuthService.isAdmin() },
    { title: 'categories', to: '/categories', type: HeaderTabType.Categories, available: AuthService.isAdmin() },
    { title: 'fairs', to: '/tags', type: HeaderTabType.Tags, available: false, chiefEditorRequired: true },
    { title: 'users', to: '/users', type: HeaderTabType.Users, available: AuthService.isAdmin() },
    { title: 'export', to: '/export', type: HeaderTabType.Export, available: AuthService.isAdmin(), chiefEditorRequired: true },
    { title: 'maintenance', to: '/maintenance', type: HeaderTabType.Maintenance, available: AuthService.isAdmin(), chiefEditorRequired: true }
];
