import React, { FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useOpenAnimations } from '../../hooks/common';
import Button, { ButtonType } from './Button';
import Icon, { IconType } from './Icon';

export interface ButtonWithPopupDropdownAction {
    id: number;
    name: string;
    onClick?: () => void;
    iconType?: IconType;
}

export interface ButtonWithPopupActionProps {
    buttonIconType: IconType;
    buttonType: ButtonType;
    disabled: boolean;
    dropdownActions: ButtonWithPopupDropdownAction[];
    dark?: boolean;
    defaultSelectedId?: number;
}

const ButtonWithPopup = ({ buttonIconType, buttonType, dark, disabled, dropdownActions, defaultSelectedId }: ButtonWithPopupActionProps) => {
    const [open, setOpen] = useState(false);
    const [animationClass, handleAnimationEnd] = useOpenAnimations(open);
    const buttonRef = useRef<HTMLDivElement>(null);
    const [selectedId, setSelectedId] = useState(defaultSelectedId);

    useEffect(() => {
        disabled && setOpen(false);
    }, []);

    const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
        if (!event.relatedTarget || !buttonRef.current || !buttonRef.current.contains(event.relatedTarget as HTMLElement)) {
            setOpen(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setOpen(x => !x);
        }
    };

    const selectAction = (action: ButtonWithPopupDropdownAction) => {
        setOpen(false);
        setSelectedId(action.id);
        action.onClick && action.onClick();
    };

    const handleActionKeyDown = (event: KeyboardEvent<HTMLDivElement>, action: ButtonWithPopupDropdownAction) => {
        event.stopPropagation();

        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            selectAction(action);
        }
    };

    return (
        <div className='button-with-popup'>
            <div ref={buttonRef} className='button-with-popup-button' tabIndex={0} onBlur={handleBlur} onKeyDown={handleKeyDown}>
                <Button
                    dark={dark}
                    type={buttonType}
                    onClick={() => setOpen(x => !x)}
                    disabled={disabled}>
                    <Icon type={buttonIconType} />
                    <div className={`button-with-popup-dropdown ${animationClass}`} onClick={e => e.stopPropagation()} onAnimationEnd={handleAnimationEnd}>
                        <div className='button-with-popup-dropdown-title'>Sort by:</div>
                        {dropdownActions.map(x =>
                            <div
                                key={x.name}
                                className='button-with-popup-dropdown-action'
                                tabIndex={0}
                                onClick={e => {
                                    e.stopPropagation();
                                    selectAction(x);
                                }
                                }
                                onKeyDown={e => handleActionKeyDown(e, x)}
                            >
                                {x.iconType &&
                                    <Icon type={x.iconType} />
                                }
                                <div>{x.name}</div>
                                {x.id == selectedId &&
                                    <Icon type={IconType.Dot} />
                                }
                            </div>
                        )}
                    </div>
                </Button>
            </div>
        </div>
    );
};

export default ButtonWithPopup;
