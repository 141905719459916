import React, { KeyboardEvent } from 'react';

interface WizardProps {
    step: number;
    stepNames: string[];
    onStepClick: (step: number) => void;
    dark?: boolean;
}

const Wizard = ({ step, dark, stepNames, onStepClick }: WizardProps) => {
    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, step: number) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onStepClick(step);
        }
    };

    return (
        <div className={`wizard wizard-steps-${stepNames.length} ${dark ? 'dark' : ''}`}>
            <div className='wizard-steps'>
                {stepNames.map((x, i) =>
                    <div key={x} className={`wizard-step ${i < step ? 'passed' : i === step ? 'active' : ''}`} onClick={() => onStepClick(i)}>
                        <div className='wizard-step-number' tabIndex={i < step ? 0 : undefined} onKeyDown={e => handleKeyDown(e, i)}>
                            {i + 1}
                        </div>
                        <div className='wizard-step-name'>
                            {x}
                        </div>
                    </div>
                )}
            </div>
            <div className='wizard-line-container'>
                <div className='wizard-line' style={{ width: `${step * 100 / (stepNames.length - 1)}%` }} />
            </div>
        </div>
    );
};

export default Wizard;
