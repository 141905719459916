import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api, apiCall } from '../../../services';
import { HeaderTabType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import MachineCollectionList from './MachineCollectionList';
import Loader from '../../common/Loader';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const MachineCollectionListManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const controller = new AbortController();
    const dispatch = useDispatch();
    const signal = controller.signal;
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [machineCollections, setMachineCollections] = useState([]);

    useEffect(() => {
        dispatch(setActiveTabType(HeaderTabType.MachineCollections));

        window.scrollTo(0, 0);

        (async () => {
            const startingUrl = window.location.pathname;

            dispatch(setLoading(true));

            await apiCall(
                Api.getMachineCollections(signal),
                async x => {
                    setMachineCollections(x.data);
                },
                async () => {
                    if (!signal.aborted) {
                        dispatch(addSnackbar({ text: translate(translations.error.collectionsLoad).toString(), type: SnackbarType.Error, dark: dark }));
                    }
                }
            );

            if (startingUrl === window.location.pathname) {
                dispatch(setLoading(false));
            }
        })();

        return () => {
            controller.abort();
            Api.cancelMachineCollectionRequests();
        };
    });

    const renderPage = () => {
        return (
            <>
                <div className={`compass-section ${dark ? 'dark' : ''}`}>
                    <Loader dark={dark} loading={isLoading}>
                        <MachineCollectionList machineCollections={machineCollections} />
                    </Loader>
                </div>
            </>
        );
    };

    return renderPage();
};
export default MachineCollectionListManager;
