import jwt_decode from 'jwt-decode';
import { MachineCollection } from '../models';
import Cookies from 'js-cookie';

export interface Token {
    jti: string;
    sub: string;
    email: string;
    userid: string;
    isadmin: string;
    role: string[];
    aud: string;
    exp: number;
    nbf: number;
    iat: number;
}

export class AuthService {
    static isAuthenticated() {
        const parsedToken = this.getParsedToken();

        return parsedToken && !this.isExpired(parsedToken.exp);        
    }

    private static isExpired(tokenExp: number) {
        const expiryLength = 1000;
        
        return tokenExp * expiryLength <= Date.now();
    }

    static redirectToLogin() {
        window.location.hostname.indexOf('localhost') !== -1
            ? window.open('/fakelogin', '_self')
            : window.open('/account/login', '_self');
    }

    static handleUnauthorizedResponse() {
        window.open('/authorizationerror', '_self');
    }    

    static isAdmin() {
        const parsedToken = this.getParsedToken();

        return this.isAuthenticated() && parsedToken.isadmin === '1';
    }

    static isChiefEditor(machineCollection: MachineCollection) {
        return this.isAuthenticated() && machineCollection && machineCollection.chiefEditorEmails && machineCollection.chiefEditorEmails.some(x => x === this.getUserEmail());
    }

    static isSiemensRepresentative() {
        return this.isAuthenticated() && this.hasRole(['SiemensRepresentative']);
    }

    static hasRole(roles: string[]) {
        const parsedToken = this.getParsedToken();
        const userRoles: string | string[] = parsedToken.role;
        
        return roles.some(x => typeof userRoles === 'string' ? userRoles === x : userRoles.some(y => y === x));
    }

    static getUserEmail() {
        const parsedToken = this.getParsedToken();

        return parsedToken ? parsedToken.email : '';
    }

    static getUserName() {
        const parsedToken = this.getParsedToken();

        return parsedToken ? parsedToken.sub : '';
    }

    static getUserId() {
        const parsedToken = this.getParsedToken();

        return parsedToken ? parsedToken.userid : '';
    }

    private static getParsedToken(): Token {
        const token = this.getToken();              

        if (token) {
            try {
                return jwt_decode<Token>(token);
            } catch (e) {
                //TODO: Remove
                console.debug(e);
                return null;
            }
        }

        return null;
    }

    static getToken(): string {
        const jwtToken = Cookies.get('JwtToken');
        const storedToken = window.sessionStorage.getItem('token');

        if ((jwtToken && !storedToken) || (jwtToken && storedToken && storedToken !== jwtToken)) {
            window.sessionStorage.setItem('token', jwtToken);
        }

        return window.sessionStorage.getItem('token');
    }
}
