import React, { ReactText } from 'react';
import Snackbar, { SnackbarType } from './Snackbar';

export interface Snackbar {
    id: ReactText;
    text: string;
    type: SnackbarType;
    dark?: boolean;
    duration?: number;
}

export interface SnackbarsProps {
    snackbars: Snackbar[];
    removeSnackbar: (id: ReactText) => void;
}

const Snackbars = ({ snackbars, removeSnackbar }: SnackbarsProps) => (
    <div className='snackbars'>
        {snackbars.map(x => <Snackbar key={x.id} {...x} onDisappear={() => removeSnackbar(x.id)} dark={x.dark} duration={x.duration} />)}
    </div>
);

export default Snackbars;
