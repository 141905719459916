import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MachineCollectionListItem } from '../../../models';
import { MachineCollectionStatus, MachineCollectionType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Icon, { IconType } from '../../common/Icon';
import List from '../../common/List';
import Modal from '../../common/Modal';
import SpacingContainer from '../../common/SpacingContainer';
import Title from '../../common/Title';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Table from '../../common/Table';
import Link from '../../common/Link';
import RadioButton from '../../common/RadioButton';

interface MachineCollectionTableProps {
    machineCollections: MachineCollectionListItem[];
    onCollectionRelease: (id: string) => Promise<void>;
    onCollectionDelete: (id: string) => Promise<void>;
}

const MachineCollectionTable = ({ machineCollections, onCollectionDelete, onCollectionRelease }: MachineCollectionTableProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const navigate = useNavigate();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [collectionType, setCollectionType] = useState(MachineCollectionType.MachineCompass);
    const [machineCollectionId, setMachineCollectionId] = useState(null);
    const [shouldDisplayReleaseDialog, setShouldDisplayReleaseDialog] = useState(false);
    const [shouldDisplayDeleteDialog, setShouldDisplayDeleteDialog] = useState(false);
    const [shouldDisplayTypeModal, setShouldDisplayTypeModal] = useState(false);

    const openTypeModal = () => {
        setShouldDisplayTypeModal(true);
    };

    const closeTypeModal = async () => {
        setCollectionType(MachineCollectionType.EBooklet);
        setShouldDisplayTypeModal(false);
    };

    const confirmTypeModal = async () => {
        setShouldDisplayTypeModal(false);
        navigate(`collectionwizard?type=${collectionType}`);
    };

    const openReleaseDialog = (machineCollection: MachineCollectionListItem) => {
        setMachineCollectionId(machineCollection.id);
        setShouldDisplayReleaseDialog(true);
    };

    const closeReleaseDialog = () => {
        setMachineCollectionId(null);
        setShouldDisplayReleaseDialog(false);
    };

    const confirmRelease = async () => {        
        closeReleaseDialog();
        await onCollectionRelease(machineCollectionId);
    };

    const openDeleteDialog = (machineCollection: MachineCollectionListItem) => {
        setMachineCollectionId(machineCollection.id);
        setShouldDisplayDeleteDialog(true);
    };

    const closeDeleteDialog = () => {
        setMachineCollectionId(null);
        setShouldDisplayDeleteDialog(false);
    };

    const confirmDelete = async () => {        
        closeDeleteDialog();
        await onCollectionDelete(machineCollectionId);
    };

    const renderTypeModal = () => {        
        return shouldDisplayTypeModal && (
            <Modal dark={dark} open={true}>
                <SpacingContainer>
                    <Title style={{ marginBottom: 30 }} text={translate(translations.dialog.selectType)} bold />
                    <List items={[
                        {
                            key: MachineCollectionType.EBooklet,
                            content: <RadioButton dark={dark} selected={collectionType === MachineCollectionType.EBooklet} label={translate(translations.collection.eBooklet)}
                                onChange={() => setCollectionType(MachineCollectionType.EBooklet)} />
                        }, {
                            key: MachineCollectionType.MachineCompass,
                            content: <RadioButton dark={dark} selected={collectionType === MachineCollectionType.MachineCompass} 
                                label={translate(translations.collection.machineCompass)} onChange={() => setCollectionType(MachineCollectionType.MachineCompass)} />
                        }
                    ]} />
                    <Flex direction={FlexDirection.Row} style={{ marginTop: 30 }} justification={FlexJustification.FlexEnd} gap={5}>
                        <Button dark={dark} type={ButtonType.Secondary} onClick={closeTypeModal}>
                            <Icon type={IconType.Close} />
                            {translate(translations.action.cancel)}
                        </Button>
                        <Button dark={dark} type={ButtonType.Primary} onClick={confirmTypeModal}>
                            <Icon type={IconType.Plus} />
                            {translate(translations.action.create)}
                        </Button>
                    </Flex>
                </SpacingContainer>
            </Modal>
        );
    };

    const renderReleaseDialog = () => {
        return shouldDisplayReleaseDialog && (
            <Modal dark={dark} open={shouldDisplayReleaseDialog}>
                <Title text={translate(translations.dialog.confirmRelease)} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    <Link dark={dark} text={translate(translations.action.yes)} onClick={confirmRelease} />
                    <Link dark={dark} text={translate(translations.action.no)} onClick={closeReleaseDialog} />
                </Flex>
            </Modal>            
            );
    };

    const renderDeleteDialog = () => {
        return shouldDisplayDeleteDialog && (
            <Modal dark={dark} open={shouldDisplayDeleteDialog}>
                <Title text={translate(translations.dialog.confirmDelete)} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={5}>
                    <Link dark={dark} text={translate(translations.action.yes)} onClick={confirmDelete} />
                    <Link dark={dark} text={translate(translations.action.no)} onClick={closeDeleteDialog} />
                </Flex>
            </Modal>
        );
    };

    const renderHeaderActions = () => {
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                <Link dark={dark} text={translate(translations.action.create)} onClick={openTypeModal} />
            </Flex>
        );
    };

    const renderRowActions = (machineCollection: MachineCollectionListItem) => {
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.SpaceEvenly}>
                {machineCollection.status === MachineCollectionStatus.Draft &&
                    <>
                        <Link dark={dark} text={translate(translations.action.release)} onClick={() => openReleaseDialog(machineCollection)} />
                        <Link dark={dark} text={translate(translations.action.edit)} onClick={() => navigate(`collectionwizard/${machineCollection.id}`)} />
                        <Link dark={dark} text={translate(translations.action.delete)} onClick={() => openDeleteDialog(machineCollection)} />
                    </>
                }
            </Flex>
        );
    };

    const renderTable = () => {
        return <Table
            dark={dark}
            columns={[
                { label: translate(translations.common.name), min: '150px', max: '2fr', bold: true },
                { label: translate(translations.common.status), min: '100px', max: '1fr', bold: true },
                { label: '', min: '110px', max: '2fr' }
            ]}
            rows={[
                ...machineCollections.map(x => ({
                    key: x.id,
                    cells: [
                        { content: x.title },
                        { content: translate(translations.collection.status[Number(x.status).toString() as keyof typeof translations.collection.status]) },
                        { content: renderRowActions(x) }
                    ]
                }))
            ]}
        />;
    };

    const renderPage = () => {
        return (
            <>
                <ContentContainer style={{ marginTop: 30 }}>
                    {renderHeaderActions()}
                </ContentContainer>
                <ContentContainer style={{ marginTop: 30, marginBottom: 50 }}>
                    {renderTable()}
                </ContentContainer>
                {renderTypeModal()}
                {renderReleaseDialog()}
                {renderDeleteDialog()}
            </>
        );
    };

    return renderPage();
};
export default MachineCollectionTable;
