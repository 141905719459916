import { DateService } from '.';
import { MachineCollectionCreation, ReleasedMachineCollection } from '../models';

export class CollectionService {
    static convertDates = <T extends MachineCollectionCreation | ReleasedMachineCollection>(collection: T): T =>
        Object.assign({}, collection, {
            exhibitorsStartDate: DateService.fromString(collection.exhibitorsStartDate),
            exhibitorsEndDate: DateService.fromString(collection.exhibitorsEndDate),
            fairStartDate: DateService.fromString(collection.fairStartDate),
            fairEndDate: DateService.fromString(collection.fairEndDate),
            fairPreStartDate: DateService.fromString(collection.fairPreStartDate)
        });

    static filter = <T>(searchText: string, array: T[], map: (value: T) => string, mapForSort: (value: T) => string = null): T[] => {
        const searchValues = searchText.split(' ').map(x => x.trim() ? x.trim().toLowerCase() : null).filter(x => x);

        let filteredArray = array.filter(x => searchValues.every(y => map(x).split(' ').some(z => z.toLowerCase().startsWith(y))));
        filteredArray = filteredArray.length ? filteredArray : array.filter(x => searchValues.every(y => map(x).toLowerCase().includes(y)));

        if (mapForSort) {
            const numberArray = filteredArray.filter(x => !isNaN(Number(mapForSort(x))));
            const stringArray = filteredArray.filter(x => isNaN(Number(mapForSort(x))));

            return numberArray.sort((x, y) => Number(mapForSort(x)) - Number(mapForSort(y))).concat(stringArray.sort((x, y) => mapForSort(x).localeCompare(mapForSort(y))));
        } else {
            return filteredArray.sort((x, y) => map(x).localeCompare(map(y)));
        }
    };

    static getLink = (collectionId: string) => `/collection/${collectionId}`;
}
