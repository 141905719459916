import React, { useState } from 'react';
import { ContentService, DateService } from '../../../services';
import { Categories, Content, Hall, MachineCollectionCreation } from '../../../models';
import { MachineCollectionType } from '../../../enums';
import { TRANSLATIONS } from '../../../constants';
import MachineCollectionCategoryForm from './MachineCollectionCategoryForm';
import MachineCollectionDescriptionForm from './MachineCollectionDescriptionsForm';
import MachineCollectionGeneralForm from './MachineCollectionGeneralForm';
import MachineCollectionHallForm from './MachineCollectionHallForm';
import MachineCollectionTradeShowForm from './MachineCollectionTradeShowForm';
import Button, { ButtonType } from '../../common/Button';
import ContentContainer from '../../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Float from '../../common/Float';
import Icon, { IconType } from '../../common/Icon';
import Wizard from '../../common/Wizard';
import { useAppSelector, useTranslate } from '../../../hooks/common';

interface MachineCollectionWizardProps {
    accordionsOpen: boolean[];
    categories: Categories;
    image: File;
    imageUrl: string;
    machineCollection: MachineCollectionCreation;
    onAccordionToggle: (index: number) => void;
    onCollectionChange: (machineCollection: Partial<MachineCollectionCreation>) => Promise<void>;
    onFileReceived: (file: File) => void;
    onHallAdd: (hall: Hall) => Promise<void>;
    onHallChange: (index: number, hall: Hall) => Promise<void>;
    onHallDelete: (index: number) => Promise<void>;
    onWizardSubmit: () => Promise<void>;
}

const MachineCollectionWizard = ({ accordionsOpen, categories, image, imageUrl, machineCollection, onAccordionToggle, onCollectionChange,
    onFileReceived, onHallAdd, onHallChange, onHallDelete, onWizardSubmit }: MachineCollectionWizardProps) => {
    const dark = useAppSelector(state => state.layout.dark);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [wizardStep, setWizardStep] = useState(0);

    const handleSubmit = async () => {
        if (wizardStep < getLastTab()) {
            setWizardStep(wizardStep + 1);
        } else {
            await onWizardSubmit();
        }
    };

    const handleStepClick = (step: number) => {
        setWizardStep(step);
    };

    const isValid = () => {
        type contentType = keyof Content;

        switch (wizardStep) {
            case 0:
                return machineCollection && machineCollection.title && machineCollection.chiefEditorEmails.length && machineCollection.availableLanguages.length
                    && (machineCollection.type === MachineCollectionType.MachineCompass || (
                        DateService.getDayBeginning(machineCollection.exhibitorsStartDate) < DateService.getDayBeginning(machineCollection.exhibitorsEndDate)
                        && DateService.getDayBeginning(machineCollection.exhibitorsStartDate) >= DateService.getDayBeginning(new Date(Date.now()))
                    ));
            case 1:
                return machineCollection && (machineCollection.imageId || image) && (machineCollection.type === MachineCollectionType.MachineCompass || (
                    machineCollection.location
                    && DateService.getDayBeginning(machineCollection.fairPreStartDate) <= DateService.getDayBeginning(machineCollection.fairStartDate)
                    && DateService.getDayBeginning(machineCollection.fairStartDate) < DateService.getDayBeginning(machineCollection.fairEndDate)
                ));
            case 2:
                return machineCollection?.availableLanguages?.every(x => ContentService.isValid(machineCollection?.subtitle[x as contentType]?.toString()) 
                    && ContentService.isValid(machineCollection?.description1[x as contentType]?.toString()) 
                    && ContentService.isValid(machineCollection?.description2[x as contentType]?.toString()) 
                    && ContentService.isValid(machineCollection?.imageDescription[x as contentType]?.toString()));
            case 3:
                return machineCollection && machineCollection.type === MachineCollectionType.EBooklet ? Boolean(machineCollection.halls.length) : isCategoryFormValid();
            case 4:
                return isCategoryFormValid();
            default:
                return true;
        }
    };

    const isCategoryFormValid = () => {
        return machineCollection && machineCollection.categoryGroups.every(x => x.subgroups.some(y => Boolean(y.categoryIds.length))
            && x.subgroups.every(y => !y.categoryIds.length || (y.rule !== null && y.subgroupId != null)));
    };

    const getLastTab = () => machineCollection && machineCollection.type === MachineCollectionType.EBooklet ? 4 : 3;

    const renderWizard = () => {
        const stepNames: string[] = [
            translate(translations.common.general),
            translate(translations.collection.tradeShow),
            translate(translations.common.descriptions),
            machineCollection && machineCollection.type === MachineCollectionType.EBooklet ? translate(translations.hall.halls) : translate(translations.category.categories),
            ...machineCollection && machineCollection.type === MachineCollectionType.EBooklet ? [translate(translations.category.categories)] : []
        ];

        return <Wizard dark={dark} step={wizardStep} stepNames={stepNames} onStepClick={handleStepClick} />;
    };

    const renderCategoryForm = () => {
        return <MachineCollectionCategoryForm accordionsOpen={accordionsOpen} categories={categories} machineCollection={machineCollection} onAccordionToggle={onAccordionToggle}
            onChange={onCollectionChange} />;
    };

    const renderForm = () => {
        const forms: JSX.Element[] = [
            <ContentContainer>
                <MachineCollectionGeneralForm machineCollection={machineCollection} onChange={onCollectionChange} />
            </ContentContainer>,
            <ContentContainer>
                <MachineCollectionTradeShowForm image={image} imageUrl={imageUrl} machineCollection={machineCollection} onChange={onCollectionChange} 
                    onFileReceived={onFileReceived} />
            </ContentContainer>,
            <ContentContainer>
                <MachineCollectionDescriptionForm machineCollection={machineCollection} onChange={onCollectionChange} />
            </ContentContainer>,
            machineCollection && machineCollection.type === MachineCollectionType.EBooklet
                ? <ContentContainer>
                    <MachineCollectionHallForm halls={machineCollection ? machineCollection.halls : []} onHallAdd={onHallAdd} onHallChange={onHallChange} 
                       onHallDelete={onHallDelete} />
                </ContentContainer>
                : renderCategoryForm(),
            renderCategoryForm()
        ];

        return forms[wizardStep];
    };

    const renderButton = () => {
        return (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                <Float key={wizardStep} bottom={50}>
                    <Button dark={dark} type={ButtonType.Primary} disabled={!isValid()} onClick={handleSubmit}>
                        <Icon type={isValid() ? IconType.Check : IconType.Warning} />
                        {translate(wizardStep < getLastTab() ? translations.action.nextStep : translations.action.save)}
                    </Button>
                </Float>
            </Flex>
        );
    };

    const renderPage = () => {
        return (
            <>
                <ContentContainer style={{ marginTop: 30, marginBottom: 30 }}>
                    {renderWizard()}
                </ContentContainer>
                {renderForm()}
                <ContentContainer style={{ marginTop: 30, marginBottom: 50 }}>
                    {renderButton()}
                </ContentContainer>
            </>
        );
    };

    return renderPage();
};
export default MachineCollectionWizard;
