import React, { ReactNode } from 'react';
import keyVisual from '../../../assets/images/machine-compass-key-visual.jpg';
import keyVisualDark from '../../../assets/images/machine-compass-dark-key-visual.jpg';

interface KeyVisualProps {
    height: number;
    url: string;
    children?: ReactNode;
}

const KeyVisual = ({ children, height, url }: KeyVisualProps) => (
    <div id='no-print' className='key-visual' style={{ height: `${height}px`, backgroundImage: `url(${url.includes('dark') ? keyVisualDark : keyVisual})` }}>
        {children}
    </div>
);

export default KeyVisual;
