export enum ProductTableName {
    None,
    MyMachines,
    ProductRequests,
    ChiefEditorProducts,
    IncompleteProducts,
    ReviewedProducts,
    InReviewProducts,
    SharedMachines
}
