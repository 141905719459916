export enum ProductStatus {
    Draft,
    Released,
    Deleted,
    Approved,
    Rejected,
    Incomplete,
    CancelledRelease,
    ApprovedByChiefEditor,
    RejectedByChiefEditor,
    InApproval,
    RejectedByCustomer
}
