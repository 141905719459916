import React, { ChangeEvent, KeyboardEvent, useState, useRef, useEffect } from 'react';
import Icon, { IconType } from './Icon';

interface SearchProps<T> {
    label: string;
    searchValue: string;
    results: T[];
    mapToString: (result: T) => string;
    onChange: (searchValue: string) => void;
    onSelect: (result: T) => void;
    getId?: (x: T) => string;
    condensed?: boolean;
    dark?: boolean;
    error?: boolean;
    disabled?: boolean;
    icon?: boolean;
    required?: boolean;
}

const Search = <T, >({ condensed, dark, disabled, error, icon, label, searchValue, required, results, getId, mapToString, onChange, onSelect }: SearchProps<T>) => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const inputRef = useRef<HTMLInputElement>(null);
    const resultsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollTo(0);
        setSelectedIndex(-1);
    }, [results]);

    const openSearch = () => {
        scrollTo(0);
        setSelectedIndex(-1);
        setOpen(true);
    };

    const handleBlur = () => {
        setOpen(false);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowUp') {
            event.preventDefault();

            const index = selectedIndex <= -1 ? results.length - 1 : selectedIndex - 1;

            scrollToIndexTop(Math.max(index - 1, 0));
            setSelectedIndex(index);
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();

            const index = selectedIndex >= results.length - 1 ? -1 : selectedIndex + 1;

            scrollToIndexBottom(Math.min(index + 1, results.length - 1));
            setSelectedIndex(index);
        } else if (event.key === 'Enter') {
            if (0 <= selectedIndex && selectedIndex < results.length) {
                onSelect(results[selectedIndex]);
            }

            inputRef.current?.blur();
        } else if (event.key === 'Escape') {
            inputRef.current?.blur();
        }
    };

    const handleClick = (selectedResult: T, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        onSelect(selectedResult);
        inputRef.current?.blur();
    };

    const handleMouseEnter = (index: number) => {
        setSelectedIndex(index);
    };

    const scrollTo = (top: number) => {
        if (resultsRef.current) {
            resultsRef.current.scrollTop = top;
        }
    };

    const scrollToIndexTop = (index: number) => {
        if (resultsRef.current) {
            const searchResult = getSearchResult(index);
            
            if (searchResult && (index === results.length - 2 || (searchResult.offsetTop <= resultsRef.current.scrollTop))) {
                resultsRef.current.scrollTop = searchResult.offsetTop;
            }
        }
    };

    const scrollToIndexBottom = (index: number) => {
        if (resultsRef.current) {
            const searchResult = getSearchResult(index);
            
            if (searchResult && (index === 0 || (searchResult.offsetTop + searchResult.offsetHeight >= resultsRef.current.scrollTop + resultsRef.current.offsetHeight))) {
                resultsRef.current.scrollTop = searchResult.offsetTop + searchResult.offsetHeight - resultsRef.current.offsetHeight;
            }
        }
    };

    const getSearchResult = (index: number) => resultsRef.current?.getElementsByClassName('search-result')[index] as HTMLDivElement;

    return (
        <div className={`search ${open ? 'open' : ''} ${disabled ? 'disabled' : ''} ${condensed ? 'condensed' : ''} ${error ? 'error' : ''} ${dark ? 'dark' : ''}`}>
            <div className='search-input-field'>
                <input ref={inputRef} type='text' placeholder={condensed ? label : undefined} value={searchValue} disabled={disabled} onBlur={handleBlur} onChange={handleChange} onClick={openSearch}
                    onFocus={openSearch} onKeyDown={handleKeyDown} />
                {!condensed &&
                    <div className={`search-label ${searchValue ? 'filled' : ''} ${required ? 'required' : ''}`}>
                        {label}
                    </div>
                }
                {icon &&
                    <div className='search-icon' onClick={() => inputRef.current?.focus()}>
                        <Icon type={IconType.Search} />
                    </div>
                }
            </div>
            <div ref={resultsRef} className={`search-results search-results-${Math.min(results.length, 5)}`}>
                {results.map((x, i) =>
                    <div key={getId ? getId(x) : mapToString(x)} className={`search-result ${selectedIndex === i ? 'selected' : ''}`} onClick={e => handleClick(x, e)} onMouseEnter={() => handleMouseEnter(i)}>
                        {mapToString(x)}
                    </div>)
                }
            </div>
        </div>
    );
};

export default Search;
