import React, { createRef, RefObject, useEffect, useState } from 'react';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Api, apiCall, AuthService, CategoryService, CollectionService, getFormData, ProductService } from '../../../services';
import { Categories, Company, Content, Comment, ProductCreation, ReleasedMachineCollection } from '../../../models';
import { CommentType, HeaderTabType, ProductStatus } from '../../../enums';
import { EMPTY_COMPANY, EMPTY_GUID, EMPTY_PRODUCT, PRODUCT_FOLDER, ROLES, TRANSLATIONS } from '../../../constants';
import ProductReleaseWizard from './ProductReleaseWizard';
import Loader from '../../common/Loader';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { SnackbarType } from '../../common/Snackbar';
import { addSnackbar, setActiveTabType, setLoading } from '../../../store';

const ProductReleaseWizardManager = () => {
    const dark = useAppSelector(state => state.layout.dark);
    const isLoading = useAppSelector(state => state.app.isLoading);
    const match = useMatch('/productreleasewizard/:id');
    const id = match?.params?.id;
    const search = useLocation().search;
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [accordionHeaderRefs, setAccordingHeaderRefs] = useState(null);
    const [accordionsOpen, setAccordionsOpen] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [editing, setEditing] = useState(false);
    const [filteredCategories, setFilteredCategories] = useState(null);
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [machineCollection, setMachineCollection] = useState(null);
    const [product, setProduct] = useState<ProductCreation>(null);
    const [readOnly, setReadOnly] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [tags, setTags] = useState(null);
    const [user, setUsers] = useState(null);

    useEffect(() => {
        dispatch(setActiveTabType(HeaderTabType.MyProducts));

        window.scrollTo(0, 0);

        (async () => {
            const startingUrl = window.location.pathname;

            dispatch(setLoading(true));

            setReadOnly(queryString.parse(search)['readonly'] === 'true');
            const isVisibleForSiemensRepresentative = queryString.parse(search)['incompleteVisible'] === 'true';

            let resultCategories: Categories;
            let resultProduct: ProductCreation;

            const categoryPromise = apiCall(
                Api.getCategories(),
                async x => {
                    const length = x.data.groups.length;

                    setAccordingHeaderRefs(Array<RefObject<HTMLDivElement>>(length).fill(null).map(_ => createRef()));
                    setAccordionsOpen(Array<boolean>(length).fill(true));
                    resultCategories = x.data;
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.categoryLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );

            const companiesPromise = !id && AuthService.hasRole([ROLES.SIEMENS_REPRESENTATIVE])
                ? apiCall(
                    Api.getAssignedCompanies(),
                    async x => {
                        setCompanies(x.data);
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.companiesLoad).toString(), type: SnackbarType.Error, dark: dark }));
                    }
                )
                : (async () => { })();

            const machineCollectionPromise = apiCall(
                Api.getReleasedMachineCollections(),
                async x => {
                    await categoryPromise;
                    await productPromise;

                    setNewMachineCollection(x.data, resultProduct, resultCategories);
                },
                async () => {
                    dispatch(addSnackbar({ 
                        text: translate(translations.error.releasedCollectionsLoad).toString(), 
                        type: SnackbarType.Error, 
                        dark: dark 
                    }));
                }
            );

            const productPromise = id
                ? apiCall(
                    Api.getProduct(id),
                    async x => {
                        await categoryPromise;

                        const hasReleaseComment = x.data.comments.some(y => y.type === CommentType.ReleaseComment);
                        const editing = x.data.status === ProductStatus.ApprovedByChiefEditor || queryString.parse(search)['editing'] === 'true';

                        resultProduct = {
                            ...ProductService.map(x.data),
                            comments: [...x.data.comments, ...hasReleaseComment ? [] : [{ type: CommentType.ReleaseComment, comment: '' }]],
                            categoryGroups: [...x.data.categoryGroups, 
                                ...CategoryService.getProductCategoryGroups(resultCategories).filter(y => x.data.categoryGroups.every(z => z.groupId !== y.groupId))]
                        };

                        setProduct(resultProduct);
                        setImageUrl(x.data.imageUrl);
                        setEditing(editing);
                    },
                    async () => {
                        resultProduct = { ...EMPTY_PRODUCT, isVisibleForSiemensRepresentative };

                        setProduct(resultProduct);
                        dispatch(addSnackbar({ text: translate(translations.error.productLoad).toString(), type: SnackbarType.Error, dark: dark }));
                    }
                )
                : !AuthService.hasRole([ROLES.SIEMENS_REPRESENTATIVE])
                    ? apiCall(
                        Api.getCompanyForUser(),
                        async x => {
                            await categoryPromise;

                            const company = x.data;
                            resultProduct = {
                                ...EMPTY_PRODUCT,
                                companyId: company.id,
                                company,
                                isVisibleForSiemensRepresentative,
                                categoryGroups: CategoryService.getProductCategoryGroups(resultCategories)
                            };

                            setProduct(resultProduct);
                        },
                        async () => {
                            resultProduct = { ...EMPTY_PRODUCT, isVisibleForSiemensRepresentative };

                            setProduct(resultProduct);
                            dispatch(addSnackbar({ text: translate(translations.error.companyLoad).toString(), type: SnackbarType.Error, dark: dark }));
                        }
                    )
                    : (async () => {
                        await categoryPromise;

                        resultProduct = {
                            ...EMPTY_PRODUCT,
                            companyId: EMPTY_COMPANY.id,
                            company: ProductService.addRepresentativeToCompany(EMPTY_COMPANY, AuthService.getUserName()),
                            isVisibleForSiemensRepresentative,
                            categoryGroups: CategoryService.getProductCategoryGroups(resultCategories)
                        };

                        setProduct(resultProduct);
                    })();

            const tagPromise = apiCall(
                Api.getTags(),
                async x => {
                    setTags(x.data);
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.tagLoad).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );

            const userPromise = id
                ? apiCall(
                    Api.getCreatorUser(id),
                    async x => {
                        setUsers(x.data);
                    },
                    async () => {
                        dispatch(addSnackbar({ text: translate(translations.error.usersLoad).toString(), type: SnackbarType.Error, dark: dark }));
                    }
                )
                : setUsers(null);

            await categoryPromise;
            await companiesPromise;
            await productPromise;
            await machineCollectionPromise;
            await tagPromise;
            await userPromise;

            if (startingUrl === window.location.pathname) {
                dispatch(setLoading(false));
            }
        })();
    }, []);

    const setNewMachineCollection = async (machineCollections: ReleasedMachineCollection[], resultProduct: ProductCreation, resultCategories: Categories) => {
        const queryCollectionId = queryString.parse(search)['collectionid'] as string;
        const collectionId = queryCollectionId ? queryCollectionId : resultProduct.machineCollectionId;
        const newMachineCollection = CollectionService.convertDates(machineCollections.find(x => x.id === collectionId));

        const categoryGroups = CategoryService.getFilteredProductCategoryGroups(resultProduct.categoryGroups, newMachineCollection);
        const newFilteredCategories = CategoryService.getFilteredCategories(resultCategories, newMachineCollection);

        setFilteredCategories(newFilteredCategories);
        setMachineCollection(newMachineCollection);
        setProduct({ ...resultProduct, categoryGroups, customReleaseDate: newMachineCollection.fairStartDate, machineCollectionId: newMachineCollection.id });
    };

    const handleAccordionToggle = (index: number) => setAccordionsOpen(accordionsOpen.map((x: boolean, i: number) => i === index ? !x : x));

    const handleCompanyChange = async (company: Partial<Company>) => {
        setProduct({ ...product, company: { ...product.company, ...company } });
    };

    const handleCompanySet = async (company: Company) => {
        setProduct({ ...product, companyId: company.id, company });
    };

    const handleFileReceived = (file: File) => {
        if (!image || image.name !== file.name) {
            setImage(file);

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = x => setImageUrl(x.target['result'] as string);
        }
    };

    const handleMaxResolutionExceeded = async () => {
        dispatch(addSnackbar({ text: translate(translations.dialog.resolutionChange).toString(), type: SnackbarType.Info, dark: dark }));
    };

    const handleProductChange = async (newProduct: Partial<ProductCreation>) => {
        setProduct({ ...product, ...newProduct });
    };

    const handleProductSave = async (isIncomplete = false, isVisibleForSiemensRepresentative?: boolean, comments?: Comment[]) => {
        dispatch(setLoading(true));

        let imageError = false;
        let imageId = product.imageId;

        if (image) {
            await apiCall(
                Api.saveImage(PRODUCT_FOLDER, getFormData(image)),
                async x => {
                    imageId = x.data;
                },
                async () => {
                    imageError = true;
                    dispatch(addSnackbar({ text: translate(translations.error.imageSave).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );
        }

        if (!imageError) {
            await apiCall(
                product.id === EMPTY_GUID
                    ? Api.createAndReleaseProduct(getReleasedProduct(imageId, isVisibleForSiemensRepresentative, comments), isIncomplete)
                    : Api.releaseProduct(getReleasedProduct(imageId, isVisibleForSiemensRepresentative, comments), isIncomplete),
                async () => {
                    setShouldRedirect(true);
                    dispatch(addSnackbar({
                        text: translate(isIncomplete || editing ? translations.dialog.successfulSave : translations.dialog.successfulRelease).toString(), 
                        type: SnackbarType.Success, dark: dark
                    }));
                },
                async () => {
                    dispatch(addSnackbar({ text: translate(translations.error.productRelease).toString(), type: SnackbarType.Error, dark: dark }));
                }
            );
        }
    };

    const handleWizardCancel = async () => {
        setShouldRedirect(true);
    };

    const getReleasedProduct = (imageId: string, productIsVisibleForSiemensRepresentative?: boolean, comments?: Comment[]): ProductCreation => {
        const description: Content = { ...product.description };
        const title = product.title ? product.title : translate(translations.product.newMachine).toString();
        const isVisibleForSiemensRepresentative = productIsVisibleForSiemensRepresentative ? productIsVisibleForSiemensRepresentative : false;

        return { ...product, imageId, description, title, isVisibleForSiemensRepresentative, comments };
    };

    const renderWizard = () => {
        return (
            <div className={`compass-section no-overflow ${dark ? 'dark' : ''}`}>
                <Loader dark={dark} loading={isLoading}>
                    <ProductReleaseWizard accordionHeaderRefs={accordionHeaderRefs} accordionsOpen={accordionsOpen} categories={filteredCategories} companies={companies} 
                                          editing={editing} image={image} imageUrl={imageUrl} machineCollection={machineCollection} product={product} readOnly={readOnly} 
                                          tags={tags} user={user} onAccordionToggle={handleAccordionToggle} onCompanyChange={handleCompanyChange} onCompanySet={handleCompanySet} 
                                          onFileReceived={handleFileReceived} onMaxResExceeded={handleMaxResolutionExceeded} onProductChange={handleProductChange} 
                                          onProductSave={handleProductSave} onWizardCancel={handleWizardCancel} />
                    {shouldRedirect && <Navigate to='/products' />}
                </Loader>
            </div>
        );
    };

    return renderWizard();
};
export default ProductReleaseWizardManager;
